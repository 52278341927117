<script>
  import { createEventDispatcher } from "svelte";
  export let border = false;
  export let label = "Label";
  /** @type {string | null} */
  export let labelWidth = null;
  export let labelOnRight = false;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let outerBorder = true;

  const dispatch = createEventDispatcher();
  let input;
  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: indeterminate = value === "Mixed";
  $: checkIndeterminateStatus(input, indeterminate);

  export const focus = () => {
    input.focus();
  };

  function handleFocus(evt) {
    focused = true;
  }

  function blur() {
    focused = false;
  }

  function checkIndeterminateStatus(input, indeterminate) {
    if (!input) return;
    if (indeterminate) {
      input.indeterminate = true;
    } else {
      input.indeterminate = false;
    }
  }

  function updateValue(e) {
    dispatch("input", { value: e.target.checked });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly
  class:visible-border={border}
  on:click|stopPropagation={focus}>
  {#if label !== null && !labelOnRight}
    <div class="label" style={lw}>
      <slot name="label">
        {label}
      </slot>
    </div>
  {/if}
  <input
    type="checkbox"
    bind:this={input}
    disabled={disabled || readonly}
    class="input"
    on:focus={handleFocus}
    on:blur={blur}
    bind:checked={value}
    on:input={updateValue} />
  {#if labelOnRight}
    <div class="truncate">
      <slot name="label">
        {label}
      </slot>
    </div>
  {/if}
</div>

<style lang="scss">
  .prop-container {
    @apply w-full flex items-center rounded bg-white gap-2;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    &.has-inner-border {
      @apply p-1;
    }

    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }
    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }
    .label {
      @apply truncate text-gray-400 flex-none;
    }
    .input {
      @apply min-w-0 bg-transparent;
    }
  }
</style>
