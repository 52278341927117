<script>
  import { getContext } from "svelte";
  import { derived } from "svelte/store";
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import { draggable } from "svelte-utilities";

  import GripIcon from "@local/assets/icons/grip.svg";
  import PlusIcon from "@local/assets/icons/plus.svg";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import CheckIcon from "@local/assets/icons/check.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";

  import XIcon from "@local/assets/icons/x.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import { api } from "#src/api";
  import { orderedList } from "@local/extensions/collections/sortable-list.js";

  const org = getContext("org");
  const productLists = getContext("productLists");
  const priceEntries = getContext("priceEntries");
  const edgeTreatments = getContext("edgeTreatments");
  const fabrications = getContext("fabrications");

  export let selected;
  export let disabled = false;

  const newEntry = () => ({
    list_id: null,
    product_id: null,
    unit_price: null,
    unit: null,
    formula: null,
    minimum_area: null,
    maximum_area: null,
    minimum_item_quantity: null,
    maximum_item_quantity: null,
    fabrication_price_entries: [],
    edge_treatment_price_entries: [],
  });

  const newFP = (id) => ({
    price_entry_id: id,
    fabrication_id: null,
    unit_price: null,
    unit: "each",
  });

  const newEtP = (id) => ({
    price_entry_id: id,
    edge_treatment_id: null,
    unit_price: null,
    unit: "ft",
  });

  const unitOptionLabels = {
    sqft: "Sq. Ft.",
    sqin: "Sq. In.",
    in: "Inches",
    m2: "Sq. Meters",
    item: "Item",
    each: "Each",
    ft: "Feet",
    m: "Meters",
  };

  let editModal;
  let entry = newEntry();
  let list = null;
  let defineCosts = "define";
  let dragging = null;
  let listDragging = null;
  let isDragging = false;
  let showAdvanced = false;
  let newFabPrice = null;
  let newEtPrice = null;

  $: childProducts = getChildProducts(list, entry, selected, $priceEntries);
  $: entryProduct = product(entry.product_id);
  $: unitOptions = getUnitOptions(selected.application);
  $: fabs = derived(fabrications, ($fab) => orderedList($fab));
  $: usedFabs = entry.fabrication_price_entries.reduce((acc, e) => {
    acc[e.fabrication_id] = true;
    return acc;
  }, {});
  $: fabOptions = $fabs.filter((f) => !usedFabs[f.id]).map((f) => ({ label: f.name, value: f.id }));
  $: usedEts = entry.edge_treatment_price_entries.reduce((acc, e) => {
    acc[e.edge_treatment_id] = true;
    return acc;
  }, {});
  $: eTreatments = derived(edgeTreatments, ($et) => orderedList($et));
  $: etOptions = $eTreatments.filter((f) => !usedEts[f.id]).map((f) => ({ label: f.name, value: f.id }));

  function getUnitOptions(application) {
    if (["material", "surface", "makeup"].includes(application)) return ["sqft", "sqin", "m2"];
    if (application === "item") return ["item", "sqft", "sqin", "m2"];
    if (application === "fabrication") return ["each"];
    if (application === "edge") return ["in", "ft", "m"];
    return [];
  }

  function parser(v) {
    if (v === "" || v === undefined || v === null) return null;
    if (typeof v !== "string") throw new Error("Invalid currency value");

    const num = v.match(/\d+(\.?\d+)?/);
    if (!num) throw new Error("Invalid currency value");

    const d = parseFloat(num[0]);
    if (!Number.isFinite(d)) {
      throw new Error("Invalid currency value");
    }

    return d;
  }

  function formatter(v) {
    if (v === "Mixed") return "Mixed";
    if (!Number.isFinite(v)) return "";
    return v.toFixed(4);
  }

  function intParser(num) {
    if (num == null || num === "") return null;
    return parseInt(num);
  }

  function numValidator(num) {
    if (num == null) return true;
    return !Number.isNaN(num);
  }

  function getEntries(list, entries) {
    const pe = entries[selected.id]?.[list.id] || [];
    return pe
      .map((e) => cloneDeep(e || { ...newEntry(), list_id: list.id, product_id: selected.id }))
      .map((e) => {
        delete e.formula_compiled;
        return e;
      });
  }

  function product(id) {
    return (
      $org.makeups[id] ||
      $org.materials[id] ||
      $org.surfaces[id] ||
      $org.item_products[id] ||
      $edgeTreatments[id] ||
      $fabrications[id]
    );
  }

  function editEntry(l, e) {
    list = l;
    entry = cloneDeep(e);

    if (["makeup", "item"].includes(selected.application) && entry.unit_price == null) {
      defineCosts = "defer";
    } else {
      defineCosts = "define";
    }

    if (
      ["minimum_area", "maximum_area", "minimum_item_quantity", "maximum_item_quantity"].some(
        (p) => entry[p] != null,
      ) ||
      entry.fabrication_price_entries.length > 0 ||
      entry.edge_treatment_price_entries.length > 0
    ) {
      showAdvanced = true;
    } else {
      showAdvanced = false;
    }

    editModal.open();
  }

  function getPrice(list, entry) {
    if (entry.unit_price == null || entry.unit == null) return null;
    if (entry.unit_price === "Mixed" || entry.unit === "Mixed") return "Mixed";
    const currency = list.currency === "USD" ? "$" : "C$";
    return `${currency}${entry.unit_price}/${entry.unit}`;
  }

  function constraint(entry, prop) {
    const max = entry[`maximum_${prop}`];
    const min = entry[`minimum_${prop}`];
    const p = prop.replace("_", " ");
    if (max != null && min != null) {
      return `${min} ≤ ${p} < ${max}`;
    } else if (max != null) {
      return `${p} < ${max}`;
    } else if (min != null) {
      return `${min} ≤ ${p}`;
    }

    return null;
  }

  function getPriceConstraints(entry) {
    const constraints = ["area", "item_area", "item_quantity"]
      .map((prop) => constraint(entry, prop))
      .filter((c) => c);

    if (constraints.length) {
      return `(${constraints.join(", ")})`;
    } else {
      return "";
    }
  }

  function addProductEntry(prods, prod, list) {
    if (!prod || !list) return;

    const entries = $priceEntries[prod.id]?.[list.id];
    if (!entries) return;

    prods.push({
      product: prod,
      entries,
    });
  }

  function getChildProducts(list, entry, product) {
    if (!list || !entry || !product) return null;
    if (!["makeup", "item"].includes(product.application)) return null;
    if (Array.isArray(product.id)) return null;
    const currency = list.currency === "USD" ? "$" : "C$";

    let layers;
    if (product.application === "item") {
      if (!product.data?.makeup) return null;

      const makeup = $org.makeups[product.data.makeup.value];
      if (!makeup) return null;

      const entries = $priceEntries[makeup.id]?.[list.id];
      if (entries?.some((e) => e.unit_price != null)) {
        return {
          currency,
          products: [{ product: makeup, entries }],
        };
      } else {
        layers = makeup.data?.layers;
      }
    } else {
      layers = product.data?.layers;
    }

    if (!layers) return null;

    const products = layers.reduce((prods, layer) => {
      if (layer.material) addProductEntry(prods, layer.material, list);
      if (layer.inboard_surface) addProductEntry(prods, layer.inboard_surface, list);
      if (layer.outboard_surface) addProductEntry(prods, layer.outboard_surface, list);

      return prods;
    }, []);

    return {
      currency,
      products,
    };
  }

  function getFabPriceUpdates(oldPrices, newPrices, key = "fabrication_id") {
    const newIds = newPrices.reduce((acc, p) => {
      acc[p[key]] = true;
      return acc;
    }, {});
    const oldIds = oldPrices.reduce((acc, p) => {
      acc[p[key]] = true;
      return acc;
    }, {});

    const del = oldPrices.filter((p) => !newIds[p[key]]).map((p) => p[key]);
    const insert = newPrices.filter((p) => !oldIds[p[key]]);
    const update = newPrices.filter((p) => oldIds[p[key]]);

    return { update, insert, delete: del };
  }

  function updatePriceEntry() {
    const { product_id, list_id, id, fabrication_price_entries, edge_treatment_price_entries, ...rest } =
      entry;

    if (defineCosts === "defer") {
      rest.unit_price = null;
      rest.unit = null;
      rest.formula = null;
    }

    const index = $priceEntries[product_id][list_id].findIndex((e) => e.id === id);

    if (index === -1) return;

    const oldFabPrices = $priceEntries[product_id][list_id][index].fabrication_price_entries;
    const fabPriceUpdates = getFabPriceUpdates(oldFabPrices, fabrication_price_entries);

    const oldEtPrices = $priceEntries[product_id][list_id][index].edge_treatment_price_entries;
    const etPriceUpdates = getFabPriceUpdates(oldEtPrices, edge_treatment_price_entries, "edge_treatment_id");

    $priceEntries[product_id][list_id][index] = entry;

    api
      .from("price_entries")
      .update(rest)
      .eq("id", id)
      .then(({ error }) => {
        if (error) console.error(error);
      });

    if (fabPriceUpdates.insert.length) {
      api
        .from("fabrication_price_entries")
        .insert(fabPriceUpdates.insert)
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (fabPriceUpdates.delete.length) {
      api
        .from("fabrication_price_entries")
        .delete()
        .eq("price_entry_id", entry.id)
        .in("fabrication_id", fabPriceUpdates.delete)
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (fabPriceUpdates.update.length) {
      fabPriceUpdates.update.forEach((u) => {
        const { fabrication_id, price_entry_id, ...update } = u;

        api
          .from("fabrication_price_entries")
          .update(update)
          .eq("price_entry_id", price_entry_id)
          .eq("fabrication_id", fabrication_id)
          .then(({ error }) => {
            if (error) console.error(error);
          });
      });
    }

    if (etPriceUpdates.insert.length) {
      api
        .from("edge_treatment_price_entries")
        .insert(etPriceUpdates.insert)
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (etPriceUpdates.delete.length) {
      api
        .from("edge_treatment_price_entries")
        .delete()
        .eq("price_entry_id", entry.id)
        .in("edge_treatment_id", etPriceUpdates.delete)
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (etPriceUpdates.update.length) {
      etPriceUpdates.update.forEach((u) => {
        const { edge_treatment_id, price_entry_id, ...update } = u;

        api
          .from("edge_treatment_price_entries")
          .update(update)
          .eq("price_entry_id", price_entry_id)
          .eq("edge_treatment_id", edge_treatment_id)
          .then(({ error }) => {
            if (error) console.error(error);
          });
      });
    }
  }

  function addPriceEntry(list) {
    if (!$priceEntries[selected.id]?.[list.id]) {
      set($priceEntries, `${selected.id}.${list.id}`, []);
    }

    const id = crypto.randomUUID();

    const entry = {
      ...newEntry(),
      id,
      list_id: list.id,
      product_id: selected.id,
    };

    $priceEntries[selected.id][list.id].push(entry);
    $priceEntries = $priceEntries;

    const { fabrication_price_entries, edge_treatment_price_entries, ...rest } = entry;

    api
      .from("price_entries")
      .insert(rest)
      .then(({ error }) => {
        if (error) console.error(error);
      });
  }

  function removePriceEntry(list, entry) {
    const { id } = entry;
    const index = $priceEntries[selected.id][list.id].findIndex((e) => e.id === id);
    if (index === -1) return;

    $priceEntries[selected.id][list.id].splice(index, 1);
    $priceEntries = $priceEntries;

    api
      .from("price_entries")
      .delete()
      .eq("id", id)
      .then(({ error }) => {
        if (error) console.error(error);
      });
  }

  function drag(list, index) {
    dragging = index;
    listDragging = list.id;
    isDragging = true;
  }

  function drop(list, dropIndex) {
    if (!isDragging) return;
    if (listDragging !== list.id) return;
    if (dragging === dropIndex || dropIndex === dragging - 1) return;

    const insertion = dropIndex < dragging ? dropIndex + 1 : dropIndex;
    const entries = cloneDeep($priceEntries[selected.id][list.id]);
    const moved = entries.splice(dragging, 1)[0];
    entries.splice(insertion, 0, moved);

    $priceEntries[selected.id][list.id] = entries;

    const updates = entries
      .map((e, i) => [e.id, { priority: i }])
      .map(async ([id, u]) => {
        const { error } = await api.from("price_entries").update(u).eq("id", id);
        if (error) console.error(error);
      });

    Promise.all(updates).catch((e) => console.error(e));
  }

  function dragend() {
    dragging = null;
    listDragging = null;
    isDragging = false;
  }

  function beginAddingFabPrice() {
    newFabPrice = newFP(entry.id);
  }

  function addNewFabPrice() {
    entry.fabrication_price_entries.push(newFabPrice);
    entry = entry;
    newFabPrice = null;
  }

  function removeFabPrice(index) {
    entry.fabrication_price_entries.splice(index, 1);
    entry = entry;
  }

  function beginAddingEtPrice() {
    newEtPrice = newEtP(entry.id);
  }

  function addNewEtPrice() {
    entry.edge_treatment_price_entries.push(newEtPrice);
    entry = entry;
    newEtPrice = null;
  }

  function removeEdgeTreatmentPrice(index) {
    entry.edge_treatment_price_entries.splice(index, 1);
    entry = entry;
  }
</script>

{#if $productLists.length && !Array.isArray(selected.id)}
  <div class="px-6 text-xs">
    <h3 class="mb-2">Product Lists</h3>
    <div class="space-y-2 mb-2">
      {#each $productLists as list}
        {@const entries = getEntries(list, $priceEntries)}
        <div>
          <div class="py-1">
            {list.name}
          </div>
          {#each entries as entry, entryIndex}
            {@const price = getPrice(list, entry)}
            {@const constr = getPriceConstraints(entry)}
            <div
              class="entry-container"
              class:dragging={dragging === entryIndex && list.id === listDragging}
              class:is-dragging={isDragging}>
              {#if entryIndex === 0}
                <!-- svelte-ignore a11y-no-static-element-interactions -->
                <div
                  class="drop-target top-target"
                  class:visible={isDragging}
                  on:mouseup={() => drop(list, -1)}>
                  <div class="drop-target-line" />
                </div>
              {/if}
              <div class="flex justify-between">
                <button
                  class="grip-icon p-1 text-gray-500 hover:text-black"
                  class:disabled
                  use:draggable
                  on:drag={() => !disabled && drag(list, entryIndex)}
                  on:dragend={dragend}>
                  <GripIcon />
                </button>
                <button
                  class="grow text-left p-1 rounded hover:bg-gray-200"
                  on:click={() => editEntry(list, entry)}
                  {disabled}>
                  {price || "No price"}
                  {constr}
                </button>
                <button
                  class="grip-icon flex-none p-1 rounded hover:bg-gray-200 text-gray-500 hover:text-black"
                  on:click={() => removePriceEntry(list, entry)}
                  {disabled}>
                  <XIcon />
                </button>
              </div>
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <div class="drop-target" class:visible={isDragging} on:mouseup={() => drop(list, entryIndex)}>
                <div class="drop-target-line" />
              </div>
            </div>
          {/each}
          {#if !disabled}
            <div class="flex justify-end p-1">
              <button on:click={() => addPriceEntry(list)}>
                <PlusIcon />
              </button>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  </div>
{/if}

<Modal
  closeable
  on:confirm={() => updatePriceEntry()}
  bind:this={editModal}
  width="32rem"
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Product List Entry</div>
  <div slot="content" class="space-y-2 text-xs">
    <div class="space-y-2">
      {#if list}
        <div>Product list: <span class="font-bold">{list.name}</span></div>
      {/if}
      <div>
        <div class="mb-1">Editing price entry for:</div>
        <div class="px-4">
          {entryProduct?.name}
        </div>
      </div>
    </div>
    <div>
      Currency: <span class="font-bold">{list.currency}</span>
    </div>
    {#if ["makeup", "item"].includes(selected.application)}
      <SelectInput
        label="Price Computation"
        border
        bind:value={defineCosts}
        options={[
          { label: "Use price of components", value: "defer" },
          { label: "Define price", value: "define" },
        ]} />
    {/if}
    {#if defineCosts === "define"}
      <TextInput label="Unit Price" border {formatter} {parser} bind:value={entry.unit_price} />
      <SelectInput
        label="Price Unit"
        border
        bind:value={entry.unit}
        options={unitOptions.map((uo) => ({ label: unitOptionLabels[uo], value: uo }))} />
      {#if showAdvanced}
        <div class="py-2">
          <div class="mb-2">Constraints:</div>
          <div class="space-y-2">
            <div class="flex gap-2 items-center">
              <TextInput
                label="Min Area"
                border
                bind:value={entry.minimum_area}
                parser={intParser}
                validator={numValidator} />
              <TextInput
                label="Max Area"
                border
                bind:value={entry.maximum_area}
                parser={intParser}
                validator={numValidator} />
            </div>
            <div class="flex gap-2 items-center">
              <TextInput
                label="Min Item Qty"
                border
                bind:value={entry.minimum_item_quantity}
                parser={intParser}
                validator={numValidator} />
              <TextInput
                label="Max Item Qty"
                border
                bind:value={entry.maximum_item_quantity}
                parser={intParser}
                validator={numValidator} />
            </div>
          </div>
        </div>
        {#if selected.application === "item"}
          <div class="space-y-2">
            <div>Fabrication Prices:</div>
            <div class="space-y-2">
              {#each entry.fabrication_price_entries as fabPriceEntry, fabIndex}
                {@const fab = $fabrications[fabPriceEntry.fabrication_id]}
                <div class="flex pl-8 gap-2 items-center">
                  <div class="w-1/2 flex-none">
                    <div class="w-full truncate">
                      {fab?.name}
                    </div>
                  </div>
                  <div class="grow">
                    <TextInput label="$" border {formatter} {parser} bind:value={fabPriceEntry.unit_price} />
                  </div>
                  <button class="p-1 rounded hover:bg-gray-200" on:click={() => removeFabPrice(fabIndex)}>
                    <TrashIcon />
                  </button>
                </div>
              {/each}
              {#if newFabPrice}
                <div class="flex pl-8 gap-2 items-center">
                  <div class="w-1/2 flex-none">
                    <SelectInput
                      label="Name"
                      border
                      bind:value={newFabPrice.fabrication_id}
                      options={fabOptions} />
                  </div>
                  <TextInput label="$" border {formatter} {parser} bind:value={newFabPrice.unit_price} />
                  <button on:click={addNewFabPrice} class="p-1 rounded hover:bg-gray-200">
                    <CheckIcon />
                  </button>
                </div>
              {/if}
            </div>
            <div class="flex justify-end">
              <button class="font-bold" on:click={beginAddingFabPrice}>+ Add Fab Price Entry</button>
            </div>
          </div>
        {/if}
        {#if ["item", "makeup", "material"].includes(selected.application)}
          <div class="space-y-2">
            <div>Edge Treatment Prices:</div>
            <div class="space-y-2">
              {#each entry.edge_treatment_price_entries as edgePriceEntry, etIndex}
                {@const edge = $edgeTreatments[edgePriceEntry.edge_treatment_id]}
                <div class="flex pl-8 gap-2 items-center">
                  <div class="w-1/2 flex-none">
                    <div class="w-full truncate">
                      {edge?.name}
                    </div>
                  </div>
                  <TextInput label="$" border {formatter} {parser} bind:value={edgePriceEntry.unit_price} />
                  <SelectInput
                    label="/"
                    border
                    bind:value={edgePriceEntry.unit}
                    options={[
                      { label: "in", value: "in" },
                      { label: "ft", value: "ft" },
                      { label: "m", value: "m" },
                    ]} />
                  <button
                    class="p-1 rounded hover:bg-gray-200"
                    on:click={() => removeEdgeTreatmentPrice(etIndex)}>
                    <TrashIcon />
                  </button>
                </div>
              {/each}
              {#if newEtPrice}
                <div class="flex pl-8 gap-2 items-center">
                  <div class="w-1/2 flex-none">
                    <SelectInput
                      label="Name"
                      border
                      bind:value={newEtPrice.edge_treatment_id}
                      options={etOptions} />
                  </div>
                  <TextInput label="$" border {formatter} {parser} bind:value={newEtPrice.unit_price} />
                  <SelectInput
                    label="/"
                    border
                    bind:value={newEtPrice.unit}
                    options={[
                      { label: "ft", value: "ft" },
                      { label: "m", value: "m" },
                    ]} />
                  <button on:click={addNewEtPrice} class="p-1 rounded hover:bg-gray-200">
                    <CheckIcon />
                  </button>
                </div>
              {/if}
            </div>
            <div class="flex justify-end">
              <button class="font-bold" on:click={beginAddingEtPrice}
                >+ Add Edge Treatment Price Entry</button>
            </div>
          </div>
        {/if}
      {:else}
        <div class="flex justify-end items-center">
          <button on:click={() => (showAdvanced = true)} class="p-1 rounded hover:bg-gray-200">
            <EllipsisIcon />
          </button>
        </div>
      {/if}
    {:else if childProducts}
      <div class="space-y-2 py-2">
        <div>Child product price entries:</div>
        {#if childProducts.products.length === 0}
          <div class="px-4 italic">No child prices defined</div>
        {/if}
        <table class="ml-4">
          {#each childProducts.products as product}
            <tr>
              <td>
                {product.product.name}
              </td>
              <td>
                {#each product.entries as entry}
                  {@const price = getPrice(list, entry)}
                  {@const constr = getPriceConstraints(entry)}
                  <div class="px-8">
                    {price || "No price"}
                    {constr}
                  </div>
                {/each}
              </td>
            </tr>
          {/each}
        </table>
      </div>
    {/if}
  </div>
</Modal>

<style lang="scss">
  .pl-item {
    .ps-button {
      display: none;
    }

    &:hover .ps-button {
      display: block;
    }
  }

  .entry-container {
    @apply relative border border-transparent;

    .grip-icon {
      visibility: hidden;
    }

    &:hover:not(.is-dragging) {
      .grip-icon {
        visibility: visible;
      }
    }
  }

  .drop-target {
    @apply absolute w-full h-full z-30 pointer-events-none;
    bottom: calc(-50%);

    &.top-target {
      top: calc(-50% - 0.25rem);
    }

    &.visible {
      pointer-events: auto;

      &:hover {
        .drop-target-line {
          @apply w-full border-b border-gray-300 absolute;
          top: 50%;
        }
      }
    }
  }
</style>
