<script>
  import { onMount, createEventDispatcher, getContext } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import set from "lodash/set";
  import { Modal } from "svelte-utilities";

  import { createClient } from "@local/lamina-freight/client";

  import SpinnerIcon from "@local/assets/icons/spinner.svg";
  import LocationIcon from "@local/assets/icons/location.svg";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import ErrorMessage from "../standard-inputs/ErrorMessage.svelte";
  import MapboxInput from "#lib/sidebar/MapboxInput.svelte";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";

  import { sortableList } from "@local/extensions/collections/sortable-list.js";
  import { currentSuppliers } from "#src/stores/ui.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials";
  import { pricingGroupProps, itemFreightProperties, getItemPrices } from "@local/lamina-core/reporters";
  import eb from "#src/extensions/event-bus.js";
  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";
  import { FRATER_URL } from "#src/env.js";

  export let rfq = null;
  export let job;
  export let group;
  export let recipients = [];
  export let items;
  export let types;
  export let org;

  const dispatch = createEventDispatcher();
  const freight = createClient(FRATER_URL, api);
  const productLists = getContext("productLists");
  const priceEntries = getContext("priceEntries");
  const supplier = getContext("supplier");

  let modal;
  let suppliers = {};
  let computingShipping = false;
  let jobkey = crypto.randomUUID();
  let quotekey = crypto.randomUUID();
  let key;
  let destination = job.location_postal_code;
  let errorMsg;
  let showAdvancedOptions = false;

  $: jobProperties = pricingGroupProps($group);
  $: selfSending = org.id === $supplier?.id && !rfq;
  $: savedShipping = selfSending ? job.shipping : recipients[0]?.shipping;
  $: shipping = sumShipping(savedShipping);
  $: sendDisabled = (!recipients.length && !selfSending) || !shipping || computingShipping;
  $: updateKey(items, types);
  $: jobUpdateKey(job);
  $: currentPl = $productLists.find((pl) => pl.id === job?.product_list_id);
  $: hasShippingProp = ["liftgate_delivery", "appointment_delivery", "inside_delivery"].some(
    (prop) => job.data.shipping_options?.[prop],
  );
  $: updateShipping(job);
  $: accessorialCodes = getAccessorialCodes(job.data.shipping_options);

  function sumShipping(shipping) {
    if (!shipping) return null;
    if (shipping.cost == null) return null;

    let cost = shipping.cost;
    let markup = shipping.markup ?? 0;

    return ((cost + markup) / 100).toFixed(2);
  }

  function updateKey(items, types) {
    jobkey = crypto.randomUUID();
  }

  function jobUpdateKey(job) {
    if (destination !== job.location_postal_code) {
      jobkey = crypto.randomUUID();
      destination = job.location_postal_code;
    }
  }

  function getAccessorialCodes(shippingOptions) {
    if (!shippingOptions) return [];
    const codes = [];

    if (shippingOptions.notification_delivery) {
      codes.push("notification_delivery");
    }

    if (shippingOptions.liftgate_delivery) {
      codes.push("liftgate_delivery");
    }

    if (shippingOptions.appointment_delivery) {
      codes.push("appointment_delivery");
    }

    if (shippingOptions.inside_delivery) {
      codes.push("inside_delivery");
    }

    return codes;
  }

  async function openModal() {
    modal.open();

    if (hasShippingProp) {
      showAdvancedOptions = true;
    }

    if (key !== jobkey) {
      getShipping();
    }

    suppliers = $currentSuppliers;
    const { data, error } = await api
      .from("organizations")
      .select("*, profiles(*)")
      .in(
        "id",
        recipients.map((r) => r.contact_id),
      );

    if (error) {
      console.error(error);
      suppliers = sortableList([]);
    } else {
      suppliers = sortableList(data);
    }
  }

  function updateShipping(job) {
    if (job.location_postal_code && key !== jobkey) {
      getShipping();
    }
  }

  async function getShipping() {
    quotekey = crypto.randomUUID();
    let qk = quotekey;
    if (!supplier) return;

    const hasRecipient = !!rfq?.data?.saved_recipients?.[0];
    if (!selfSending && !hasRecipient) return;

    computingShipping = true;

    const itemPrices = getItemPrices($group, currentPl, $priceEntries, jobProperties);

    const itemDims = items.reduce((fp, item) => {
      const type = $group.types[item.type_id];

      if (item.quantity > 0) {
        for (let i = 0; i < item.quantity; i++) {
          fp.push(itemFreightProperties(item, type?.pricing_group, type?.freight_group, itemPrices));
        }
      }

      return fp;
    }, []);

    const postcode = job.location_postal_code;
    if (!postcode) return;
    const isOpen = modal?.isModalOpen();
    if (!isOpen) return;

    const endpointId = $supplier.freight_endpoint ?? "418f0f57-babd-5bef-a3a2-c2bc30f8e0be";
    const referenceId = selfSending ? $group.seller_reference || job.id : $group.seller_reference || rfq?.id;
    try {
      const response = await freight.rateQuote.query({
        origin: {
          country: $supplier.address_country_code?.toLowerCase() ?? "usa",
          postcode: $supplier.address_postal_code,
        },
        destination: {
          country: job.location_country_code?.toLowerCase() ?? "usa",
          postcode,
        },
        referenceId,
        endpointId,
        accessorialCodes,
        endpointParams: {},
        items: itemDims,
      });

      // Store computed shipping
      if (selfSending) {
        if (qk === quotekey) {
          await updateJob({ shipping: response });
          computingShipping = false;
          key = jobkey;
        }
      } else {
        const data = cloneDeep(rfq.data);
        const recipient = data.saved_recipients?.[0];
        recipient.shipping = response;

        if (qk === quotekey) {
          await dispatch("update-rfq", { data });
          computingShipping = false;
          key = jobkey;
        }
      }
    } catch (error) {
      console.log(error);
      errorMsg = "Error calculating shipping.";
      computingShipping = false;
      key = jobkey;
    }
  }

  function userInits(p) {
    return getUserInitials(p).toUpperCase();
  }

  async function updateJob(update) {
    await dispatch("update-job", update);
  }

  async function updateJobData(path, value) {
    const data = cloneDeep(job.data);
    set(data, path, value);
    await dispatch("update-job", { data });
  }

  async function updateAddress(e) {
    await updateJob({
      location: e.detail.value,
      location_coordinates: e.detail.coordinates,
      location_postal_code: e.detail.postal_code,
      location_country_code: e.detail.country_code,
    });
  }

  onMount(() => {
    eb.on("open-rfq-modal", openModal);

    return () => {
      eb.unsubscribe("open-rfq-modal", openModal);
    };
  });
</script>

<Modal
  bind:this={modal}
  width="32rem"
  closeable
  on:confirm
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Create", type: "confirm", style: "primary", disabled: sendDisabled },
  ]}>
  <div slot="title">
    {#if org.account_type === "free"}
      {#if supplier}
        Create Quote
      {:else}
        Unable to send
      {/if}
    {:else}
      Create Quote
    {/if}
  </div>
  <div slot="content" class="space-y-4 text-sm">
    {#if errorMsg}
      <ErrorMessage padding={false} message={errorMsg} on:close={() => (errorMsg = null)} />
    {/if}
    {#if org.account_type === "free" && !supplier}
      <div>
        Your free account is not sponsored by any suppliers. Upgrade to a paid account in order to send this
        RFQ.
      </div>
    {:else if !selfSending && !recipients.length}
      <div>You must add at least one recipient.</div>
    {:else}
      <div class="flex">
        <div class="flex-none w-20 text-gray-500 text-xs">Job:</div>
        <div class="text-black">
          <div class="font-bold">{job.name}</div>
        </div>
      </div>
      {#if selfSending}
        <div class="flex">
          <div class="flex-none w-20 text-gray-500 text-xs">Vendor:</div>
          <div class="space-y-3">
            <div>
              {#if org?.data.logo}
                <img
                  class="block logo-image object-contain h-6"
                  src={org.data.logo.base64}
                  alt="Logo Thumbnail" />
              {/if}
              <div class="font-bold text-sm">{org.name}</div>
              <div class="flex items-center text-xs gap-1">
                <div
                  class="profile-circle relative"
                  style="background-color: {$profile?.user_color ||
                    '#000'}; border-color: {$profile?.user_color || '#000'}">
                  {userInits($profile)}
                </div>
                <div>
                  {$profile?.username}
                </div>
              </div>
            </div>
          </div>
        </div>
      {:else}
        <div class="flex">
          <div class="flex-none w-20 text-gray-500 text-xs">Vendor:</div>
          <div class="space-y-3">
            {#each recipients as recipient}
              {@const rorg = suppliers[recipient.contact_id]}
              {@const prof = rorg?.profiles.find((p) => p.id === recipient.primary_contact_id)}
              <div>
                {#if rorg?.data.logo}
                  <img
                    class="block logo-image object-contain h-6"
                    src={rorg.data.logo.base64}
                    alt="Logo Thumbnail" />
                {/if}
                <div class="font-bold text-sm">{recipient.company_name}</div>
                <div class="flex items-center text-xs gap-1">
                  {#if prof}
                    <div
                      class="profile-circle relative"
                      style="background-color: {prof.user_color || '#000'}; border-color: {prof.user_color ||
                        '#000'}">
                      {userInits(prof)}
                    </div>
                    <div>
                      {prof.username}
                    </div>
                  {/if}
                </div>
              </div>
            {/each}
          </div>
        </div>
      {/if}
      {#if job.customer_record}
        <div class="flex">
          <div class="flex-none w-20 text-gray-500 text-xs">Customer:</div>
          <div class="text-black">
            <div class="font-bold">{job.customer_record.name}</div>
            <div class="text-xs text-gray-500">
              {job.customer_record.email}
            </div>
          </div>
        </div>
      {:else if job.customer_text}
        <div class="flex">
          <div class="flex-none w-20 text-gray-500 text-xs">Customer:</div>
          <div class="text-black">
            <div class="font-bold">Customer: {job.customer_text}</div>
          </div>
        </div>
      {/if}
      <div class="flex">
        <div class="flex-none w-20 text-gray-500 text-xs">Shipping & Crating:</div>
        <div class="text-black grow text-xs space-y-1">
          <div class="w-full flex">
            <div class="grow">
              <MapboxInput
                labelWidth="1rem"
                value={job.location}
                coordinates={job.location_coordinates}
                outerBorder={false}
                placeholder="Location"
                disabled={rfq?.sent_at}
                on:input={updateAddress}>
                <div class="text-black" slot="label">
                  <LocationIcon />
                </div>
              </MapboxInput>
            </div>
            <button
              class="flex-none p-1 rounded hover:bg-gray-200"
              on:click={() => (showAdvancedOptions = !showAdvancedOptions)}>
              <EllipsisIcon />
            </button>
          </div>
          {#if showAdvancedOptions}
            <div class="w-full pl-6">
              <BooleanInput
                label="Liftgate"
                labelOnRight
                outerBorder={false}
                value={job.data.shipping_options?.liftgate_delivery}
                on:input={(e) => updateJobData("shipping_options.liftgate_delivery", e.detail.value)}
              ></BooleanInput>
              <BooleanInput
                label="Appointment"
                labelOnRight
                outerBorder={false}
                value={job.data.shipping_options?.appointment_delivery}
                on:input={(e) => updateJobData("shipping_options.appointment_delivery", e.detail.value)}
              ></BooleanInput>
              <BooleanInput
                label="Inside delivery"
                labelOnRight
                outerBorder={false}
                value={job.data.shipping_options?.inside_delivery}
                on:input={(e) => updateJobData("shipping_options.inside_delivery", e.detail.value)}
              ></BooleanInput>
            </div>
          {/if}
          <div>
            {#if !job.location_postal_code}
              <div class="italic">
                <div class="text-black text-center w-4">&nbsp;</div>
                <div class="px-1">Choose an address to calculate shipping.</div>
              </div>
            {:else if computingShipping}
              <div class="flex gap-1 items-center italic text-gray-500">
                <div class="relative">
                  <div class="animate-spin">
                    <SpinnerIcon />
                  </div>
                </div>
                <div>Calculating shipping.</div>
              </div>
            {:else}
              <div class="flex gap-2 font-bold">
                <div class="text-black text-center w-4">$</div>
                <div class="px-1">
                  {#if shipping != null}
                    {shipping}
                  {:else}
                    &nbsp;
                  {/if}
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>
</Modal>
