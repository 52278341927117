export default function sanitizePgString(str) {
  if (!str) return "";
  return str.replace(/[+/#?\[\]\\%]/g, "_");
}

export function escapeSpecialChars(str) {
  if (!str) return "";
  return str.replace(/[%_\\\/]/g, "\\$&");
}

export function escapeWithWildcards(str) {
  if (!str) return "";
  return escapeSpecialChars(str).replace(/[?]/g, "_").replace(/[*]/g, "%");
}

export function escapeRegExp(str) {
  if (!str) return "";
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
