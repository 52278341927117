import { Dimension } from "dimtext";
import {
  sortableList,
  sortArrayBy,
} from "@local/extensions/collections/sortable-list.js";

export function orgFabrications(org) {
  if (!org?.products) return sortableList([]);

  return sortableList(
    sortArrayBy(
      org.products
        .filter((p) => p.application === "fabrication")
        .map((p) => {
          if (p.data?.params) {
            p.data.params = p.data.params.map((param) => new Dimension(param));
          }
          return p;
        }),
      org.data.fabrication_order,
    ),
  );
}
