<script>
  import { Router, Route } from "svelte-routing";
  import { ShynetTrackingScript } from "svelte-utilities";
  import queryString from "query-string";
  import RouteObserver from "./lib/RouteObserver.svelte";
  import Notifications from "./lib/Notifications.svelte";
  import RootRedirect from "./lib/RootRedirect.svelte";
  import Login from "./routes/Login.svelte";
  import Logout from "./routes/Logout.svelte";
  import Signup from "./routes/Signup.svelte";
  import UpdatePassword from "./routes/UpdatePassword.svelte";
  import NotFound from "./routes/NotFound.svelte";
  import Home from "./routes/Home.svelte";
  import Archive from "./routes/Archive.svelte";
  import Quote from "./routes/Quote.svelte";
  import Job from "./routes/Job.svelte";
  import Group from "./routes/Group.svelte";
  import Settings from "./routes/Settings.svelte";
  import Products from "./routes/Products.svelte";
  import Help from "./routes/Help.svelte";
  import Org from "./routes/Org.svelte";
  import OrgList from "./routes/OrgList.svelte";
  import Profile from "./routes/Profile.svelte";
  import Portal from "./routes/Portal.svelte";
  import AcceptInvitation from "./routes/AcceptInvitation.svelte";
  import Footer from "./lib/Footer.svelte";
  import Redirect from "./routes/Redirect.svelte";
  import { user, profile } from "./stores/auth.js";
  import OrgSelector from "./lib/OrgSelector.svelte";
  import Varz from "./routes/Varz.svelte";
  import Dataz from "./routes/Dataz.svelte";
  import Authenticated from "./routes/Authenticated.svelte";

  import { url as urlStore } from "./stores/url.js";
  import {
    DEV,
    IS_PULL_REQUEST,
    SHYNET_URL,
    SHYNET_ID,
    ROOT_REDIRECT_URL,
    ROOT_REDIRECT_AUTO,
  } from "#src/env";
  import { api } from "#src/api";

  let url = "";

  export let basepath = "/";

  $: queryParams = queryString.parse($urlStore?.search);
  $: orgType = $profile?.organization?.org_type;

  async function getQuoteId(rfqid) {
    if (!$profile?.organization_id) return null;

    const { data, error } = await api
      .from("quotes")
      .select("id")
      .eq("quote_request_id", rfqid)
      .eq("organization_id", $profile.organization_id)
      .single();

    if (error) return null;
    return data.id;
  }

  let routerLocation;

  function handleNavigate(evt) {
    const { detail } = evt;
    if (detail) {
      routerLocation = detail;
    }
  }

  async function getSession() {
    await user.getSession();
  }
</script>

<RootRedirect href={ROOT_REDIRECT_URL} auto={ROOT_REDIRECT_AUTO}>
  <Router {url} {basepath}>
    <RouteObserver on:navigate={handleNavigate} />
    {#if DEV || IS_PULL_REQUEST}
      <Route path="varz" component={Varz} />
    {/if}
    {#await getSession() then _}
      {#if $user}
        {#if $profile && $profile.user_role === "developer" && !$profile.organization_id}
          <div class="w-full h-full bg-gray-200 flex flex-col overflow-hidden justify-end">
            <OrgSelector />
            <Footer />
          </div>
        {:else if $profile}
          <Authenticated>
            <div class="w-full h-full flex flex-col overflow-hidden">
              {#if DEV || IS_PULL_REQUEST}
                <Route path="dataz" component={Dataz} />
              {/if}
              <Route path="jobs/:jobid/current/*path" pathtype="current" component={Group} {queryParams} />
              <Route path="jobs" component={Home} {queryParams} />
              <Route path="quotes" component={Home} {queryParams} />
              <Route path="jobs/:jobid" component={Job} />
              <Route path="quotes/:quoteid" component={Quote} />
              <Route path="archive" component={Archive} {queryParams} />
              <Route path="rfq/:rfqid/*path" let:params>
                {#if orgType === "recipient"}
                  <Redirect to={`/quotes/${params.rfqid}`} {queryParams} />
                {:else}
                  {#await getQuoteId(params.rfqid) then quoteid}
                    <Redirect to={`/quotes/${quoteid}`} {queryParams} />
                  {/await}
                {/if}
              </Route>
              <Route path="versions/:groupid/*path" pathtype="version" component={Group} />
              <Route path="orgs/:orgid" component={Org} />
              {#if $profile.user_role === "developer"}
                <Route path="orgs" component={OrgList} {queryParams} />
              {/if}
              <Route path="profiles/:profileid" component={Profile} />
              <Route path="link/:linkid/*path" pathtype="link" component={Group} />
              <Route path="accept-invitation/*path">
                <Redirect to="/" />
              </Route>
              <Route path="settings/*path" component={Settings} {queryParams} />
              <Route path="products/*path" component={Products} />
              <Route path="help/*path" component={Help} orgId={$profile.organization_id} />
              <Route path="not-found" component={NotFound} />
              <Route path="passreset" component={UpdatePassword} />
              <Route path="logout" component={Logout} />
              <Route path="login/*path" component={Login} />
              <Route path="/" component={Home} {queryParams} />
              <Route path=":slug" component={Portal} />
              <Route path="*path">
                <Redirect to="/not-found" />
              </Route>
              <Footer />
            </div>
          </Authenticated>
        {/if}
      {:else}
        <div class="w-full h-full bg-gray-200 flex flex-col overflow-hidden justify-end">
          <Route path="accept-invitation/:invitationid" component={AcceptInvitation} {queryParams} />
          <Route path="not-found" component={NotFound} />
          <Route path="signup" component={Signup} />
          <Route path="link/:linkid/*path" pathtype="link" component={Group} />
          <Route path="login/*path" component={Login} />
          <Route path="logout">
            <Redirect to="/login" />
          </Route>
          <Route path=":slug" component={Portal} />
          <Route path="*path" let:params>
            <Redirect to="/login" path={params.path} />
          </Route>
          <Footer />
        </div>
      {/if}
    {/await}
  </Router>
  <Notifications />
  <ShynetTrackingScript
    url={SHYNET_URL}
    serviceID={SHYNET_ID}
    userID={$profile?.email}
    location={routerLocation} />
</RootRedirect>

<style lang="scss">
  @import "./assets/styles/variables.scss";

  :root {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  :global {
    html {
      scroll-behavior: smooth;
    }

    body {
      height: 100vh;
      height: 100dvh;
      width: 100vw;
    }

    #app {
      width: 100%;
      height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: inherit;
      font-weight: bold;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }
    .form-input {
      @apply border rounded p-1;
    }

    .btn-text {
      @apply text-blue-500;

      &:hover {
        @apply underline;
      }

      &:disabled {
        @apply text-gray-400 no-underline;
      }
    }

    .dropdown-container {
      @apply absolute p-2 z-40 bg-white border rounded drop-shadow-lg text-xs text-black;
    }

    .dropdown-button-item {
      @apply flex py-1 px-2 gap-2 rounded w-full items-center whitespace-nowrap;

      &:hover {
        @apply bg-gray-100;
      }

      &:disabled {
        @apply text-gray-400 cursor-not-allowed;
      }
    }

    .item-list-button {
      @apply flex gap-2 border border-gray-400 rounded-full p-1.5 items-center bg-white whitespace-nowrap justify-between;

      .remove-button {
        @apply text-gray-400;

        &:hover {
          @apply text-black;
        }
      }
    }

    .hover-item {
      @apply hidden;
    }

    .hover-container:hover {
      .hover-item {
        @apply block;
      }
    }

    .btn-bare {
      @apply rounded-md border border-gray-500 h-8;

      &:hover {
        @apply border-black bg-gray-100;
      }
    }

    .btn {
      @apply py-1 px-4 font-semibold text-xs rounded-md h-8 border-gray-500;
      border-width: 1px;
      appearance: none;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        @apply border-black bg-gray-100;
      }

      &.dropdown-button {
        cursor: default;
      }

      &.btn-lg {
        @apply py-2 h-10;
      }

      &.btn-default {
        color: $color-gray-09;
        background: linear-gradient($color-white, $color-gray-03);

        &:hover {
          background: linear-gradient($color-gray-01, $color-gray-03);
        }

        &:active,
        &.active {
          background: $color-gray-03;
          box-shadow:
            inset 0 0 0 1px rgba(0, 0, 0, 0.15),
            inset 0 1px 3px rgba(0, 0, 0, 0.15);
        }
      }

      &.btn-primary {
        color: $color-white;
        border-color: transparent;
        background: black;
        // background: linear-gradient(#566678, #1e2936);

        &:hover {
          background: black;
          // background: linear-gradient(#38485b, #162332);
        }

        &:active,
        &.active {
          background: #0e5fc1;
        }
      }

      &.btn-icon {
        @apply px-2 flex items-center;
      }

      &.btn-compact {
        @apply px-2 h-7;
      }

      &.btn-primary-alt {
        color: $color-white;
        border-color: transparent;
        @apply bg-blue-500;
        // background: linear-gradient(#566678, #1e2936);

        &:hover {
          @apply bg-blue-600;
          // background: linear-gradient(#38485b, #162332);
        }

        &:active,
        &.active {
          @apply bg-blue-500;
        }
      }

      &.btn-primary-alt-2 {
        color: black;
        background: #ffc035;
        @apply border-2;
      }

      &.btn-danger {
        color: $color-white;
        background-color: $color-red;
        border-color: transparent;

        &:hover {
          background-color: #cf3945;
          border-color: rgba(27, 31, 35, 0.5);
        }

        &:active,
        &.active {
          background: #ac0211;
        }
      }

      &.btn-danger-minimal {
        color: $color-red;
        background-color: transparent;
        border-color: $color-red;

        &:hover {
          color: #cf3945;
        }

        &:active {
          color: #ac0211;
        }
      }

      &:disabled,
      &.disabled {
        opacity: 50%;
        cursor: not-allowed;
      }
    }

    .btn-minimal {
      color: $color-blue;
      font-size: $text-sm;
    }

    .profile-circle {
      @apply flex-none h-4 w-4 text-center rounded-full border text-white font-bold;
      font-size: 0.4rem;
      line-height: 0.8rem;
    }

    .auto-tooltip:after,
    .auto-tooltip:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translate(-50%, 0);
    }

    .auto-tooltip-left:before {
      left: 3px;
      transform: translate(0, 0);
    }

    .auto-tooltip-left:after {
      left: 4px;
      transform: translate(0, 0);
    }

    .auto-tooltip-right:before {
      left: calc(100% - 3px);
      transform: translate(-100%, 0);
    }

    .auto-tooltip-right:after {
      left: calc(100% - 4px);
      transform: translate(-100%, 0);
    }

    .auto-tooltip:before {
      border-width: 8px;
      border-color: transparent transparent rgb(156 163 175) transparent;
      top: -16px;
    }

    .auto-tooltip:after {
      border-width: 7px;
      border-color: transparent transparent #000 transparent;
      top: -14px;
    }
  }
</style>
