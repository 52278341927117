<script>
  import { getContext } from "svelte";
  import { Modal } from "svelte-utilities";
  import { Datagrid } from "datagrid";
  import { api } from "#src/api";
  import set from "lodash/set";

  export let selected;

  const org = getContext("org");
  const productLists = getContext("productLists");
  const priceEntries = getContext("priceEntries");

  let modal;
  let priceList;
  let products = [];
  let priceCols = [];
  let categories;

  $: columns = [{ label: "name", prop: "name", disabled: true }, ...priceCols];

  function parseNumber(v) {
    if (!v) return null;
    const result = parseFloat(v);
    if (isNaN(result)) throw new Error("Not parseable as a number");
    return result;
  }

  function costFormatter(v) {
    if (!v) return "";
    return v.toFixed(4);
  }

  function constraint(entry, prop) {
    const max = entry[`maximum_${prop}`];
    const min = entry[`minimum_${prop}`];
    const p = prop.replace("_", " ");
    if (max != null && min != null) {
      return `$/${entry.unit}, ${min} ≤ ${p} < ${max}`;
    } else if (max != null) {
      return `$/${entry.unit}, ${p} < ${max}`;
    } else if (min != null) {
      return `$/${entry.unit}, ${min} ≤ ${p}`;
    }

    if (entry.unit) return `$/${entry.unit}`;

    return null;
  }

  function entryCategory(entry) {
    let cat = [`unit=${entry.unit}`];
    if (entry.maximum_area) cat.push(`maximum_area=${entry.maximum_area}`);
    if (entry.minimum_area) cat.push(`minimum_area=${entry.minimum_area}`);
    if (entry.maximum_quantity) cat.push(`maximum_quantity=${entry.maximum_quantity}`);
    if (entry.minimum_quantity) cat.push(`minimum_quantity=${entry.minimum_quantity}`);
    if (entry.maximum_item_quantity) cat.push(`maximum_item_quantity=${entry.maximum_item_quantity}`);
    if (entry.minimum_item_quantity) cat.push(`minimum_item_quantity=${entry.minimum_item_quantity}`);
    return cat.join("&");
  }

  function parseCategoryProps(cat) {
    const props = cat.split("&");
    return props.reduce((p, c) => {
      const [key, value] = c.split("=");
      p[key] = key === "unit" ? value : parseFloat(value);
      return p;
    }, {});
  }

  function categorizeEntries(selected, list) {
    return selected.reduce((e, product) => {
      const entries = $priceEntries[product.id]?.[list.id] || [];
      entries.forEach((entry) => {
        const cat = entryCategory(entry);
        e[cat] = entry;
      });

      return e;
    }, {});
  }

  function openList(list) {
    priceList = list;
    categories = categorizeEntries(selected, list);
    priceCols = Object.keys(categories).map((cat) => {
      const entry = categories[cat];
      let prop = "";
      if (cat.includes("maximum_area") || cat.includes("minimum_area")) {
        prop = "area";
      } else if (cat.includes("maximum_quantity") || cat.includes("minimum_quantity")) {
        prop = "quantity";
      } else if (cat.includes("maximum_item_quantity") || cat.includes("minimum_item_quantity")) {
        prop = "item_quantity";
      }

      const c = constraint(entry, prop);
      return {
        label: c,
        prop: cat,
        type: "number",
        parser: parseNumber,
        formatter: costFormatter,
        validator: (v) => v >= 0,
      };
    });

    const p = selected.map((s) => {
      const entries = $priceEntries[s.id]?.[list.id] || [];
      const entryCats = entries.map((e) => entryCategory(e));

      const prod = { id: s.id, name: s.name };

      priceCols.forEach((col) => {
        const index = entryCats.indexOf(col.prop);
        if (index !== -1) {
          prod[col.prop] = entries[index].unit_price;
        } else {
          prod[col.prop] = null;
        }
      });

      return prod;
    });

    products = p;
    modal.open();
  }

  async function updatePriceEntries() {
    const updates = [];
    const inserts = [];
    const deletes = [];

    const storeDeletes = {};

    products.forEach((product) => {
      const { name, id, ...newEntries } = product;
      const oldEntries = $priceEntries[id]?.[priceList.id] || [];

      oldEntries.forEach((entry) => {
        const cat = entryCategory(entry);
        const newEntry = newEntries[cat];

        if (newEntry == null) {
          deletes.push(entry.id);
          if (!storeDeletes[id]) storeDeletes[id] = {};
          storeDeletes[id][entry.id] = true;
        } else if (entry.unit_price !== newEntry) {
          updates.push({ id: entry.id, list_id: priceList.id, product_id: id, unit_price: newEntry });
          entry.unit_price = newEntry;
        }
      });

      Object.keys(newEntries).forEach((cat) => {
        const newEntry = newEntries[cat];
        const oldEntry = oldEntries.find((e) => entryCategory(e) === cat);
        if (!oldEntry && newEntry != null) {
          const props = parseCategoryProps(cat);
          const entry = { product_id: id, list_id: priceList.id, unit_price: newEntry, ...props };
          inserts.push(entry);
          if (!$priceEntries[id]) $priceEntries[id] = {};
          if (!$priceEntries[id][priceList.id]) $priceEntries[id][priceList.id] = [];
          $priceEntries[id][priceList.id].push(entry);
        }
      });
    });

    if (updates.length) {
      api
        .from("price_entries")
        .upsert(updates, { ignoreDuplicates: false, onConflict: "id", defaultToNull: false })
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (inserts.length) {
      api
        .from("price_entries")
        .insert(inserts)
        .then(({ error }) => {
          if (error) console.error(error);
        });
    }

    if (deletes.length) {
      api
        .from("price_entries")
        .delete()
        .eq("list_id", priceList.id)
        .in("id", deletes)
        .then(({ error }) => {
          if (error) console.error(error);
        });

      Object.entries(storeDeletes).forEach(([productId, present]) => {
        const entries = $priceEntries[productId][priceList.id].filter((entry) => !present[entry.id]);
        set($priceEntries, [productId, priceList.id], entries);
      });
    }

    $priceEntries = $priceEntries;
  }
</script>

{#if $productLists.length}
  <div class="px-6 text-xs">
    <h3 class="mb-2">Product Lists</h3>
    <div class="space-y-2 mb-2">
      {#each $productLists as list}
        <button on:click={() => openList(list)}>
          {list.name}
        </button>
      {/each}
    </div>
  </div>
{/if}

<Modal
  closeable
  on:confirm={updatePriceEntries}
  bind:this={modal}
  width="80%"
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Price Entries</div>
  <div slot="content">
    <div class="w-full p-4">
      <Datagrid {columns} bind:data={products} />
    </div>
  </div>
</Modal>
