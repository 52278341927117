<script>
  import { createEventDispatcher } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import { Dimension } from "dimtext";

  import ImageInput from "./ImageInput.svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import TextInput from "./TextInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import DimtextInput from "./DimtextInput.svelte";
  import BooleanInput from "./BooleanInput.svelte";
  import CopyTextField from "./CopyTextField.svelte";
  import SidebarSection from "./SidebarSection.svelte";
  import DrawingInput from "./DrawingInput.svelte";
  import Viewport from "#lib/drawing/ViewportMinimal.svelte";

  import fabDrawing from "@local/extensions/drawing/fab-drawing";
  import { drawingSrc } from "#src/api";
  import { fabParams } from "@local/extensions/fabrications";

  import { profile } from "#src/stores/auth.js";

  export let fabrication;
  export let org;
  export let disabled;

  const dispatch = createEventDispatcher();

  $: settings = $org.data.settings;
  $: multiple = Array.isArray(fabrication.id);
  $: dis = fabrication.vendor_id !== $org.id || disabled;
  $: functionParams = fabParams[fabrication.data?.func] || [];

  function update(prop, value) {
    dispatch("update", { id: fabrication.id, prop, value });
  }

  function updateShape(shape) {
    const data = cloneDeep(fabrication.data);
    data.func = shape;
    data.params = fabParams[shape].map((p) => p.default);
    update("data", data);
  }

  function updateFabPosition(value) {
    const data = {
      position: value,
      type: "generic",
      drawing: null,
      effect: "subtract",
    };
    update("data", data);
  }

  function updateFabType(value) {
    if (value === "function") {
      if (fabrication.data.position === "edge") {
        const data = {
          position: "edge",
          type: "function",
          func: "geneva",
          params: fabParams.geneva.map((p) => new Dimension(p.default)),
        };
        update("data", data);
      } else if (fabrication.data.position === "corner") {
        const data = {
          position: "corner",
          type: "function",
          func: "patch",
          params: fabParams.patch.map((p) => new Dimension(p.default)),
        };
        update("data", data);
      }
    } else if (value === "static") {
      const data = {
        position: fabrication.data.position,
        type: "static",
        filename: null,
        path: null,
        voids: [],
      };
      update("data", data);
    } else if (value === "generic") {
      const data = {
        position: fabrication.data.position,
        type: "generic",
        drawing: null,
        effect: "subtract",
      };
      update("data", data);
    }
  }

  function updateFabProp(prop, value) {
    const data = cloneDeep(fabrication.data);
    data[prop] = value;
    update("data", data);
  }

  function updateParam(index, value) {
    const p = fabParams[fabrication.data.func][index];
    if (p.update) {
      const data = cloneDeep(fabrication.data);
      p.update(data.params, value);
      update("data", data);
    } else {
      const data = cloneDeep(fabrication.data);
      data.params[index] = value;
      update("data", data);
    }
  }

  function updateFabEffect(value) {
    const data = cloneDeep(fabrication.data);
    data.effect = value;
    update("data", data);
  }

  function highlight(param) {
    dispatch("highlight", { param });
  }

  function focus(param) {
    dispatch("focus", { param });
  }

  function unhighlight(param) {
    dispatch("unhighlight", { param });
  }

  function blur(param) {
    dispatch("blur", { param });
  }
</script>

<div class="text-xs px-4">
  {#if !multiple}
    {#if fabrication.vendor_id === $org.id}
      <div class="px-2 mt-2">
        <div class="w-full aspect-square relative border border-gray-400">
          {#if fabrication.data.drawing}
            <div class="w-full h-full flex items-center justify-center bg-white" style="padding:4px;">
              <img class="w-full h-full object-contain" src={drawingSrc(fabrication.data.drawing)} alt="" />
            </div>
          {:else}
            <Viewport padding={0} drawing={fabDrawing(fabrication.data)} />
          {/if}
        </div>
        <div class="text-gray-400 py-2">Image</div>
      </div>
    {:else}
      <ImageInput
        label="Image"
        labelWidth="5rem"
        value={`/images/${fabrication.image?.filename}`}
        accept="image/png, image/svg+xml"
        uploadType="arraybuffer"
        disabled />
    {/if}
  {/if}
  <ContenteditableInput
    label="Name"
    disabled={dis}
    labelWidth="5rem"
    value={fabrication.name}
    on:input={(evt) => update("name", evt.detail.value)} />
  {#if fabrication.vendor_id === $org.id}
    <ContenteditableInput
      label="Description"
      disabled={dis}
      labelWidth="5rem"
      value={fabrication.description}
      on:input={(evt) => update("description", evt.detail.value)} />
    <ContenteditableInput
      label="Category"
      disabled={dis}
      labelWidth="5rem"
      value={fabrication.category}
      on:input={(evt) => update("category", evt.detail.value)} />
  {/if}
  <TextInput label="Classification" disabled={dis} labelWidth="5rem" value={fabrication.classification} />
  {#if fabrication.vendor_id === $org.id}
    <TextInput
      label="Vendor Code"
      disabled={dis}
      labelWidth="5rem"
      value={fabrication.vendor_code}
      on:input={(evt) => update("vendor_code", evt.detail.value)} />
    <BooleanInput
      label="Published"
      disabled={dis}
      labelWidth="5rem"
      value={fabrication.published}
      on:input={(evt) => update("published", evt.detail.value)} />
  {/if}

  {#if $profile?.user_role === "developer" && !multiple}
    <CopyTextField label="ID" labelWidth="5rem" value={fabrication.id} />
  {/if}
</div>

{#if fabrication.vendor_id === $org.id && !multiple && $profile?.user_role === "developer"}
  <SidebarSection>
    <h3 class="px-2 mb-2">Properties</h3>
    <SelectInput
      label="Position"
      labelWidth="5.5rem"
      value={fabrication.data.position}
      {disabled}
      options={[
        { label: "Edge", value: "edge" },
        { label: "Edge Offset", value: "edge-offset" },
        { label: "Edge-Aligned", value: "edge-aligned" },
        { label: "Two-point", value: "two-point" },
        { label: "Corner", value: "corner" },
      ]}
      on:input={(e) => updateFabPosition(e.detail.value)} />
    <SelectInput
      label="Type"
      labelWidth="5.5rem"
      value={fabrication.data.type}
      {disabled}
      options={[
        ...(["edge", "corner"].includes(fabrication.data.position)
          ? [
              { label: "Flexible", value: "function" },
              { label: "Static DXF", value: "static" },
            ]
          : []),
        { label: "Generic", value: "generic" },
      ]}
      on:input={(e) => updateFabType(e.detail.value)} />
    {#if fabrication.data.type === "function"}
      <SelectInput
        label="Shape"
        {disabled}
        labelWidth="5.5rem"
        value={fabrication.data.func}
        on:input={(e) => updateShape(e.detail.value)}
        options={fabrication.data.position === "corner"
          ? [{ label: "Patch", value: "patch" }]
          : [
              { label: "Geneva", value: "geneva" },
              { label: "Cutout", value: "cutout" },
            ]} />
      {#each functionParams as param, index}
        <DimtextInput
          label={param.label}
          {disabled}
          labelWidth="5.5rem"
          value={fabrication.data.params[index]}
          on:mouseover={() => highlight(param)}
          on:focus={() => focus(param)}
          on:mouseout={() => unhighlight(param)}
          on:blur={() => blur(param)}
          {settings}
          on:input={(e) => updateParam(index, e.detail.value)} />
      {/each}
    {:else if fabrication.data.type === "generic"}
      <SelectInput
        label="Effect"
        labelWidth="5.5rem"
        value={fabrication.data.effect}
        {disabled}
        options={[
          { label: "Subtract", value: "subtract" },
          { label: "Add", value: "add" },
          { label: "Surface", value: "surface" },
        ]}
        on:input={(e) => updateFabEffect(e.detail.value)} />
    {/if}
  </SidebarSection>
{/if}

{#if fabrication.data?.type === "generic" && !multiple}
  <DrawingInput
    drawing={fabrication.data.drawing}
    recordName="fabrication"
    on:upload={(e) => updateFabProp("drawing", e.detail.drawing)}
    on:delete={() => updateFabProp("drawing", null)} />
{/if}
