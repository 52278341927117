import cloneDeep from "lodash/cloneDeep";
import lset from "lodash/set";
import { updateProp, addRecords, removeRecords } from "#src/api";
import {
  hydrateItem,
  hydrateType,
  updateItemCache,
  updateTypeCache,
  updateMaterialCache,
} from "@local/lamina-core";

import {
  addToSortableList,
  removeFromSortableList,
} from "@local/extensions/collections/sortable-list.js";

function matDbProps(mat) {
  if (!mat || mat.type === "none") {
    return { type: "none" };
  } else if (!mat.type) {
    return mat;
  } else if (mat.type === "fixed") {
    return mat;
  } else if (mat.type === "query") {
    const { type, value, default: def } = mat;
    return { type, value, default: def };
  } else if (mat.type === "option") {
    const { type, options, default: def } = mat;
    return { type, options, default: def };
  } else if (mat.type === "reference") {
    const { type, value } = mat;
    return { type, value };
  } else {
    return mat;
  }
}

function removeNonDbProps(item) {
  const { approver, updater, cache, ...rest } = item;
  return rest;
}

export function remExtdItemProdProps(itemProduct) {
  const {
    category_name,
    category_image,
    expanded_data,
    item_script,
    item_script_decl,
    ...rest
  } = itemProduct;
  return rest;
}

export function remExtdMakeupProps(makeup) {
  const {
    data,
    cache,
    expanded_data,
    _orig_data,
    category_image,
    category_name,
    ...rest
  } = makeup;
  const layers = data?.layers.map((l) => {
    return {
      ...l,
      material: matDbProps(l.material),
      ...(l.inboard_surface
        ? { inboard_surface: matDbProps(l.inboard_surface) }
        : {}),
      ...(l.outboard_surface
        ? { outboard_surface: matDbProps(l.outboard_surface) }
        : {}),
    };
  });
  return {
    ...rest,
    ...(data ? { data: { ...data, layers } } : {}),
  };
}

function updateSync(record, update) {
  let oldValue = {};
  let newValue = {};

  if (update.diff) {
    oldValue = Object.keys(update.diff).reduce((o, p) => {
      const path = p.split(".");
      const [col] = path;
      o[col] = record[col];
      return o;
    }, {});

    newValue = Object.keys(update.diff).reduce((o, p) => {
      const path = p.split(".");
      const [col, ...rest] = path;

      if (["data", "custom_column_data"].includes(col)) {
        if (rest.length > 0) {
          if (!o[col]) o[col] = cloneDeep(record[col]);
          lset(o[col], rest, update.diff[p]);
        } else {
          o[col] = update.diff[p];
        }
      } else {
        o[p] = update.diff[p];
      }

      return o;
    }, {});
  } else if (update.prop) {
    const path = update.prop.split(".");
    const [col, ...rest] = path;

    if (["data", "custom_column_data"].includes(col) && rest.length > 0) {
      oldValue = { [col]: record[col] };
      const val = cloneDeep(record[col]);
      lset(val, rest, update.value);
      newValue = { [col]: val };
    } else {
      oldValue = { [col]: record[col] };
      newValue = { [col]: update.value };
    }
  }

  Object.entries(newValue).forEach(([key, value]) => {
    record[key] = value;
  });

  return [oldValue, newValue];
}

function add(n, up, profile) {
  let callback;
  let undoSync;
  let undoAsync;
  if (up.type === "type") {
    const ids = up.records.map((t) => t.id);
    const old_data = cloneDeep(n.data);
    old_data.type_order = [...n.types.order];
    addToSortableList(n.types, ...up.records.map((t) => hydrateType(t)));
    n.data.type_order = [...n.types.order];

    callback = async () => {
      await addRecords(
        "types",
        up.records.map((t) => removeNonDbProps(t)),
      );
      await updateProp("groups", n.id, "data", n.data);
    };

    undoSync = (n) => {
      removeFromSortableList(n.types, ...ids);
    };

    undoAsync = async (n) => {
      await removeRecords("types", ids);
      await updateProp("groups", n.id, "data", old_data);
    };
  } else if (up.type === "item" || up.type === "collection") {
    const ids = up.records.map((i) => i.id);
    const old_data = cloneDeep(n.data);
    old_data.item_order = [...n.items.order];
    addToSortableList(n.items, ...up.records.map((i) => hydrateItem(i, n)));
    n.data.item_order = [...n.items.order];

    callback = async () => {
      await addRecords(
        "items",
        up.records.map((i) => removeNonDbProps(i)),
      );
      await updateProp("groups", n.id, "data", n.data);
    };

    undoSync = (n) => {
      removeFromSortableList(n.items, ...ids);
    };

    undoAsync = async (n) => {
      await removeRecords("items", ids);
      await updateProp("groups", n.id, "data", old_data);
    };
  } else if (up.type === "document") {
    const ids = up.records.map((d) => d.id);
    addToSortableList(n.documents, ...up.records);

    callback = async () => addRecords("documents", up.records);

    undoSync = (n) => {
      removeFromSortableList(n.documents, ...ids);
    };

    undoAsync = async (n) => {
      await removeRecords("documents", ids);
    };
  } else if (up.type === "location") {
    const ids = up.records.map((l) => l.id);
    addToSortableList(n.locations, ...up.records);
    callback = async () => addRecords("locations", up.records);

    undoSync = (n) => {
      removeFromSortableList(n.locations, ...ids);
    };

    undoAsync = async (n) => {
      await removeRecords("locations", ids);
    };
  }

  return [callback, undoSync, undoAsync];
}

function remove(n, up) {
  let callback;
  let undoSync;
  let undoAsync;
  if (up.type === "type") {
    const types = up.ids.map((id) => n.types[id]);
    removeFromSortableList(n.types, ...up.ids);
    n.data.type_order = [...n.types.order];

    callback = async () => {
      await removeRecords("types", up.ids);
      await updateProp("groups", n.id, "data", n.data);
    };

    undoSync = (n) => {
      addToSortableList(n.types, ...types);
      const data = n.data;
      data.type_order = [...n.types.order];
    };

    undoAsync = async (n) => {
      await addRecords(
        "types",
        types.map((t) => removeNonDbProps(t)),
      );
      await updateProp("groups", n.id, "data", n.data);
    };
  } else if (up.type === "item") {
    const items = up.ids.map((id) => n.items[id]);
    removeFromSortableList(n.items, ...up.ids);
    n.data.item_order = [...n.items.order];

    callback = async () => {
      await removeRecords("items", up.ids);
      await updateProp("groups", n.id, "data", n.data);
    };

    undoSync = (n) => {
      addToSortableList(n.items, ...items);
      const data = n.data;
      data.item_order = [...n.items.order];
    };

    undoAsync = async (n) => {
      await addRecords(
        "items",
        items.map((i) => removeNonDbProps(i)),
      );
      await updateProp("groups", n.id, "data", n.data);
    };
  } else if (up.type === "document") {
    const documents = up.ids.map((id) => n.documents[id]);
    removeFromSortableList(n.documents, ...up.ids);

    callback = async () => removeRecords("documents", up.ids);

    undoSync = (n) => {
      addToSortableList(n.documents, ...documents);
    };

    undoAsync = async (n) => {
      await addRecords("documents", documents);
    };
  } else if (up.type === "location") {
    const locations = up.ids.map((id) => n.locations[id]);
    removeFromSortableList(n.locations, ...up.ids);

    callback = async () => removeRecords("locations", up.ids);

    undoSync = (n) => {
      addToSortableList(n.locations, ...locations);
    };

    undoAsync = async (n) => {
      await addRecords("locations", locations);
    };
  }

  return [callback, undoSync, undoAsync];
}

function applyUpdate(record, update) {
  Object.entries(update).forEach(([key, value]) => {
    record[key] = value;
  });
}

function update(n, up, profile) {
  let callback;
  let undoSync;
  let undoAsync;
  if (up.type === "group") {
    const [ov, nv] = updateSync(n, up);

    callback = async () => updateProp("groups", n.id, nv);

    undoSync = (n) => {
      applyUpdate(n, ov);
    };

    undoAsync = async (n) => {
      await updateProp("groups", n.id, ov);
    };
  } else if (up.type === "organization") {
    const [ov, nv] = updateSync(n, up);

    callback = async () => updateProp("organizations", n.id, nv);

    undoSync = (n) => {
      applyUpdate(n, ov);
    };

    undoAsync = async (n) => {
      await updateProp("organzations", n.id, ov);
    };
  } else if (up.type === "item" || up.type === "collection") {
    const item = n.items[up.id];
    const [ov, nv] = updateSync(item, up);
    item.updated_at = new Date().toISOString();
    item.updater = profile;
    updateItemCache(item, n);

    callback = async () => updateProp("items", up.id, nv);

    undoSync = (n) => {
      const item = n.items[up.id];
      if (!item) return;
      applyUpdate(item, ov);
      item.updated_at = new Date().toISOString();
      item.updater = profile;
      updateItemCache(item, n);
    };

    undoAsync = async (n) => {
      await updateProp("items", up.id, ov);
    };
  } else if (up.type === "type") {
    const type = n.types[up.id];
    const [ov, nv] = updateSync(type, up);
    type.updated_at = new Date().toISOString();
    type.updater = profile;
    updateTypeCache(type);

    callback = async () => updateProp("types", up.id, nv);

    undoSync = (n) => {
      const type = n.types[up.id];
      if (!type) return;
      applyUpdate(type, ov);
      type.updated_at = new Date().toISOString();
      type.updater = profile;
      updateTypeCache(type);
    };

    undoAsync = async (n) => {
      await updateProp("types", up.id, ov);
    };
  } else if (up.type === "surface") {
    const surface = n.surfaces[up.id];
    const [ov, nv] = updateSync(surface, up);

    callback = async () => updateProp("product_entries", up.id, nv);

    undoSync = (n) => {
      const surface = n.surfaces[up.id];
      if (!surface) return;
      applyUpdate(surface, ov);
    };

    undoAsync = async (n) => {
      await updateProp("product_entries", up.id, ov);
    };
  } else if (up.type === "item_product") {
    const itemProduct = n.item_products[up.id];
    const [ov, nv] = updateSync(itemProduct, up);

    callback = async () =>
      updateProp("product_entries", up.id, remExtdItemProdProps(nv));

    undoSync = (n) => {
      const itemProduct = n.item_products[up.id];
      if (!itemProduct) return;
      applyUpdate(itemProduct, ov);
    };

    undoAsync = async (n) => {
      await updateProp("product_entries", up.id, remExtdItemProdProps(ov));
    };
  } else if (up.type === "material") {
    const material = n.materials[up.id];
    const [ov, nv] = updateSync(material, up);
    updateMaterialCache(material);

    callback = async () => updateProp("product_entries", up.id, nv);

    undoSync = (n) => {
      const material = n.materials[up.id];
      if (!material) return;
      applyUpdate(material, ov);
      updateMaterialCache(material);
    };

    undoAsync = async (n) => {
      await updateProp("product_entries", up.id, ov);
    };
  } else if (up.type === "fabrication") {
    const fabrication = n.fabrications[up.id];
    const [ov, nv] = updateSync(fabrication, up);

    callback = async () => updateProp("product_entries", up.id, nv);

    undoSync = (n) => {
      const fabrication = n.fabrications[up.id];
      if (!fabrication) return;
      applyUpdate(fabrication, ov);
    };

    undoAsync = async (n) => {
      await updateProp("product_entries", up.id, ov);
    };
  } else if (up.type === "makeup") {
    const makeup = n.makeups[up.id];
    const [ov, nv] = updateSync(makeup, up);
    if (makeup.application === "makeup") {
      updateTypeCache(makeup);
      callback = async () =>
        updateProp("product_entries", up.id, remExtdMakeupProps(nv));

      undoSync = (n) => {
        const makeup = n.makeups[up.id];
        if (!makeup) return;
        applyUpdate(makeup, ov);
        updateTypeCache(makeup);
      };

      undoAsync = async (n) => {
        await updateProp("product_entries", up.id, remExtdMakeupProps(ov));
      };
    } else {
      callback = async () => updateProp("product_entries", up.id, nv);

      undoSync = (n) => {
        const makeup = n.makeups[up.id];
        if (!makeup) return;
        applyUpdate(makeup, ov);
      };

      undoAsync = async (n) => {
        await updateProp("product_entries", up.id, ov);
      };
    }
  } else {
    const recName = `${up.type}s`;
    const record = n[recName]?.[up.id];
    if (!record) {
      return [() => {}, () => {}];
    }
    const [ov, nv] = updateSync(record, up);
    callback = async () => updateProp(recName, up.id, nv);

    undoSync = (n) => {
      const record = n[recName]?.[up.id];
      if (!record) return;
      applyUpdate(record, ov);
    };

    undoAsync = async (n) => {
      await updateProp(recName, up.id, ov);
    };
  }

  return [callback, undoSync, undoAsync];
}

const actions = { add, remove, update };

async function execSequentially(updates, promises, n) {
  for (const [i, up] of updates.entries()) {
    const cb = promises[i];
    if (up.block) {
      await cb(n);
    } else {
      cb(n);
    }
  }
}

export function updateCommit(updates, $p = null) {
  const commit = async (n) => {
    const callbacks = [];
    const undosSync = [];
    const undosAsync = [];

    updates.forEach((up) => {
      const action = actions[up.action] || update;
      const [cb, undoSync, undoAsync] = action(n, up, $p);
      callbacks.push(cb);
      undosSync.push(undoSync);
      undosAsync.push(undoAsync);
    });

    if (updates.some((up) => up.await)) {
      await execSequentially(updates, callbacks);
    } else {
      execSequentially(updates, callbacks);
    }

    return async (n) => {
      undosSync.toReversed().forEach((undo, i) => {
        undo(n);
      });

      let undoUpdates = updates.toReversed();
      if (undoUpdates.some((up) => up.block)) {
        undoUpdates = undoUpdates.map((up) => {
          return { ...up, block: !up.block };
        });
      }

      let uasync = undosAsync.toReversed();

      if (undoUpdates.some((up) => up.await)) {
        await execSequentially(undoUpdates, uasync, n);
      } else {
        execSequentially(undoUpdates, uasync, n);
      }
    };
  };

  return commit;
}

export function addCommit(
  recordType,
  table,
  parentTable,
  records,
  hydrate,
  strip,
) {
  const ids = records.map((t) => t.id);

  const commit = async (n) => {
    const recordList = n[`${recordType}s`];
    const old_data = cloneDeep(n.data);
    old_data[`${recordType}_order`] = cloneDeep(recordList.order);

    if (hydrate) {
      addToSortableList(recordList, ...records.map((t) => hydrate(t)));
    } else {
      addToSortableList(recordList, ...records);
    }
    n.data[`${recordType}_order`] = [...recordList.order];

    await addRecords(table, strip ? records.map((t) => strip(t)) : records);

    updateProp(parentTable, n.id, "data", n.data);

    return async (n) => {
      removeFromSortableList(n[`${recordType}s`], ...ids);
      await removeRecords(table, ids);
      updateProp(parentTable, n.id, "data", old_data);
    };
  };

  return commit;
}

export function removeCommit(
  recordType,
  table,
  parentTable,
  ids,
  hydrate,
  strip,
) {
  const commit = (n, commitId) => {
    const recordList = n[`${recordType}s`];
    const old_data = cloneDeep(n.data);
    old_data[`${recordType}_order`] = [...recordList.order];

    removeFromSortableList(recordList, ...ids);
    n.data[`${recordType}_order`] = [...recordList.order];

    const res = {};
    removeRecords(table, ids).then((r) => (res[`${recordType}s`] = r));
    updateProp(parentTable, n.id, "data", n.data);

    return (n) => {
      const rl = res[`${recordType}s`];
      if (hydrate) {
        addToSortableList(recordList, ...rl.map((t) => hydrateType(t)));
      } else {
        addToSortableList(recordList, ...rl);
      }
      addRecords(table, strip ? rl.map((t) => strip(t)) : rl);
      updateProp(parentTable, n.id, "data", old_data);
    };
  };

  return commit;
}

export function reorderCommit(recordType, parentTable, order) {
  const commit = (n, commitId) => {
    const oldOrder = n[`${recordType}s`].order;
    const oldData = n.data;

    n[`${recordType}s`].order = order;
    const data = cloneDeep(n.data);
    lset(data, `${recordType}_order`, order);

    updateProp(parentTable, n.id, "data", data);

    return (n) => {
      n[`${recordType}s`].order = oldOrder;
      lset(n.data, oldData);
      updateProp(parentTable, n.id, "data", oldData);
    };
  };

  return commit;
}
