<script>
  import range from "lodash/range";
  import { onMount } from "svelte";

  export let debug = false;

  export let src = null;
  export let pages = 1;
  export let tileList = null;
  export let options = {};
  export let controller = null;
  export let tileOverlay = null;
  export let overlay = null;
  export let pannable = true;
  export let rightMousePan = false;
  export let initialPage = null;
  export const redraw = () => {
    viewer && viewer.forceRedraw();
  };
  export const pixelCoords = (tileCoords) => {
    if (!tileCoordsConvFunc) return;
    return tileCoordsConvFunc(tileCoords);
  };
  export const tileCoords = (pixelCoords, tileIndex) => {
    if (!pixelCoordsConvFunc) return;
    return pixelCoordsConvFunc(pixelCoords, tileIndex);
  };
  export const nearestTile = (pixelCoords) => {
    if (!nearestTileFunc) return;
    return nearestTileFunc(pixelCoords);
  };
  export const isPointVisible = (point) => {
    if (!isPointVisibleFunc) return;
    return isPointVisibleFunc(point);
  };
  export const tile = (index) => {
    if (!tileFunc) return;
    return tileFunc(index);
  };

  /** @type {HTMLDivElement} */
  let el;

  /** @type {import('openseadragon').Viewer} */
  let viewer;

  let hitCanvases = [];
  let hitContexts = [];
  let dragPosition = null;
  let nonPrimaryDown = false;
  let tileCoordsConvFunc = null;
  let pixelCoordsConvFunc = null;
  let nearestTileFunc = null;
  let isPointVisibleFunc = null;
  let tileFunc = null;
  let tsPaths = [];

  const dpi = window.devicePixelRatio;

  $: updatePannable(pannable);
  $: updateTiles(tileList);

  function setDebug(mode) {
    if (viewer) {
      viewer.setDebugMode(mode);
    }
  }

  function updatePannable(pannable) {
    if (viewer) {
      viewer.panHorizontal = pannable;
      viewer.panVertical = pannable;
    }
  }

  function blankWhiteTileSource() {
    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8A/////wn7A/2j0UkKAAAAAElFTkSuQmCC";
  }

  function sources(tl) {
    return tl.map((i) => {
      if (!i.url) {
        return {
          type: "image",
          url: blankWhiteTileSource(),
        };
      } else if (i.type === "image") {
        return { type: "image", url: i.url };
      } else {
        return i.url;
      }
    });
  }

  function updateTiles(tileList) {
    if (!tileList) return;
    if (!viewer) return;

    const newTsPaths = tileList.map((t) => t.url);
    const totalTiles = viewer.world.getItemCount();

    // Check whether a tileSource requires updating
    if (tileList.length !== totalTiles || newTsPaths.some((path, i) => path !== tsPaths[i])) {
      const tileSources = sources(tileList);

      tileSources.forEach((ts, i) => {
        // Update hitCanvases
        if (!hitCanvases[i] || newTsPaths[i] !== tsPaths[i]) {
          hitCanvases[i] = document.createElement("canvas");
          const context = hitCanvases[i].getContext("2d");
          context.imageSmoothingEnabled = false;
          hitContexts[i] = context;
        }

        // Update tiles
        if (tsPaths[i] !== tileList[i].url) {
          const existing = viewer.world.getItemAt(i);

          if (ts.type === "image") {
            viewer.addSimpleImage({
              url: ts.url,
              index: i,
              replace: existing ? true : false,
            });
          } else {
            viewer.addTiledImage({
              tileSource: ts,
              index: i,
              replace: existing ? true : false,
            });
          }
        }
      });

      // Remove any extra hitCanvases if necessary
      if (hitCanvases.length > tileSources.length) {
        hitCanvases.splice(tileSources.length);
      }

      // Remove any extra tiles if necessary
      if (totalTiles > tileSources.length) {
        for (let i = totalTiles - 1; i > tileSources.length - 1; i--) {
          const tile = viewer.world.getItemAt(i);
          if (tile) {
            viewer.world.removeItem(tile);
          }
        }
      }

      tsPaths = tileList.map((i) => i.url);
    }
  }

  function getTileSources(url, startPage, endPage) {
    const sources = [];
    for (let p = startPage; p < endPage; p++) {
      sources.push({ type: "iiif", url: `${url}.${p}.tiff/info.json` });
    }
    return sources;
  }

  function fitCols(aspectRatio, numItems) {
    if (numItems < 2) return 1;

    const aspectRatios = range(1, numItems + 1).map((c) => {
      const rows = Math.ceil(numItems / c);
      const rAr = c / rows;
      return Math.abs(rAr - aspectRatio);
    });

    const min = Math.min(...aspectRatios);
    const minIndex = aspectRatios.indexOf(min) + 1;
    return minIndex;
  }

  onMount(async () => {
    const { default: OpenSeadragon, MouseTracker, Point } = await import("openseadragon");
    const { default: CanvasOverlayHD } = await import("./canvasOverlayHD.js");
    const { default: getTileCoordsFromPixel } = await import("./getTileCoordsFromPixel.js");
    const { default: getPixelCoordsFromTile } = await import("./getPixelCoordsFromTile.js");
    const { default: getNearestTile } = await import("./getNearestTile.js");

    const tileSources = tileList ? sources(tileList) : getTileSources(src, 0, pages);

    if (tileList) {
      tsPaths = tileList.map((i) => i.url);
    }

    hitCanvases = range(0, tileSources.length).map(() => {
      return document.createElement("canvas");
    });

    hitContexts = hitCanvases.map((canvas) => {
      const context = canvas.getContext("2d");
      context.imageSmoothingEnabled = false;
      return context;
    });

    if (!el) return;
    const ar = el.clientWidth / el.clientHeight;
    const cols = fitCols(ar, tileSources.length);

    viewer = OpenSeadragon({
      element: el,
      prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
      tileSources: tileSources,
      collectionMode: true,
      collectionRows: null,
      collectionColumns: cols,
      collectionLayout: "horizontal",
      springStiffness: 50,
      zoomPerClick: 1,
      zoomPerScroll: 1.8,
      maxZoomLevel: 128,
      debugMode: debug,
      showNavigator: false,
      showNavigationControl: !controller,
      panHorizontal: pannable,
      panVertical: pannable,
      preload: false,
      ...options,
    });

    if (tileOverlay || overlay) {
      const options = { onTileRedraw: null, onRedraw: null };

      if (tileOverlay) {
        options.onTileRedraw = (opts) => {
          const canvas = opts.context.canvas;
          const w = canvas.clientWidth;
          const h = canvas.clientHeight;

          const hitCanvas = hitCanvases[opts.index];
          const hitContext = hitContexts[opts.index];

          if (!hitCanvas || !hitContext) return;

          hitCanvas.style.width = `${w}px`;
          hitCanvas.style.height = `${h}px`;
          hitCanvas.width = canvas.width;
          hitCanvas.height = canvas.height;
          const transform = opts.context.getTransform();
          hitContext.setTransform(transform);

          tileOverlay({
            ...opts,
            hitContext,
          });
        };
      }

      if (overlay) {
        options.onRedraw = (opts) => {
          overlay(opts);
        };
      }

      new CanvasOverlayHD(viewer, options);
    }

    tileCoordsConvFunc = (tileCoords) => {
      return getPixelCoordsFromTile(viewer, tileCoords);
    };

    pixelCoordsConvFunc = (pixelCoords, tileIndex) => {
      const pt = new Point(pixelCoords.x, pixelCoords.y);
      return getTileCoordsFromPixel(viewer, pt, tileIndex);
    };

    nearestTileFunc = (pixelCoords) => {
      const pt = new Point(pixelCoords.x, pixelCoords.y);
      return getNearestTile(viewer, pt);
    };

    isPointVisibleFunc = (point) => {
      const { viewport } = viewer;
      const pt = new Point(point.x, point.y);
      const vpPt = viewport.pointFromPixel(pt);
      const bounds = viewport.getBounds();
      return bounds.containsPoint(vpPt);
    };

    tileFunc = (index) => {
      return viewer.world.getItemAt(index);
    };

    if (controller) {
      controller.on("zoom-in", () => {
        const { viewport } = viewer;
        viewport.zoomBy(2);
        viewport.applyConstraints();
      });

      controller.on("zoom-out", () => {
        const { viewport } = viewer;
        viewport.zoomBy(0.5);
        viewport.applyConstraints();
      });

      controller.on("zoom-to-page", (page) => {
        const { viewport } = viewer;
        if (Array.isArray(page)) {
          const tiles = page
            .map((p) => viewer.world.getItemAt(p))
            .filter((t) => t)
            .map((t) => t.getBounds());

          if (tiles.length > 0) {
            const [first, ...rest] = tiles;
            let bounds = first;
            rest.forEach((b) => {
              bounds = bounds.union(b);
            });
            viewport.fitBounds(bounds);
          }
        } else {
          const tile = viewer.world.getItemAt(page);
          if (tile) {
            viewport.fitBounds(tile.getBounds());
          }
        }
      });

      controller.on("pan-to-location", (tileCoords) => {
        const { viewport } = viewer;
        const tiledImage = viewer.world.getItemAt(tileCoords.index);
        if (!tiledImage) return;
        const vpCoords = tiledImage.imageToViewportCoordinates(tileCoords.x, tileCoords.y);
        viewport.panTo(vpCoords);
      });

      controller.on("home", () => {
        viewer.viewport.goHome();
      });

      controller.on("debug", (checked) => {
        console.log("DEBUG!", checked);
        setDebug(checked);
      });

      viewer.addHandler("canvas-double-click", (e) => {
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-double-click", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.addHandler("canvas-click", (e) => {
        if (!e.quick) return;
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-click", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.addHandler("canvas-press", (e) => {
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-press", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.addHandler("canvas-drag", (e) => {
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-drag", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.addHandler("open", (e) => {
        controller.dispatch("open", e);
      });

      viewer.addHandler("canvas-drag-end", (e) => {
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-drag-end", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.canvas.addEventListener("mousemove", (e) => {
        const position = new Point(e.offsetX, e.offsetY);
        const tilePosition = getTileCoordsFromPixel(viewer, position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-mousemove", {
          hitContext: hitContext,
          position,
          tilePosition,
        });
      });

      // Handle right-mouse-drag
      if (rightMousePan) {
        viewer.addHandler("canvas-nonprimary-press", (e) => {
          nonPrimaryDown = true;
          dragPosition = e.position.clone();
          controller.dispatch("canvas-nonprimary-press", e);
        });

        viewer.addHandler("canvas-nonprimary-release", (e) => {
          nonPrimaryDown = false;
          controller.dispatch("canvas-nonprimary-release", e);
        });

        new MouseTracker({
          element: viewer.canvas,
          moveHandler: (e) => {
            if (nonPrimaryDown) {
              const delta = e.position.minus(dragPosition);
              viewer.viewport.panBy(viewer.viewport.deltaPointsFromPixels(delta.negate()));
              dragPosition = e.position.clone();
            }
          },
        });
      }

      // Handle two-finger pan
      if (!pannable && "ontouchstart" in window) {
        let panning = null;
        viewer.canvas.addEventListener("touchstart", (e) => {
          if (e.touches.length === 2) {
            const a = e.touches[0];
            const b = e.touches[1];
            const x = (a.clientX + b.clientX) / 2;
            const y = (a.clientY + b.clientY) / 2;
            const pt = new Point(x, y);
            panning = {
              start: pt,
              end: pt,
            };
          }
        });

        viewer.canvas.addEventListener("touchmove", (e) => {
          if (panning && e.touches.length === 2) {
            const a = e.touches[0];
            const b = e.touches[1];
            const x = (a.clientX + b.clientX) / 2;
            const y = (a.clientY + b.clientY) / 2;
            const pt = new Point(x, y);
            const delta = pt.minus(panning.end);
            viewer.viewport.panBy(viewer.viewport.deltaPointsFromPixels(delta.negate()));
            panning.end = pt;
          }
        });

        viewer.canvas.addEventListener("touchend", (e) => {
          panning = null;
        });
      }

      viewer.addHandler("canvas-exit", (e) => {
        if (rightMousePan && nonPrimaryDown) {
          nonPrimaryDown = false;
          controller.dispatch("canvas-nonprimary-release", e);
        }

        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-exit", {
          ...e,
          hitContext,
          tilePosition,
        });
      });

      viewer.addHandler("canvas-enter", (e) => {
        const tilePosition = getTileCoordsFromPixel(viewer, e.position);
        const hitContext = tilePosition.index >= 0 ? hitContexts[tilePosition.index] : null;
        controller.dispatch("canvas-enter", {
          ...e,
          hitContext,
          tilePosition,
        });
      });
    }

    if (initialPage) {
      const page = parseInt(initialPage);
      const { viewport } = viewer;
      viewer.addHandler("open", () => {
        const tile = viewer.world.getItemAt(page);
        viewport.fitBounds(tile.getBounds());
      });
    }

    return () => {
      viewer.destroy();
      viewer = null;
    };
  });

  $: setDebug(debug);
</script>

<div class="osd-container" on:contextmenu|preventDefault>
  <div class="viewer" bind:this={el} />
</div>

<style>
  .osd-container {
    width: 100%;
    height: 100%;
  }
  .viewer {
    width: 100%;
    height: 100%;
  }

  :global(.openseadragon-canvas) {
    outline: none;
  }
</style>
