<script>
  import { getContext } from "svelte";

  import EditIcon from "@local/assets/icons/edit.svg";
  import PopoutIcon from "@local/assets/icons/popout.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";

  import { Modal } from "svelte-utilities";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import LibrarySection from "#lib/sidebar/LibrarySection.svelte";
  import MakeupThumbnail from "#lib/MakeupThumbnail.svelte";
  import SidebarTitle from "#lib/sidebar/SidebarTitle.svelte";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";
  import TextareaInput from "#lib/sidebar/TextareaInput.svelte";
  import { currentSupplierid } from "#src/stores/ui.js";
  import { updateProp, removeRecords, api } from "#src/api";

  const org = getContext("org");
  const productLists = getContext("productLists");
  const priceEntries = getContext("priceEntries");
  const refreshSupplier = getContext("refreshSupplier");

  let addModal;
  let newList = {
    parent_id: null,
    sort_order: null,
    name: "",
    currency: "USD",
  };

  let editModal;
  let editList = {
    id: null,
    parent_id: null,
    sort_order: null,
    allow_price_override: false,
    name: "",
    currency: "USD",
  };

  let quoteItemScriptModal;
  let quoteItemScript = {
    id: null,
    owner_id: null,
    product_list_id: null,
    name: null,
    script: null,
  };

  let deleteModal;
  let deleteScriptModal;
  let previewModal;
  let library = null;
  let libraryTitle = null;

  export let disabled = false;

  function intParser(num) {
    if (num == null || num === "") return null;
    return parseInt(num);
  }

  function numValidator(num) {
    if (num == null) return true;
    return !Number.isNaN(num);
  }

  function previewLibrary(org, list) {
    const categories = org.makeups.order
      .filter((id) => {
        if (!list) return true;
        const prodPe = $priceEntries[id];
        if (!prodPe) return false;
        return prodPe[list.id]?.length;
      })
      .filter((id) => org.makeups[id]?.published)
      .reduce((lib, id) => {
        const makeup = org.makeups[id];
        if (!makeup.category) {
          if (!lib._uncategorized) lib._uncategorized = [];
          lib._uncategorized.push(makeup);
        } else {
          if (!lib[makeup.category]) lib[makeup.category] = [];
          lib[makeup.category].push(makeup);
        }
        return lib;
      }, {});

    const u = categories._uncategorized;
    delete categories._uncategorized;
    if (Object.keys(categories).length === 0) {
      return [{ records: u || [] }];
    }

    const cats = Object.keys(categories).sort((a, b) => a.localeCompare(b));
    const lib = cats.map((cat) => ({
      title: cat,
      records: categories[cat],
    }));

    if (u) {
      lib.push({
        title: "Uncategorized",
        records: u,
      });
    }
    return lib;
  }

  function confirmAddProductList() {
    addModal.open();
  }

  async function addProductList() {
    await api.from("product_lists").insert({
      ...newList,
      vendor_id: $org.id,
    });

    refreshSupplier($currentSupplierid);
  }

  function editProductList(list) {
    const { price_entries, ...listData } = list;
    editList = { ...listData };
    editModal.open();
  }

  function editQuoteItemScript(script) {
    quoteItemScript = { ...script };
    quoteItemScriptModal.open();
  }

  async function updateQuoteItemScript() {
    const { id, product_list_id, owner_id, ...rest } = quoteItemScript;
    await updateProp("quote_item_scripts", id, rest);
    refreshSupplier($currentSupplierid);
  }

  function confirmRemoveQuoteItemScript(script) {
    quoteItemScript = { ...script };
    deleteScriptModal.open();
  }

  async function deleteQuoteItemScript() {
    await removeRecords("quote_item_scripts", [quoteItemScript.id]);
    refreshSupplier($currentSupplierid);
  }

  function openPreview(list) {
    library = previewLibrary($org, list);
    libraryTitle = list ? list.name : "All Products";
    previewModal.open();
  }

  function confirmRemoveProductList(list) {
    editList = { ...list };
    deleteModal.open();
  }

  async function deleteProductList() {
    const id = editList.id;

    await removeRecords("product_lists", [id]);
    refreshSupplier($currentSupplierid);
  }

  async function updateProductList() {
    const { id, quote_item_scripts, sort_order, ...rest } = editList;
    await updateProp("product_lists", id, rest);
    refreshSupplier($currentSupplierid);
  }
</script>

<div>
  <SidebarTitle title="Product Lists" />
  <div class="px-6 text-xs py-2">
    <div class="space-y-2 mb-2">
      <div class="w-full flex items-center h-6 pl-item">
        <button class="flex gap-2 items-center" on:click={() => openPreview()}>
          <div>All Products</div>
          <div class="flex items-center gap-1">
            <button class="p-0.5 rounded hover:bg-gray-200">
              <PopoutIcon />
            </button>
          </div>
        </button>
      </div>
      {#each $productLists as list}
        <div class="w-full flex items-center h-6 justify-between pl-item">
          <button class="flex gap-2 items-center" {disabled} on:click={() => editProductList(list)}>
            <div>
              {list.name}
            </div>
            <div class="text-gray-400">
              {list.currency}
            </div>
            <div class="flex items-center gap-1">
              {#if !disabled}
                <button class="p-0.5 rounded hover:bg-gray-200">
                  <EditIcon />
                </button>
              {/if}
              <button
                class="p-0.5 rounded hover:bg-gray-200"
                on:click|stopPropagation={() => openPreview(list)}>
                <PopoutIcon />
              </button>
            </div>
          </button>
          {#if !disabled}
            <button class="pl-buttons" on:click={() => confirmRemoveProductList(list)}>
              <TrashIcon />
            </button>
          {/if}
        </div>
        {#each list.quote_item_scripts as script}
          <div class="w-full flex items-center justify-between pl-item pl-4">
            <button class="flex gap-2 items-center" {disabled} on:click={() => editQuoteItemScript(script)}>
              <div>
                {script.name}
              </div>
              {#if !disabled}
                <button class="p-0.5 rounded hover:bg-gray-200">
                  <EditIcon />
                </button>
              {/if}
            </button>
            {#if !disabled}
              <button class="pl-buttons" on:click={() => confirmRemoveQuoteItemScript(script)}>
                <TrashIcon />
              </button>
            {/if}
          </div>
        {/each}
      {/each}
      {#if !disabled}
        <div class="flex justify-end">
          <button class="font-bold" on:click={confirmAddProductList}> + New Product List </button>
        </div>
      {/if}
    </div>
  </div>
</div>

<Modal
  closeable
  on:confirm={() => addProductList()}
  bind:this={addModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Add", type: "confirm", style: "primary", disabled: !newList.name },
  ]}>
  <div slot="title">Add Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <TextInput label="Name" inputOnKeydown border bind:value={newList.name} />
    <SelectInput
      label="Currency"
      border
      bind:value={newList.currency}
      options={[
        { label: "USD", value: "USD" },
        { label: "CAD", value: "CAD" },
      ]} />
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => updateProductList()}
  bind:this={editModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}>
  <div slot="title">Edit Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <TextInput label="Name" inputOnKeydown border bind:value={editList.name} />
    <SelectInput
      label="Currency"
      border
      bind:value={editList.currency}
      options={[
        { label: "USD", value: "USD" },
        { label: "CAD", value: "CAD" },
      ]} />
    <BooleanInput border label="Allow Price Override" bind:value={editList.allow_price_override} />
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => deleteProductList()}
  bind:this={deleteModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Remove Product List</div>
  <div slot="content" class="space-y-4 text-sm">
    <div>Are you sure you want to remove this product list?</div>
    <div class="flex gap-2 px-4">
      <span class="font-bold">{editList.name}</span><span class="text-gray-500"> {editList.currency}</span>
    </div>
  </div>
</Modal>

<Modal
  closeable
  on:confirm={() => deleteQuoteItemScript()}
  bind:this={deleteScriptModal}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}>
  <div slot="title">Remove Quote Item Script</div>
  <div slot="content" class="space-y-4 text-sm">
    <div>Are you sure you want to remove this script?</div>
    <div class="flex gap-2 px-4">
      <span class="font-bold">{editList.name}</span><span class="text-gray-500"> {quoteItemScript.name}</span>
    </div>
  </div>
</Modal>

<Modal closeable bind:this={previewModal} width="36rem">
  <div slot="title">Product List: {libraryTitle}</div>
  <div slot="content" class="overflow-y-auto">
    {#if !library?.length}
      <div class="italic text-xs">No products in list</div>
    {/if}
    {#each library as section}
      <LibrarySection {section}>
        <MakeupThumbnail slot="thumbnail" let:record {record} />
        <div slot="caption" let:record>
          {record.name}
        </div>
      </LibrarySection>
    {/each}
  </div>
</Modal>

<Modal
  closeable
  bind:this={quoteItemScriptModal}
  width="36rem"
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Update", type: "confirm", style: "primary" },
  ]}
  on:confirm={updateQuoteItemScript}>
  <div slot="title">Quote Item Script: {quoteItemScript.name}</div>
  <div slot="content" class="overflow-y-auto space-y-4">
    <TextInput label="Name" inputOnKeydown border bind:value={quoteItemScript.name} />
    <TextareaInput bind:value={quoteItemScript.script} fullwidth mono rows={15} />
  </div>
</Modal>

<style lang="scss">
  .pl-buttons {
    @apply p-0.5 rounded;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .pl-item {
    .pl-buttons {
      display: none;
    }

    &:hover {
      .pl-buttons {
        display: block;
      }
    }
  }
</style>
