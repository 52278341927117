<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { tooltip as ttaction } from "svelte-utilities";

  const dispatch = createEventDispatcher();

  const colorMode = getContext("toolbar-color-mode");

  export let type = "enum";
  export let toolid = "select";

  /**
   * @type {string | null | boolean} Tool state
   */
  export let state = null;
  export let disabled = false;

  /**
   * @type {string | null} Tooltip text
   */
  export let tooltip = null;

  $: cm = colorMode === "light" ? "light" : "dark";
  $: active = {
    enum: () => toolid === state,
    toggle: () => !!state,
    button: () => toolid === state && state !== null,
  }[type]();

  function clickTool(evt) {
    dispatch("click", { state });

    if (type === "button") {
      return;
    } else if (type === "toggle") {
      state = !state;
    } else {
      state = toolid;
    }
  }
</script>

<button
  class="tool-button"
  class:dark-mode={cm === "dark"}
  class:light-mode={cm === "light"}
  class:outline-none={!active}
  class:disabled
  class:active
  {disabled}
  on:click={clickTool}
  use:ttaction={{ text: tooltip }}>
  <slot />
</button>

<style lang="scss">
  .tool-button {
    @apply opacity-50 cursor-pointer p-1 rounded;

    &.dark-mode {
      &:hover:not(.disabled) {
        @apply bg-gray-700 opacity-100;
      }

      &.active {
        @apply opacity-100 bg-gray-700;
      }
    }

    &.light-mode {
      &:hover:not(.disabled) {
        @apply bg-gray-200 opacity-100;
      }

      &.active {
        @apply opacity-100 bg-gray-200;
      }
    }

    &.disabled {
      @apply opacity-20 cursor-not-allowed;
    }
  }
</style>
