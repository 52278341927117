<script>
  import { onMount, createEventDispatcher, getContext } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import { objectify } from "overline";
  import eb from "#src/extensions/event-bus.js";
  import { selectedPages } from "#src/stores/ui.js";
  import { transformTilePoint } from "@local/extensions/geometry/transform-sheet-points.js";

  import XICon from "@local/assets/icons/x.svg";
  import RadioInput from "#lib/sidebar/RadioInput.svelte";

  import { api } from "#src/api/";

  export let group;
  export let doc;
  export let visiblePages;
  export let visibleIndices;
  export let pageRegions;
  export let showPageRelabelRegions;

  const dispatch = createEventDispatcher();
  const fileData = getContext("fileData");
  const sf = 300 / 72;

  let visible = false;
  let pages = "all";
  let regions = "title";
  let state = "start";
  let pagesToRelabel = [];
  let updates = [];
  let errorMessage = "";

  $: noPagesSelected = Object.keys($selectedPages).length === 0;

  function tileSize(w, h, sr) {
    if ([90, 270].includes(sr)) {
      return {
        x: h * sf,
        y: w * sf,
      };
    }

    return {
      x: w * sf,
      y: h * sf,
    };
  }

  function clip(x, y, w = 2000, h = 500) {
    return { x, y, width: w, height: h };
  }

  function toggleVisible() {
    visible = !visible;
    state = "start";
    if (!visible) {
      showPageRelabelRegions = false;
    }
  }

  function regionsMatchSetting(pageRegions, regions) {
    if (!pageRegions) return false;
    if (regions === "title") return pageRegions.number == null;
    return pageRegions.number != null;
  }

  function beginSelectingRegions() {
    state = "selecting";
    let selectedPage;

    if (noPagesSelected || pages === "all") {
      pagesToRelabel = [...visiblePages];
      selectedPage = visiblePages[0];
    } else {
      const first = visiblePages.find((p) => $selectedPages[p]);
      pagesToRelabel = Object.keys($selectedPages).filter((p) => $selectedPages[p]);
      selectedPage = first;
    }

    try {
      if (!pagesToRelabel.length) throw new Error("No pages selected.");

      pagesToRelabel.forEach((p) => {
        const page = doc.data.pages[p];
        const sheet = $fileData[page.name]?.sheets[page.page];
        if (!sheet) throw new Error("Every page must be fully processed before relabeling.");
      });

      const first = pagesToRelabel[0];
      const page = doc.data.pages[first];
      const sheet = $fileData[page.name]?.sheets[page.page];
      const r = sheet.rotation || 0;
      const limits = sheet.limits;
      const w = limits[1][0] - limits[0][0];
      const h = limits[1][1] - limits[0][1];
      const ts = tileSize(w, h, r);
      const bw = ts.x / 5;
      const bh = ts.x / 15;
      const g = ts.x / 100;
      const x = -bw - g;

      if (!regionsMatchSetting(pageRegions, regions)) {
        pageRegions =
          regions === "title"
            ? { title: clip(x, -bh - g, bw, bh), number: null }
            : {
                title: clip(x, -2 * (bh + g), bw, bh),
                number: clip(x, -bh - g, bw, bh),
              };
      }

      showPageRelabelRegions = true;

      dispatch("zoom-to-page", { page: selectedPage });
    } catch (error) {
      state = "error";
      errorMessage = error.message;
    }
  }

  async function finishSelectingRegions() {
    try {
      state = "working";

      const first = pagesToRelabel[0];
      const page = doc.data.pages[first];
      const sheet = $fileData[page.name]?.sheets[page.page];
      const r = sheet.rotation || 0;
      const limits = sheet.limits;
      const w = limits[1][0] - limits[0][0];
      const h = limits[1][1] - limits[0][1];
      const ts = tileSize(w, h, r);
      const titleTp = clipPoints(pageRegions.title)
        .map((p) => ({
          x: p.x + ts.x,
          y: p.y + ts.y,
        }))
        .map((p) => transformTilePoint(p, r, sf, ts));
      const regs = [titleTp];

      if (regions === "title-number") {
        const numberTp = clipPoints(pageRegions.number)
          .map((p) => ({
            x: p.x + ts.x,
            y: p.y + ts.y,
          }))
          .map((p) => transformTilePoint(p, r, sf, ts));
        regs.push(numberTp);
      }

      const overlaps = await api.rpc("find_overlapping_text_objects", {
        document_id: doc.id,
        pages: pagesToRelabel,
        regions: regs,
      });
      if (overlaps.error) throw new Error("Error finding overlapping text objects.");

      const o = objectify(overlaps.data, "page_id");
      updates = pagesToRelabel
        .map((p) => {
          let label;
          if (regions === "title-number") {
            const page = o[p].overlapping_regions[0][0]?.content;
            const number = o[p].overlapping_regions[1][0]?.content;
            if (page != null && number != null) {
              label = `${number} - ${page}`;
            } else if (page != null) {
              label = page;
            } else {
              label = number;
            }
          } else {
            label = o[p].overlapping_regions[0][0]?.content;
          }

          return {
            page_id: p,
            label,
          };
        })
        .filter((u) => u.label != null);

      state = "confirm";
    } catch (error) {
      state = "error";
      errorMessage = error.message;
    }
  }

  function clipPoints(clip) {
    return [
      { x: clip.x, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y },
      { x: clip.x, y: clip.y },
      { x: clip.x, y: clip.y + clip.height },
    ];
  }

  async function renamePages() {
    try {
      const data = cloneDeep(doc.data);
      updates.forEach((u) => {
        data.pages[u.page_id].label = u.label;
      });
      group.updateDocument(doc.id, { data });

      toggleVisible();
    } catch (error) {
      state = "error";
      errorMessage = error.message;
    }
  }

  onMount(() => {
    eb.on("relabel-pages", toggleVisible);

    return () => {
      eb.unsubscribe("relabel-pages", toggleVisible);
    };
  });
</script>

{#if visible}
  <div class="w-full flex justify-end p-4 absolute z-30 pointer-events-none text-xs">
    <div class="w-72 pointer-events-auto">
      <div class="bg-white rounded border shadow-lg w-full">
        <div class="p-2 flex items-center border-b">
          <div class="grow">Create Page Labels</div>
          <button on:click={toggleVisible} class="rounded p-1 hover:bg-gray-200">
            <XICon />
          </button>
        </div>
        <div class="p-2">
          {#if state === "start"}
            <div class="space-y-2 mb-2">
              <RadioInput
                label="Pages"
                labelWidth="3.5rem"
                bind:value={pages}
                options={[
                  { label: "All", value: "all", name: "all" },
                  { label: "Selected", value: "selected", name: "selected", disabled: noPagesSelected },
                ]} />
              <RadioInput
                label="Regions"
                labelWidth="3.5rem"
                bind:value={regions}
                options={[
                  { label: "Title", value: "title", name: "title" },
                  { label: "Title + Number", value: "title-number", name: "title-number" },
                ]} />
            </div>
            <button class="btn w-full btn-primary-alt" on:click={beginSelectingRegions}>
              Select Page Region
            </button>
          {:else if state === "selecting"}
            <div class="px-2 mb-4">Re-position the boxes.</div>
            <button class="btn w-full btn-primary" on:click={finishSelectingRegions}> Done Selecting </button>
          {:else if state === "working"}
            <div class="px-2">Working...</div>
          {:else if state === "error"}
            <div class="space-y-2 mb-4">
              <div class="px-2">An error occurred:</div>
              <div class="px-4 text-red-500">{errorMessage}</div>
            </div>
            <button class="btn w-full btn-primary" on:click={toggleVisible}> OK </button>
          {:else if state === "confirm"}
            <div class="space-y-2 mb-4">
              <div class="px-2">Confirm that the following pages should be re-labeled:</div>
              <div class="preview-container">
                {#if updates.length}
                  {#each updates as update}
                    <div class="flex">
                      <div class="w-6">
                        {doc.data.pages.indices[update.page_id] + 1}
                      </div>
                      <div>
                        {update.label}
                      </div>
                    </div>
                  {/each}
                {:else}
                  <div class="px-2">No text objects found.</div>
                {/if}
              </div>
            </div>
            <div class="flex gap-2">
              <button class="btn basis-1/2" on:click={finishSelectingRegions}> Update </button>
              <button class="btn basis-1/2 btn-primary-alt" on:click={renamePages} disabled={!updates.length}>
                Rename Pages
              </button>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .preview-container {
    @apply border bg-gray-100 px-6 py-1;
    max-height: 10rem;
    overflow-y: auto;
  }
</style>
