import range from "lodash/range";

export default function validDropIndices(
  selectedIndices,
  currentIndex,
  totalItems,
) {
  const selected = selectedIndices.reduce((result, index) => {
    result[index] = true;
    return result;
  }, {});

  let first = currentIndex;
  for (let i = currentIndex - 1; i >= 0; i--) {
    if (selected[i]) {
      first = i;
    } else {
      break;
    }
  }

  let last = currentIndex;
  for (let i = currentIndex + 1; i < totalItems; i++) {
    if (selected[i]) {
      last = i;
    } else {
      break;
    }
  }

  return range(0, totalItems + 1)
    .filter((index) => index < first || index > last + 1)
    .reduce((result, index) => {
      result[index] = true;
      return result;
    }, {});
}
