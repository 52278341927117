<script>
  import { createEventDispatcher } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import { createCircularHole } from "@local/lamina-core";

  export let border = false;
  export let label = "Label";

  /** @type {string | null} */
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let outerBorder = true;

  const dispatch = createEventDispatcher();

  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";
  $: voids = value?.voids || [];
  $: edge = value?.edge || [];
  $: corner = value?.corner || [];
  $: virtual = value?.virtual || [];
  $: totalDefined = voids.length + edge.length + corner.length + virtual.length;

  function decrement() {
    const newValue = cloneDeep(value);
    newValue.virtual.pop();
    value = newValue;
    dispatch("input", { value: newValue });
  }

  function increment() {
    const newValue = cloneDeep(value) || {};
    if (!newValue.virtual) newValue.virtual = [];
    newValue.virtual.push(createCircularHole());
    value = newValue;
    dispatch("input", { value: newValue });
  }
</script>

<div
  class="prop-container"
  class:has-outer-border={outerBorder}
  class:has-inner-border={!outerBorder}
  class:focused
  class:readonly={disabled}
  class:visible-border={border}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <div class="input flex gap-2 items-center">
    {#if !disabled}
      <button disabled={!virtual.length} on:click={decrement}>-</button>
    {/if}
    <div>
      {totalDefined}
    </div>
    {#if !disabled}
      <button on:click={increment}>+</button>
    {/if}
  </div>
</div>

<style lang="scss">
  .prop-container {
    @apply w-full flex items-center rounded bg-white;

    &.has-outer-border {
      @apply p-2;

      &:hover:not(.readonly):not(.focused),
      &.visible-border {
        @apply ring-1 ring-inset ring-gray-300;
      }

      &.focused {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    .input {
      @apply min-w-0 grow shrink bg-transparent ml-2;

      button {
        @apply px-1 rounded;

        &:hover {
          @apply bg-gray-200;
        }
      }
    }

    &.has-inner-border {
      .input {
        @apply p-1 rounded;
      }
    }

    &.has-inner-border:not(.readonly):not(.focused) {
      .input:hover {
        @apply ring-1 ring-inset ring-gray-300;
      }
    }

    &.has-inner-border.focused {
      .input {
        @apply ring-2 ring-inset ring-blue-500;
      }
    }
  }
</style>
