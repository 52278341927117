<svelte:options strictprops={false} />

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import debounce from "lodash/debounce";
  import uniqBy from "lodash/uniqBy";
  import { Modal } from "svelte-utilities";
  import eb from "#src/extensions/event-bus.js";
  import SearchSelect from "#lib/sidebar/SearchSelect.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";
  import sanitizePgString from "#src/extensions/sanitize-postgrest-string.js";
  import MapboxInput from "#lib/sidebar/MapboxInput.svelte";
  import { jobs, selectedJobIds } from "#src/stores/jobs.js";
  import { api } from "#src/api";
  import { notifications } from "#src/stores/notifications.js";
  import { quotes, selectedQuoteIds } from "#src/stores/quotes.js";

  export let org;
  export let job;
  export let group;

  export const open = () => openModal();

  const dispatch = createEventDispatcher();

  let modal;
  let archive = true;
  let searchString = "";
  let selectedResult = null;
  let customers = [];
  let profiles = [];
  let selectedUser = null;
  let action = null; // "share" or "process"
  let request_for = "quote";
  let buyer_reference = null;

  $: userOptions = [
    ...(selectedResult?.cta ? [{ value: null, label: selectedResult.cta }] : []),
    ...profiles.map((p) => ({ value: p.id, label: `${p.username} (${p.email})` })),
  ];
  $: customerResults = customers.map((customer) => ({
    id: customer.id,
    name: customer.name,
    cta: customer.primary_contact_email,
  }));

  async function openModal() {
    action = null;
    fetchCustomers();
    if (job.prepared_for_org_id) {
      const c = await api
        .from("organizations")
        .select("*,profiles(*)")
        .eq("id", job.prepared_for_org_id)
        .single();
      if (c.data) {
        selectedResult = {
          id: c.data.id,
          name: c.data.name,
          cta: c.data.primary_contact_email,
        };
        profiles = c.data.profiles || [];
      }
    }
    modal.open();
  }

  async function fetchCustomers() {
    const s = sanitizePgString(searchString);

    const c = await api
      .from("business_relation_list_view")
      .select("*,buyer:buyer_id(*,profiles(*))")
      .eq("seller_id", org.id)
      .or(`recipient_name.ilike.%${s}%,recipient_email.ilike.%${s}%`)
      .range(0, 8);

    if (!c.error) {
      customers = uniqBy(c.data, "buyer_id").map((c) => c.buyer);
    }
  }

  const search = debounce(fetchCustomers, 500);

  function select(e) {
    const customer = customers.find((c) => c.id === e.detail.result.id);
    selectedResult = e.detail.result;
    profiles = customer.profiles || [];
  }

  function deselect() {
    selectedResult = null;
    searchString = "";
    profiles = [];
    fetchCustomers();
  }

  async function sendDraft() {
    if (!selectedResult) return;

    try {
      if (selectedUser) {
        await jobs.shareWithOrg(job.id, selectedResult.id, selectedUser);
      } else {
        await jobs.shareWithOrg(job.id, selectedResult.id);
      }
      notifications.add({
        message: `Draft successfully shared with ${selectedResult.name}.`,
        type: "info",
        duration: 3,
      });
    } catch (err) {
      notifications.add({ message: "Failed to share draft", type: "error", duration: 3 });
    }

    if (archive) {
      await jobs.archive(job.id);
    }
  }

  async function createRequest() {
    if (!selectedResult) return;

    try {
      jobs.spin(job.id);
      const result = await jobs.createRfqForOrg(job.id, selectedResult.id, request_for, buyer_reference);
      notifications.add({
        message: `Request successfully created for ${selectedResult.name}.`,
        type: "info",
        duration: 3,
      });

      await quotes.fetchOne(result.id);
      $selectedJobIds = [];
      $selectedQuoteIds = [result.id];
    } catch (err) {
      notifications.add({ message: "Failed to create request", type: "error", duration: 3 });
    }
  }

  async function updateAddress(e) {
    await dispatch("update-job", {
      location: e.detail.value,
      location_coordinates: e.detail.coordinates,
      location_postal_code: e.detail.postal_code,
      location_country_code: e.detail.country_code,
    });
  }

  function openSendModal() {
    modal.close();
    eb.dispatch("open-rfq-modal");
  }

  function process() {
    if (action === "share") {
      sendDraft();
    } else if (action === "process") {
      createRequest();
    }
  }

  onMount(() => {
    eb.on("open-share-draft-modal", openModal);

    return () => eb.unsubscribe("open-share-draft-modal", openModal);
  });
</script>

<Modal
  bind:this={modal}
  width="32rem"
  closeable
  on:confirm={process}
  buttons={action
    ? [
        { label: "Cancel", type: "cancel" },
        {
          label: action === "process" ? "Create Request" : "Send",
          type: "confirm",
          style: "primary",
          disabled: !selectedResult || ($group?.project_type === "product" && !job.location_postal_code),
        },
      ]
    : []}>
  <div slot="title">Process Draft</div>
  <div slot="content" class="space-y-4 text-sm">
    {#if action === "share"}
      <div class="font-bold">{job.name}</div>
      <div>Choose a customer to share with:</div>
      <SearchSelect
        placeholder="Company"
        border
        bind:value={searchString}
        selection={selectedResult}
        results={customerResults}
        on:select={select}
        on:deselect={deselect}
        on:search={search} />
      {#if selectedResult && profiles.length > 0}
        <SelectInput
          label="User"
          border
          bind:value={selectedUser}
          options={userOptions}
          disabled={!selectedResult} />
      {/if}
      <BooleanInput label="Archive after Sharing" border bind:value={archive} />
    {:else if action === "process"}
      <div class="font-bold">{job.name}</div>
      <SearchSelect
        placeholder="Company"
        border
        bind:value={searchString}
        selection={selectedResult}
        results={customerResults}
        on:select={select}
        on:deselect={deselect}
        on:search={search} />
      <SelectInput
        label="Request for"
        border
        bind:value={request_for}
        options={[
          { value: "quote", label: "Quote" },
          { value: "order", label: "Order" },
        ]} />
      {#if $group?.project_type === "product"}
        <TextInput label="Quote Number" border disabled bind:value={$group.seller_reference} />
        <MapboxInput
          label="Location"
          border
          value={job.location}
          coordinates={job.location_coordinates}
          placeholder=""
          on:input={updateAddress} />
      {:else}
        <TextInput label="PO Number" border bind:value={buyer_reference} />
      {/if}
    {:else}
      <div>Choose an action:</div>
      <div class="flex gap-2">
        <button class="action-button" on:click={openSendModal}> Create Quote </button>
        <button class="action-button" on:click={() => (action = "share")}> Share Draft with Customer </button>
        <button class="action-button" on:click={() => (action = "process")}>
          Process RFQ/Order for Customer
        </button>
      </div>
    {/if}
  </div>
</Modal>

<style lang="scss">
  .action-button {
    @apply basis-1/2 p-4 border border-dashed border-gray-300 rounded text-gray-500;

    &:hover {
      @apply bg-gray-100;
    }
  }
</style>
