<script>
  import { onMount, tick, createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import debounce from "lodash/debounce";
  import uniq from "lodash/uniq";
  import { tooltip } from "svelte-utilities";
  import { degToRad } from "vector";
  import { transformPt } from "drawing";

  import Bugsnag from "#src/bugsnag.js";
  import XICon from "@local/assets/icons/x.svg";
  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import CaretRightIcon from "@local/assets/icons/caret-right.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import QuestionAsteriskIcon from "@local/assets/icons/question-asterisk.svg";
  import SelectedIcon from "@local/assets/icons/selected.svg";
  import SpinnerIcon from "@local/assets/icons/spinner.svg";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import eb from "#src/extensions/event-bus.js";
  import {
    useWildcardsInSearch,
    useSelectedInSearch,
    showSearchResults,
    selectedPages,
  } from "#src/stores/ui.js";
  import { user } from "#src/stores/auth.js";
  import {
    escapeSpecialChars,
    escapeWithWildcards,
    escapeRegExp,
  } from "#src/extensions/sanitize-postgrest-string.js";
  import { transformSheetPoint } from "@local/extensions/geometry/transform-sheet-points.js";
  import { createItem, createType } from "@local/lamina-core";
  import { api } from "#src/api/";

  export let docid;
  export let group;
  export let items;
  export let collections;
  export let types;
  export let locations;
  export let visibleIndices;
  export let highlightedText;
  export let searchResults;
  export let importSearchResultsAs;

  const dispatch = createEventDispatcher();

  const recordName = {
    item: "item",
    collection: "opening",
    type: "type",
  };

  let visible = false;
  let input;
  let allResults = [];
  let currentResult = 0;
  let searchText = "";
  let selected = false;
  let searching = false;
  let showAdvanced = false;
  let showImportAs = false;

  $: results = allResults.filter((r) => visibleIndices[r?.page_id] !== undefined);
  $: hiddenResults = allResults.filter((r) => visibleIndices[r?.page_id] === undefined);
  $: filtered = filterResults(results, $useSelectedInSearch, $selectedPages);
  $: checkResult(currentResult, filtered, highlightedText);
  $: current = filtered[currentResult];
  $: resetResults(searchText);
  $: updateSelected($selectedPages, $useSelectedInSearch);

  function resetResults(searchText) {
    searchResults = [];
  }

  function updateSelected(sp, useselected) {
    if (selected && current?.page_id !== highlightedText?.page_id) {
      currentResult = 0;
      if (filtered[currentResult]) {
        select(filtered[currentResult]);
      }
    }
  }

  function filterResults(results, useSelected, sp) {
    if (!useSelected) return results;
    if (Object.keys(sp).length === 0) return results;

    return results.filter((r) => sp[r.page_id]);
  }

  function checkResult(currentResult, results, ht) {
    if (!results[currentResult]) {
      currentResult = 0;

      if (ht) {
        highlightedText = null;
      }
    }
  }

  // Assuming 300 DPI and 72 pts/inch for PDF rasterization
  const sf = 300 / 72;

  function tileSize(w, h, sr) {
    if ([90, 270].includes(sr)) {
      return {
        x: h * sf,
        y: w * sf,
      };
    }

    return {
      x: w * sf,
      y: h * sf,
    };
  }

  function sheetPoint(p, sheetLimits, sheetRotation) {
    const w = sheetLimits[1][0] - sheetLimits[0][0];
    const h = sheetLimits[1][1] - sheetLimits[0][1];
    const ts = tileSize(w, h, sheetRotation);

    const initPt = {
      x: p.x - sheetLimits[0][0],
      y: p.y - sheetLimits[0][1],
    };

    return transformSheetPoint(initPt, sheetRotation, sf, ts);
  }

  async function toggleVisible() {
    visible = !visible;
    await tick();
    if (visible && input) {
      input.focus();
      if (filtered[currentResult]) {
        select(filtered[currentResult]);
      }
      $showSearchResults = true;
    } else {
      highlightedText = null;
      $showSearchResults = false;
    }
  }

  async function fetchResults(text) {
    if (!text) return;
    const t = text;
    const trimmed = text.trim();
    let query = $useWildcardsInSearch ? escapeWithWildcards(trimmed) : escapeSpecialChars(trimmed);

    // Allow use of ^ and $ characters to denote beginning and end of string
    if ($useWildcardsInSearch) {
      if (query.match(/^\^/)) {
        query = query.replace(/^\^/, "");
      } else {
        query = `%${query}`;
      }

      if (query.match(/\$$/)) {
        query = query.replace(/\$$/, "");
      } else {
        query = `${query}%`;
      }
    } else {
      query = `%${query}%`;
    }

    if (!query) return;
    const res = await api.rpc("search_dng_text_objects_ilike", { document_id: docid, query });

    // const words = sanitized.trim().split(/\s+/);
    // if (!words.length) return;
    // const last = words[words.length - 1];
    // words[words.length - 1] = last + ":*";
    // const query = words.join(" & ");

    // const res = await api.rpc("search_dng_text_objects", { document_id: docid, query });

    if (res.error) {
      const err = new Error(`Error searching for text in document ${docid}: ${query}`, {
        cause: res.error,
      });
      Bugsnag.notify(err);
    } else if (t === searchText) {
      allResults = res.data;
    }
    searching = false;
  }

  const debouncedFetch = debounce(async (text) => {
    await fetchResults(text);
  }, 500);

  async function search(text, immediate = false) {
    searching = true;
    allResults = [];

    if (immediate) {
      await fetchResults(text);
    } else {
      debouncedFetch(text);
    }
  }

  function select(result) {
    const { insertion_point, sheet_rotation, sheet_limits, width, height, angle, page_id } = result;
    const pt = sheetPoint(insertion_point, sheet_limits, sheet_rotation);
    const a = degToRad(sheet_rotation - angle);

    highlightedText = {
      page_id,
      angle: a,
      width: width * sf,
      height: height * sf,
      ...pt,
    };
  }

  async function chooseSearchText(text) {
    debouncedFetch.cancel();
    await search(text, true);
    selected = true;
    currentResult = 0;

    await tick();
    if (current) {
      select(current);
      moveToPoint(current);
    }
  }

  function handleInput(e) {
    selected = false;
    search(e.target.value);
  }

  function rotatePoint(pt, rotation = 0) {
    const rot = degToRad(rotation % 360);
    const cos = Math.cos(rot);
    const sin = Math.sin(rot);
    return transformPt(pt, [cos, sin, -sin, cos, 0, 0]);
  }

  function resultCenterPoint(result) {
    const { insertion_point, sheet_limits, angle, sheet_rotation, width, height } = result;

    const pos = sheetPoint(insertion_point, sheet_limits, sheet_rotation);
    const ctr = { x: (width / 2) * sf, y: -(height / 2) * sf };
    const rotated = rotatePoint(ctr, sheet_rotation - angle);
    const point = {
      x: pos.x + rotated.x,
      y: pos.y + rotated.y,
    };

    return point;
  }

  function moveToPoint(current) {
    const point = resultCenterPoint(current);
    dispatch("move-to-location", { page_id: current.page_id, point });
  }

  async function keydown(e) {
    if (e.key === "Enter") {
      debouncedFetch.cancel();
      await search(e.target.value, true);
      selected = true;
      currentResult = 0;

      await tick();
      if (current) {
        select(current);
        moveToPoint(current);
      }
    } else if (e.key === "Escape") {
      toggleVisible();
    }
  }

  async function prevResult() {
    if (currentResult > 0) {
      currentResult--;
    } else {
      currentResult = filtered.length - 1;
    }

    await tick();
    if (current) {
      select(current);
      moveToPoint(current);
    }
  }

  async function nextResult() {
    if (currentResult < filtered.length - 1) {
      currentResult++;
    } else {
      currentResult = 0;
    }

    await tick();
    if (current) {
      select(current);
      moveToPoint(current);
    }
  }

  function previewResults() {
    const rawst = searchText.trim();
    const escaped = escapeRegExp(rawst);
    const replaced = $useWildcardsInSearch
      ? escaped.replace(/\\\?/g, ".").replace(/\\\*/g, ".*").replace(/^\\\^/, "").replace(/\\\$$/, "")
      : escaped;

    const re = new RegExp(replaced, "i");

    searchResults = filtered.map((result) => {
      const center = resultCenterPoint(result);
      const id = crypto.randomUUID();
      const match = result.content.match(re);
      const label = match?.[0] ?? "";

      return {
        id,
        label,
        center,
        page_id: result.page_id,
      };
    });
  }

  function importResults() {
    if (!searchResults.length) return;

    let updates = [];

    let e;
    if (importSearchResultsAs === "type") {
      e = types;
    } else if (importSearchResultsAs === "item") {
      e = items;
    } else {
      e = collections;
    }

    const existingMarks = e.reduce((m, t) => {
      m[t.mark] = t.id;
      return m;
    }, {});

    const uniqueMarks = uniq(searchResults.map((r) => r.label));
    let recordIds = uniqueMarks.map((_) => crypto.randomUUID());
    const markMap = uniqueMarks.reduce((m, mark, i) => {
      if (existingMarks[mark]) {
        m[mark] = existingMarks[mark];
      } else {
        m[mark] = recordIds[i];
      }
      return m;
    }, {});
    const newMarks = uniqueMarks.filter((m) => !existingMarks[m]);

    const idMap = searchResults.reduce((m, r) => {
      m[r.id] = markMap[r.label];
      return m;
    }, {});

    // Create types/items
    if (importSearchResultsAs === "type") {
      const newTypes = newMarks.map((mark, i) => {
        const type = createType($group.id);
        type.mark = mark;
        type.id = recordIds[i];
        return type;
      });

      updates.push({ type: "type", action: "add", records: newTypes });
    } else {
      const is_collection = importSearchResultsAs === "collection";
      const newItems = newMarks.map((mark, i) => {
        const item = createItem($group.id, $user?.id, [], [], { is_collection });
        item.mark = mark;
        item.id = recordIds[i];
        return item;
      });

      updates.push({ type: "item", action: "add", records: newItems });
    }

    const locs = searchResults.map((r, i) => {
      return {
        id: r.id,
        group_id: $group.id,
        document_id: docid,
        index: locations.length + i,
        type: "pin",
        position: r.center,
        page_id: r.page_id,
        record_type: importSearchResultsAs,
        record_id: idMap[r.id],
      };
    });

    updates.push({ type: "location", action: "add", records: locs });
    group.update(updates);
    searchResults = [];
  }

  function setImportAs(value) {
    importSearchResultsAs = value;
    showImportAs = false;
  }

  onMount(() => {
    eb.on("search", toggleVisible);

    return () => {
      eb.unsubscribe("search", toggleVisible);
    };
  });
</script>

{#if visible}
  <div class="w-full flex justify-end p-4 absolute z-30 pointer-events-none text-xs">
    <div class="w-72 pointer-events-auto">
      <div class="bg-white p-2 rounded border shadow-lg w-full">
        <div class="flex items-center gap-0.5">
          <div class="grow flex rounded border">
            <input
              size="1"
              bind:this={input}
              bind:value={searchText}
              on:input={handleInput}
              on:keydown={keydown} />
          </div>
          <button
            use:tooltip={{ text: "Use wildcards" }}
            class="rounded p-1 hover:bg-gray-200"
            class:text-gray-400={!$useWildcardsInSearch}
            class:bg-transparent={!$useWildcardsInSearch}
            class:bg-gray-200={$useWildcardsInSearch}
            on:click={() => ($useWildcardsInSearch = !$useWildcardsInSearch)}>
            <QuestionAsteriskIcon />
          </button>
          <button
            use:tooltip={{ text: "Search Selected Pages" }}
            class="rounded p-1 hover:bg-gray-200"
            class:text-gray-400={!$useSelectedInSearch}
            class:bg-transparent={!$useSelectedInSearch}
            class:bg-gray-200={$useSelectedInSearch}
            on:click={() => ($useSelectedInSearch = !$useSelectedInSearch)}>
            <SelectedIcon />
          </button>
          <button class="rounded p-1 hover:bg-gray-200" on:click={() => (showAdvanced = !showAdvanced)}>
            <EllipsisIcon />
          </button>
          <div class="border-l border-gray-200">&#8288;</div>
          <button on:click={toggleVisible} class="rounded p-1 hover:bg-gray-200">
            <XICon />
          </button>
        </div>
        {#if showAdvanced}
          <div class="text-xs flex justify-between items-center mt-2">
            {#if filtered.length && searchResults.length === filtered.length}
              <button class="btn-text" on:click={() => (searchResults = [])}>Hide Locations</button>
            {:else}
              <button class="btn-text" on:click={previewResults} disabled={!filtered.length}
                >Show Locations</button>
            {/if}
            <div class="flex gap-1">
              <button class="btn-text" on:click={importResults}>
                Import as {upperFirst(recordName[importSearchResultsAs])}
              </button>
              <button
                on:click={() => (showImportAs = !showImportAs)}
                class="rounded p-1 hover:bg-gray-200 relative">
                <CaretDownIcon />
                {#if showImportAs}
                  <div class="dropdown-container text-right right-0">
                    <button class="dropdown-button-item" on:click|stopPropagation={() => setImportAs("item")}
                      >Item</button>
                    <button
                      class="dropdown-button-item"
                      on:click|stopPropagation={() => setImportAs("collection")}>Opening</button>
                    <button class="dropdown-button-item" on:click|stopPropagation={() => setImportAs("type")}
                      >Type</button>
                  </div>
                {/if}
              </button>
            </div>
          </div>
        {/if}
      </div>
      {#if !selected}
        <div class="bg-white p-2 rounded border shadow-lg w-full">
          <div class="flex justify-between">
            {#if !searchText}
              <span class="italic">Type to search...</span>
            {:else}
              <button class="font-bold" on:click={() => chooseSearchText(searchText)}>
                {searchText}
              </button>
              <div class="relative">
                {#if searching}
                  <div class="animate-spin w-4 h-4">
                    <SpinnerIcon />
                  </div>
                {:else}
                  {filtered.length}
                {/if}
              </div>
            {/if}
          </div>
          {#if searchText && hiddenResults.length && !$useSelectedInSearch}
            <div class="flex items-center justify-end text-gray-500 italic">
              <div>
                {hiddenResults.length} hidden
              </div>
            </div>
          {/if}
        </div>
      {:else if filtered.length && current}
        <div class="bg-white p-2 rounded border shadow-lg w-full flex items-center justify-between">
          <div>
            {currentResult + 1} / {filtered.length}
          </div>
          <div>
            Page {visibleIndices[current.page_id] + 1}
          </div>
          <div class="flex gap-1">
            <button on:click={prevResult} class="rounded p-1 hover:bg-gray-200">
              <CaretLeftIcon />
            </button>
            <button on:click={nextResult} class="rounded p-1 hover:bg-gray-200">
              <CaretRightIcon />
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style lang="scss">
  input {
    @apply grow p-1 bg-transparent;
    // outline: none;
    min-width: 0;
    max-width: 100%;
  }
</style>
