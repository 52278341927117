<script>
  import { getContext } from "svelte";
  import { navigate } from "svelte-routing";
  import get from "lodash/get";
  import set from "lodash/set";
  import unset from "lodash/unset";
  import cloneDeep from "lodash/cloneDeep";

  import TextInput from "#lib/sidebar/TextInput.svelte";
  import MapboxInput from "#lib/sidebar/MapboxInput.svelte";
  import GuardedTextInput from "#lib/sidebar/GuardedTextInput.svelte";
  import SelectInput from "#lib/sidebar/SelectInput.svelte";
  import BooleanInput from "#lib/sidebar/BooleanInput.svelte";
  import ColorInput from "#lib/sidebar/ColorInput.svelte";
  import TextareaInput from "#lib/sidebar/TextareaInput.svelte";
  import ImageUploader from "#lib/ImageUploader.svelte";
  import JSONInput from "#lib/sidebar/JSONInput.svelte";

  import { profile } from "#src/stores/auth.js";
  import { SUPABASE_URL as supabaseUrl } from "#src/env";
  import { api } from "#src/api";

  export let org;
  export let manageable = false;

  const productLists = getContext("productLists");

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);

  function uploadLogo(e) {
    if (e.detail.type && e.detail.base64) {
      const data = cloneDeep($org.data);
      set(data, "logo", e.detail);
      org.updateProp("data", data);
    }
  }

  function deleteLogo() {
    const data = cloneDeep($org.data);
    unset(data, "logo");
    org.updateProp("data", data);
  }

  function bannerSrc(banner) {
    if (!banner) return null;
    if (banner.base64) return banner.base64;
    if (banner.object_id) {
      return `${supabaseUrl}/storage/v1/render/image/public/images/${banner.object_id}?width=200&height=200&resize=contain`;
    }
  }

  async function uploadBanner(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        const data = cloneDeep($org.data);
        set(data, "banner", {
          id: uuid,
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        });
        org.updateProp("data", data);
      }
    }
  }
  function updateOrgData(prop, value) {
    const data = cloneDeep($org.data);
    set(data, prop, value);
    org.updateProp("data", data);
  }

  function updateOrgSetting(prop, value) {
    const data = cloneDeep($org.default_group_settings);
    set(data, prop, value);
    org.updateProp("default_group_settings", data);
  }

  function deleteBanner() {
    const data = cloneDeep($org.data);
    unset(data, "banner");
    org.updateProp("data", data);
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>{$org.name}</h2>
</div>

<div class="py-4 sm:px-4 space-y-2 text-sm">
  <div class="w-full sm:w-96 space-y-4">
    <TextInput
      labelWidth="10rem"
      border
      label="Name"
      value={$org.name}
      disabled={!canAdmin}
      on:input={(e) => org.updateProp("name", e.detail.value)} />
    <TextInput
      labelWidth="10rem"
      border
      label="Primary Email"
      value={$org.primary_contact_email}
      disabled={!canAdmin}
      on:input={(e) => org.updateProp("primary_contact_email", e.detail.value.trim())} />
    <MapboxInput
      labelWidth="10rem"
      border
      label="Address"
      value={$org.address}
      coordinates={$org.address_coordinates}
      disabled={!canAdmin}
      on:input={(e) =>
        org.updateProp({
          address: e.detail.value,
          address_coordinates: e.detail.coordinates,
          address_postal_code: e.detail.postal_code,
          address_country_code: e.detail.country_code,
        })} />
    <!-- <TextareaInput
      fullwidth
      placeholder="Address"
      value={$org.address}
      disabled={!canAdmin}
      on:input={(e) => org.updateProp("address", e.detail.value)}
      rows={4} /> -->
    {#if $profile?.user_role === "developer"}
      <SelectInput
        labelWidth="10rem"
        border
        label="Org Type"
        value={$org.org_type}
        disabled={!canAdmin}
        options={[
          { label: "Anonymous", value: "anonymous" },
          { label: "Guest", value: "guest" },
          { label: "Recipient", value: "recipient" },
          { label: "Supplier", value: "supplier" },
          { label: "Product Rep", value: "product_rep" },
        ]}
        on:input={(e) => org.updateProp("org_type", e.detail.value)} />
      {#if $org.org_type === "recipient"}
        <SelectInput
          labelWidth="10rem"
          border
          label="Account Type"
          value={$org.account_type}
          disabled={!canAdmin}
          options={[
            { label: "Free", value: "free" },
            { label: "Subscribed", value: "subscribed" },
          ]}
          on:input={(e) => org.updateProp("account_type", e.detail.value)} />
      {/if}
      <BooleanInput
        labelWidth="10rem"
        border
        label="Is Demo"
        value={$org.is_demo}
        disabled={!canAdmin}
        on:input={(e) => org.updateProp("is_demo", e.detail.value)} />
      <BooleanInput
        labelWidth="10rem"
        border
        label="Public Profile"
        value={$org.is_public}
        disabled={!canAdmin}
        on:input={(e) => org.updateProp("is_public", e.detail.value)} />
      {#if $org.is_public && $profile?.user_role === "developer"}
        <GuardedTextInput
          labelWidth="10rem"
          border
          label="Portal Slug"
          value={$org.slug}
          disabled={$profile?.user_role !== "developer"}
          on:input={(e) => org.updateProp("slug", (e.detail.value || "").toLowerCase())} />
      {/if}
      {#if $org.is_public && $org.org_type === "supplier"}
        <BooleanInput
          labelWidth="10rem"
          border
          label="Accepts Customers"
          value={$org.accepts_public_membership_requests}
          disabled={!canAdmin}
          on:input={(e) => org.updateProp("accepts_public_membership_requests", e.detail.value)} />
        <SelectInput
          labelWidth="10rem"
          border
          label="Default Products"
          value={$org.default_products}
          disabled={!canAdmin}
          options={[
            { label: "All Published Products", value: "all" },
            { label: "Generic Products Only", value: "none" },
            { label: "Named Product List", value: "product_list" },
          ]}
          on:input={(e) => org.updateProp("default_products", e.detail.value)} />
        {#if $org.default_products === "product_list"}
          <SelectInput
            labelWidth="10rem"
            border
            label="Product List"
            value={$org.default_product_list_id}
            disabled={!canAdmin}
            options={$productLists?.map((l) => ({ label: l.name, value: l.id }))}
            on:input={(e) => org.updateProp("default_product_list_id", e.detail.value)} />
        {/if}
      {/if}
      <JSONInput
        labelWidth="10rem"
        border
        label="Item Templates"
        value={$org.data.templates}
        disabled={!canAdmin}
        on:input={(e) => updateOrgData("templates", e.detail.value)} />
      <!-- <BooleanInput
        labelWidth="10rem"
        border
        label="Hide Magic Links"
        value={$org.hide_magic_links_in_emails}
        disabled={!canAdmin}
        on:input={(e) => org.updateProp("hide_magic_links_in_emails", e.detail.value)} /> -->
    {/if}
    {#if $org.is_public && $org.org_type === "supplier"}
      <TextareaInput
        fullwidth
        placeholder="Portal Description"
        value={$org.description}
        disabled={!canAdmin}
        on:input={(e) => org.updateProp("description", e.detail.value)}
        rows={4} />
    {/if}
    <SelectInput
      border
      label="Default Type View"
      labelWidth="10rem"
      options={[
        { label: "Show Type Details", value: -1 },
        { label: "Show Type Details and Items", value: 1 },
        { label: "Show Type Name Only", value: 0 },
      ]}
      value={$org.default_group_settings.summary_default_type_view}
      on:input={(e) => updateOrgSetting("summary_default_type_view", e.detail.value)} />
    <BooleanInput
      border
      label="Show Untyped Items"
      labelWidth="10rem"
      value={$org.default_group_settings.summary_show_untyped_items}
      on:input={(e) => updateOrgSetting("summary_show_untyped_items", e.detail.value)} />
    <ColorInput
      border
      label="Accent Color"
      labelWidth="10rem"
      value={$org.accent_color}
      disabled={!canAdmin}
      on:input={(e) => org.updateProp("accent_color", e.detail.value)} />
    <ImageUploader
      label="Company Logo"
      value={get($org.data, "logo.base64")}
      accept="image/png, image/jpeg"
      disabled={!canAdmin}
      on:upload={uploadLogo}
      on:delete={deleteLogo} />
    {#if $org.is_public && $org.org_type === "supplier"}
      <ImageUploader
        label="Banner Image"
        value={bannerSrc($org.data?.banner)}
        accept="image/png, image/jpeg"
        uploadType="arraybuffer"
        disabled={!canAdmin}
        on:upload={uploadBanner}
        on:delete={deleteBanner} />
    {/if}

    {#if manageable && $org.org_type === "supplier"}
      <div class="py-2">
        <button class="btn btn-primary w-full" on:click={() => navigate("/products")}>Manage Products</button>
      </div>
    {/if}
  </div>
</div>
