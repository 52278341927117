function translateRectangle(rect, delta) {
  return {
    x: rect.x + delta.x,
    y: rect.y + delta.y,
    width: rect.width,
    height: rect.height,
  };
}

export default translateRectangle;
