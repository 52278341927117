import cloneDeep from "lodash/cloneDeep";
import { objectify } from "overline";
import { checkSuprastoreStatus, api } from "#src/api";

function getFiles(document) {
  if (!document.data?.pages) return [];
  const present = document.data.pages.order.reduce((files, pageIndex) => {
    const page = document.data.pages[pageIndex];
    files[page.name] = true;
    return files;
  }, {});
  return Object.keys(present);
}

async function getFileData(file) {
  const docfile = await api
    .schema("storage")
    .from("objects")
    .select("*")
    .eq("bucket_id", "documents")
    .eq("name", file)
    .single();

  if (docfile.error) return null;

  const sheetData = await api
    .from("dng_sheets")
    .select("*,limits::jsonb")
    .eq("document_id", docfile.data.id)
    .order("idx");

  const sheets = objectify(sheetData.data, "idx");

  let status;
  if (docfile.data.metadata?.mimetype === "application/pdf") {
    const rasterStatus = await checkSuprastoreStatus(
      "documents",
      encodeURIComponent(file),
    );
    if (rasterStatus.error) return null;
    status = rasterStatus.data?.status || {};
  } else if (docfile.data.metadata?.mimetype.startsWith("image")) {
    status = { 0: { state: "completed" } };
  }

  return { name: file, file: docfile.data, status, sheets };
}

function getProgress(document, status) {
  const count = document.data?.pages?.order.length;
  if (!count) return 1;

  const completed = document.data.pages.order.reduce((c, pageId) => {
    const page = document.data.pages[pageId];
    if (status[page.name]?.status[page.page]?.state === "completed") {
      return c + 1;
    }
    return c;
  }, 0);

  return completed / count;
}

export { getFiles, getFileData, getProgress };
