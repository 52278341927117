<script>
  import { createEventDispatcher } from "svelte";

  export let border = false;
  export let options;
  export let label = "Label";
  /** @type {string | null} */
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;

  let focused;

  const dispatch = createEventDispatcher();

  $: lw = labelWidth ? `width:${labelWidth};` : "";

  function update(e) {
    if (disabled || readonly) return;
    if (value !== e.target.value) {
      value = e.target.value;
      dispatch("input", { value: e.target.value });
    }
  }
</script>

<div class="prop-container" class:readonly class:focused class:visible-border={border}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  <div class="flex items-center gap-4 ml-2">
    {#each options as option}
      <div class="flex items-center gap-1">
        <input
          type="radio"
          checked={value === option.value}
          name={option.name}
          value={option.value}
          on:input={update}
          disabled={disabled || option.disabled} />
        <div class:text-gray-500={disabled || option.disabled}>{option.label}</div>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded;

    &:hover:not(.readonly),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .label {
      @apply truncate text-gray-400 flex-none;
    }
  }
</style>
