import type { ItemRecordCore } from "../entities/item.js";

interface PricingGroupProperties {
  area: number,
  weight: number,
  quantity: number,
}

interface JobProperties {
  _ungrouped: PricingGroupProperties,
  [key: string]: PricingGroupProperties,
}

function itemFreightProperties(item: ItemRecordCore, pricingGroup: string, freightGroup: string, itemPrices: Record<string, Number>) {
  const width = item.cache?.width_in ?? 0;
  const height = item.cache?.height_in ?? 0;
  const weight = item.cache?.weight ?? 0;
  const price = Math.round((itemPrices[item.id] ?? 0) * 100);

  return { id: item.id, width, height, weight, price, pricing_group: pricingGroup, freight_group: freightGroup };
}

export { itemFreightProperties };
