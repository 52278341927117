<script>
  import { createEventDispatcher, getContext } from "svelte";
  import ExitIcon from "@local/assets/icons/exit.svg";
  import TrashIcon from "@local/assets/icons/trash.svg";
  import XIcon from "@local/assets/icons/x.svg";
  import DuplicateIcon from "@local/assets/icons/duplicate.svg";
  import DuplicateSendIcon from "@local/assets/icons/duplicate-send.svg";
  import ArchiveIcon from "@local/assets/icons/archive.svg";
  import { profile } from "#src/stores/auth.js";

  export let datum;

  const productUser = getContext("productUser");
  const dispatch = createEventDispatcher();
</script>

{#if !$productUser}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "gotorow" })}>
    <ExitIcon />
    Go To
  </button>
{/if}
<button class="dropdown-button-item" on:click={() => dispatch("click", { value: "clonerow" })}>
  <DuplicateIcon />
  Clone
</button>
{#if $profile?.user_role === "developer"}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "sendrow" })}>
    <DuplicateSendIcon />
    Clone to Org
  </button>
{/if}
<button class="dropdown-button-item" on:click={() => dispatch("click", { value: "archiverow" })}>
  <ArchiveIcon />
  Archive
</button>
{#if !datum.sent_at}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "deleterow" })}>
    <TrashIcon />
    Delete
  </button>
  <!-- {:else if !(datum.status === "canceled")}
  <button class="dropdown-button-item" on:click={() => dispatch("click", { value: "cancelrow" })}>
    <XIcon />
    Cancel RFQ
  </button> -->
{/if}
