<script>
  import { onDestroy, getContext } from "svelte";
  import { loadImage } from "isomorphs/canvas";
  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import ProgressBar from "#lib/GenericProgressBar.svelte";
  import { api } from "#src/api";
  import { getFiles, getFileData, getProgress } from "#src/extensions/check-document-status.js";
  import { TRIOPS_URL as triopsUrl } from "#src/env";

  export let document;

  const fileData = getContext("fileData");

  let timeout;
  let thumbnailKey = crypto.randomUUID();

  $: files = getFiles(document);
  $: progress = getProgress(document, $fileData);
  $: checkStatus(document, $fileData);

  async function checkStatus(document, fd) {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (progress === 1) return;

    const incomplete = document.data.pages.order.reduce((incomplete, pageId) => {
      const page = document.data.pages[pageId];
      if (fd[page.name]?.status[page.page]?.state !== "completed") {
        incomplete[page.name] = true;
      }
      return incomplete;
    }, {});

    const check = async () => {
      const s = await Promise.all(Object.keys(incomplete).map((f) => getFileData(f)));
      s.forEach((st) => {
        if (st?.file?.name) {
          if (st.file.name === document.data.thumbnail?.objectName) {
            if (
              st.status[0]?.state === "completed" &&
              $fileData[st.file.name]?.status[0]?.state !== "completed"
            ) {
              thumbnailKey = crypto.randomUUID();
            }
          }
          $fileData[st.file.name] = st;
        }
      });
    };

    timeout = setTimeout(check, 2000);
  }

  async function thumbnailSrc() {
    const thumbnail = document.data.thumbnail;
    if (thumbnail) {
      if (thumbnail.type === "image") {
        const { data, error } = await api.storage
          .from(thumbnail.bucket)
          .createSignedUrl(thumbnail.path, 60 * 60);

        if (error) return null;
        return data.signedUrl;
      } else {
        const path = encodeURIComponent(thumbnail.path);
        const src = `${triopsUrl}/iiif/2/${thumbnail.bucket}/${path}/full/540,/0/default.jpg`;
        try {
          await loadImage(src);
          return src;
        } catch (e) {
          return null;
        }
      }
    }
  }

  async function fetchData() {
    const s = await Promise.all(files.map((f) => getFileData(f)));
    files.forEach((file, index) => {
      $fileData[file] = s[index];
    });
  }

  onDestroy(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
  });
</script>

<div class="relative w-full h-full">
  {#await fetchData() then _}
    <div class="w-full h-full flex items-center justify-center bg-white" style="padding:4px;">
      {#if document.data.thumbnail}
        {#key thumbnailKey}
          {#await thumbnailSrc() then src}
            {#if src}
              <img class="w-full h-full object-cover text-xs" {src} alt={document.name} />
            {/if}
          {/await}
        {/key}
      {:else}
        <div class="w-full h-full flex flex-col justify-center items-center">
          <div class="animate-spin">
            <SpinnerIcon />
          </div>
        </div>
      {/if}
    </div>
    {#if progress < 0.99999}
      <div class="absolute bottom-0 left-0 w-full">
        <div class="p-2 text-xs text-gray-500">Processing: {Math.round(progress * 100)}%</div>
        <ProgressBar value={progress} max={1} />
      </div>
    {/if}
  {/await}
</div>
