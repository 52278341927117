<!-- <svelte:options strictprops={false} /> -->

<script>
  import { onMount, tick, setContext, getContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import { Router, Route, navigate, useLocation } from "svelte-routing";
  import Mousetrap from "mousetrap";
  import isEmpty from "lodash/isEmpty";
  import cloneDeep from "lodash/cloneDeep";

  /* Core */
  import {
    makeColumns,
    makeCollectionColumns,
    createCircularHole,
    createEdgeFabrication,
    createCornerFabrication,
  } from "@local/lamina-core";

  /* Extensions */
  import { typeColorTrue, typeColor } from "@local/extensions/drawing/type-color.js";

  /* Route components */
  import Redirect from "./Redirect.svelte";
  import ItemEditor from "./ItemEditor.svelte";
  import ItemList from "./ItemList.svelte";
  import CollectionList from "./CollectionList.svelte";
  import TypeEditor from "./TypeEditor.svelte";
  import TypeList from "./TypeList.svelte";
  import FabricationEditor from "./FabricationEditor.svelte";
  import FabricationList from "./FabricationList.svelte";
  import DocumentList from "./DocumentList.svelte";
  import Document from "./Document.svelte";

  /* Components */
  import Navbar from "#lib/Navbar.svelte";
  import NavbarUnauthenticated from "#lib/NavbarUnauthenticated.svelte";
  import GroupBanner from "#lib/GroupBanner.svelte";
  import Toolbar from "#lib/toolbar/Toolbar.svelte";
  import ImportDataModal from "#lib/data-importer/ImportDataModal.svelte";
  import SettingsModal from "#lib/settings/SettingsModal.svelte";
  // import JobDocument from "#lib/JobDocument2.svelte";

  /* Stores and event bus */
  import { user, profile, share } from "#src/stores/auth.js";
  import { createGroup, fetchGroup, fetchGroupFromLink } from "#src/stores/group.js";
  import { createOrg } from "#src/stores/org.js";
  import {
    currentTool,
    tempFeatureHole,
    tempFeatureEdgeFabrication,
    tempFeatureCornerFabrication,
    navHistory,
    pathRoot,
    currentSupplierid,
  } from "#src/stores/ui.js";
  import {
    orderedList,
    sortableList,
    addToSortableList,
  } from "@local/extensions/collections/sortable-list.js";
  import eb from "#src/extensions/event-bus.js";
  import { api } from "#src/api";
  import { orgLibrary } from "#src/extensions/makeup-library.js";
  import { orgFabrications } from "#src/extensions/org-fabrications.js";

  export let jobid = null;
  export let linkid = null;
  export let groupid = null;
  export let pathtype;
  export let path = undefined;
  export let queryParams;

  let initialized = false;
  let columns = [];
  let colColumns = [];
  let groupData;
  let link;

  const org = getContext("org");
  const suppliers = getContext("suppliers");
  const supplier = getContext("supplier");
  const groupOrg = createOrg();

  const location = useLocation();
  const typeColors = writable({});
  const typeColorsTrue = writable({});
  const makeupLibrary = derived(supplier, ($supplier) => orgLibrary($supplier, groupData?.project_type));

  setContext("groupOrg", groupOrg);
  setContext("makeupLibrary", makeupLibrary);
  setContext("typeColors", typeColors);
  setContext("typeColorsTrue", typeColorsTrue);

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  $: group = groupData && createGroup(groupData, { realtime: true });
  $: $group && groupOrg.init($group.organization);
  $: loadSuppliers($groupOrg);
  $: job = groupData && groupData.job;

  $: items = transform($group?.items, orderedList).filter((i) => !i.is_collection);
  $: collections = transform($group?.items, orderedList).filter((i) => i.is_collection);
  $: types = transform($group?.types, orderedList);
  $: $typeColors = types.reduce((a, t) => {
    a[t.id] = typeColor(t);
    return a;
  }, {});
  $: $typeColorsTrue = types.reduce((a, t) => {
    a[t.id] = typeColorTrue(t);
    return a;
  }, {});

  $: promoteDeletedOrgCols($org);
  $: standardColumns = $org?.data?.standard_columns || {}; //?
  $: orgColumns = transform($org?.data?.custom_columns, orderedList);
  $: orgColColumns = transform($org?.data?.custom_collection_columns, orderedList);
  $: jobColumns = transform($group?.data?.custom_columns, orderedList);
  $: jobColColumns = transform($group?.data?.custom_collection_columns, orderedList);
  $: customColumns = [...orgColumns, ...jobColumns];
  $: customColColumns = [...orgColColumns, ...jobColColumns];
  $: if ($group && initialized) {
    columns = makeColumns($group, types, disabled, orgColumns, jobColumns, standardColumns);
    colColumns = makeCollectionColumns(
      $group,
      types,
      disabled,
      orgColColumns,
      jobColColumns,
      standardColumns,
    );
  }

  $: tab = (path && path.split("/")[0]) || "items";
  $: disabled =
    ($share && $share.role === "viewer") ||
    (pathtype === "version" && job?.current_group_id !== groupid) ||
    pathtype === "link";
  $: jobData = { job, group, types, items };
  $: colData = { columns, colColumns, standardColumns, customColumns, customColColumns };
  $: mark = getMark(path, $group);
  $: checkEdgeFabrications($group?.data?.fabrications, $tempFeatureEdgeFabrication);
  $: checkCornerFabrications($group?.data?.fabrications, $tempFeatureCornerFabrication);
  $: updateNavHistory($location);

  function getMark(path, g) {
    if (path) {
      const segments = path.split("/");
      if (segments.length > 1) {
        if (segments[0] === "items" && g?.items[segments[1]]) {
          return g.items[segments[1]].mark;
        }

        if (segments[0] === "types" && g?.types[segments[1]]) {
          return g.types[segments[1]].mark;
        }

        if (segments[0] === "fabrications" && g?.data?.fabrications?.[segments[1]]) {
          return g.data.fabrications[segments[1]].mark;
        }
      }

      return null;
    }
    return null;
  }

  // Check whether org columns have been deleted. If so,
  // create a new job-level column to show data.
  async function promoteDeletedOrgCols($org) {
    if (!$org || !$group) return;
    const orgCols = $org.data.custom_columns || {};
    const deletedCols = $org.data.deleted_columns || {};
    const jobCols = $group.data.custom_columns || {};

    const newJobCols = {};

    items.forEach((item) => {
      const cols = Object.keys(item.custom_column_data);

      cols.forEach((id) => {
        if (!newJobCols[id] && !orgCols[id] && !jobCols[id]) {
          if (deletedCols[id]) {
            newJobCols[id] = deletedCols[id];
          }
        }
      });
    });

    if (!isEmpty(newJobCols)) {
      const data = cloneDeep($group.data);
      const cc = data.custom_columns || sortableList([]);

      addToSortableList(cc, ...Object.values(newJobCols));
      data.custom_columns = cc;
      await group.updateProp("data", data);
    }
  }

  function checkEdgeFabrications(fabs, tfef) {
    if (!fabs || !tfef) return;
    if (tfef.fab_id && !fabs[tfef.fab_id]) {
      $tempFeatureEdgeFabrication = createEdgeFabrication(fabs);
    }
  }

  function checkCornerFabrications(fabs, tfcf) {
    if (!fabs || !tfcf) return;
    if (tfcf.fab_id && !fabs[tfcf.fab_id]) {
      $tempFeatureCornerFabrication = createCornerFabrication(fabs);
    }
  }

  async function init() {
    try {
      if (pathtype === "link") {
        const { group: gd, share: sd } = await fetchGroupFromLink(linkid);
        groupData = gd;
        link = sd;
      } else if (pathtype === "current") {
        const { data, error } = await api.from("jobs").select("current_group_id").eq("id", jobid).single();
        if (error) throw error;

        groupData = await fetchGroup(data.current_group_id);
      } else if (pathtype === "version") {
        groupData = await fetchGroup(groupid);
      }
    } catch (error) {
      return navigate("/not-found");
    }

    await tick();

    if (!$group) {
      return navigate("/not-found");
    }

    if (pathtype === "current") {
      $pathRoot = `/jobs/${$group.job_id}/current/`;
    } else if (pathtype === "link") {
      $pathRoot = `/link/${linkid}/`;
    } else {
      $pathRoot = `/versions/${$group.id}/`;
    }

    if ($profile && $profile.organization_id !== $group.organization_id) {
      // await share.fetch($profile.id, $group.id);
    } else {
      share.set(null);
    }

    // Set ui defaults
    $tempFeatureHole = createCircularHole();
    $tempFeatureEdgeFabrication = createEdgeFabrication($group.data.fabrications);
    $tempFeatureCornerFabrication = createCornerFabrication($group.data.fabrications);

    // Are we opening an empty job?
    if (!$group.items.order.length && tab === "items" && $group.project_type !== "product") {
      openImport("new-job-entry");
    }

    initialized = true;
  }

  async function openImport(p) {
    await tick();
    await tick();
    eb.dispatch("import-csv", p);
  }

  function updateJob() {
    document.fonts.load('10pt "Menlo"').then(() => {
      group && group.react();
    });
  }

  function undo(evt) {
    evt.stopPropagation();
    group.undo();
  }

  function redo(evt) {
    evt.stopPropagation();
    group.redo();
  }

  function updateNavHistory(location) {
    if (tab === "items") {
      $navHistory.items = path?.split("/")[1];
    } else if (tab === "openings") {
      $navHistory.collections = path?.split("/")[1];
    } else if (tab === "types") {
      $navHistory.types = path?.split("/")[1];
    } else if (tab === "fabrications") {
      $navHistory.fabrications = path?.split("/")[1];
    } else if (tab === "documents") {
      $navHistory.documents = path?.split("/")[1];
    }
  }

  async function setSupplier(orgid) {
    const so = $suppliers.find((s) => s.id === orgid);
    if (so?.id) {
      $currentSupplierid = so.id;
    }
  }

  async function loadSuppliers(org) {
    if (!org) return;

    let orgid;
    if ($suppliers.length) {
      if ($supplier?.id) {
        orgid = $supplier.id;
      } else {
        const s = $suppliers[0];
        orgid = s.id;
      }
    }

    setSupplier(orgid);
  }

  async function updateSupplier(e) {
    const orgid = e.detail.value;
    setSupplier(orgid);
  }

  onMount(() => {
    Mousetrap.bind(["command+z", "ctrl+z"], undo);
    Mousetrap.bind(["command+shift+z", "ctrl+y"], redo);
    Mousetrap.bind(["command+v", "ctrl+v"], () => {
      eb.dispatch("paste-from-clipboard");
    });
    Mousetrap.bind(["command+c", "ctrl+c"], () => {
      eb.dispatch("copy-to-clipboard");
    });

    Mousetrap.bind("esc", () => {
      $currentTool = "select";
      eb.dispatch("key-esc");
    });

    return () => {
      Mousetrap.unbind(["command+z", "ctrl+z"]);
      Mousetrap.unbind(["command+shift+z", "ctrl+y"]);
      Mousetrap.unbind("esc");
      if (group) group.destroy();
    };
  });
</script>

<svelte:head>
  <link href="https://fonts.cdnfonts.com/css/menlo" rel="stylesheet" on:load={updateJob} />
</svelte:head>

{#if $user && $profile}
  {#if pathtype === "link"}
    <Navbar context="group" {disabled} />
  {:else}
    <Navbar {tab} {group} {mark} {disabled} context="group" />
  {/if}
{:else}
  <NavbarUnauthenticated />
{/if}
<div class="grow flex flex-col min-h-0">
  {#await init() then _}
    {#if $group}
      <Router>
        {#if pathtype !== "link" && path !== "summary"}
          <Toolbar {tab} {path} {group} {disabled} />
        {/if}
        {#if pathtype === "version"}
          <GroupBanner {group} />
        {/if}
        <div class="grow flex flex-col min-h-0">
          <div class="bg-gray-100 w-full grow min-h-0 relative overflow-y-auto">
            <!-- Items -->
            <Route path="items">
              <ItemList
                {...jobData}
                {...colData}
                {disabled}
                {collections}
                on:updateSupplier={updateSupplier} />
            </Route>
            <Route path="openings">
              <CollectionList {...jobData} {...colData} {disabled} {collections} />
            </Route>
            <Route path="items/:itemid" let:params>
              {#if $group.items[params.itemid]}
                <ItemEditor {...jobData} {...colData} itemid={params.itemid} {disabled} {collections} />
              {:else}
                <Redirect to={`${$pathRoot}items`} />
              {/if}
            </Route>
            <Route path="openings/:itemid" let:params>
              {#if $group.items[params.itemid]}
                <ItemEditor
                  {...jobData}
                  {...colData}
                  {collections}
                  itemid={params.itemid}
                  {disabled}
                  isCollection />
              {:else}
                <Redirect to={`${$pathRoot}openings`} />
              {/if}
            </Route>

            <!-- Types -->
            <Route path="types">
              <TypeList {group} {disabled} {items} {types} on:updateSupplier={updateSupplier} />
            </Route>

            <Route path="types/:typeid" let:params>
              {#if $group.types[params.typeid]}
                <TypeEditor
                  {group}
                  {types}
                  typeid={params.typeid}
                  {disabled}
                  on:updateSupplier={updateSupplier} />
              {:else}
                <Redirect to={`${$pathRoot}types`} />
              {/if}
            </Route>

            <!-- Fabrications -->
            <Route path="fabrications">
              <FabricationList {group} {disabled} {items} />
            </Route>

            <Route path="fabrications/:fabid" let:params>
              {#if $group.data.fabrications?.[params.fabid]}
                <FabricationEditor fabid={params.fabid} {group} {disabled} {items} />
              {:else}
                <Redirect to={`${$pathRoot}fabrications`} />
              {/if}
            </Route>

            <Route path="documents">
              <DocumentList {group} {disabled} />
            </Route>

            <!-- <Route path="summary">
              {#if $profile?.user_role === "developer"}
                <JobDocument {group} {types} />
              {:else}
                <Redirect to={`${$pathRoot}items`} />
              {/if}
            </Route> -->

            <Route path="documents/:docid" let:params>
              {#if $group.documents?.[params.docid]}
                <Document
                  {group}
                  {disabled}
                  {items}
                  {collections}
                  {types}
                  docid={params.docid}
                  {...colData}
                  {queryParams}
                  on:updateSupplier={updateSupplier} />
              {:else}
                <Redirect to="${$pathRoot}documents" />
              {/if}
            </Route>

            <!-- Default -->
            <Route path="/">
              <ItemList
                {...jobData}
                {...colData}
                {disabled}
                {collections}
                on:updateSupplier={updateSupplier} />
            </Route>
          </div>
        </div>
      </Router>
      {#if $org}
        <ImportDataModal {group} {items} {collections} {types} {tab} />
        <SettingsModal {group} {customColumns} {orgColumns} {standardColumns} {disabled} />
      {/if}
    {/if}
  {/await}
</div>
