<script>
  import { getContext, onMount } from "svelte";
  import { Modal } from "svelte-utilities";
  import { navigate } from "svelte-routing";

  import TextInput from "#lib/sidebar/TextInput.svelte";
  import MapboxInput from "#lib/sidebar/MapboxInput.svelte";
  import Datatable from "#lib/datatable/Datatable.svelte";
  import ErrorMessage from "#lib/standard-inputs/ErrorMessage.svelte";
  import Pager from "#lib/Pager.svelte";

  import isEmail from "@local/extensions/validators/is-email.js";
  import { api } from "#src/api";

  export let queryParams;

  const maxRows = 20;
  const org = getContext("org");

  function newCustomer(orgId) {
    return {
      vendor_id: orgId,
      name: "",
      email: "",
      company: "",
      address: null,
      address_coordinates: null,
      address_postal_code: null,
      external_id: null,
    };
  }

  let customers = [];
  let count = 0;
  let customer = newCustomer($org.id);
  let newCustomerModal;
  let error = null;

  $: inputPage = parseInt(queryParams?.page) || 1;
  $: page = Math.max(inputPage - 1, 0);
  $: customerProps = [
    { label: "Name", prop: "name", type: "text" },
    { label: "Email", prop: "email", type: "text" },
    { label: "Address", prop: "address", type: "text" },
    { label: "Company", prop: "company", type: "text" },
  ];

  function gotoPage(e) {
    const p = e.detail;
    navigate(`/settings/product_customers?page=${p + 1}`);
  }

  function beginAddingCustomer() {
    customer = newCustomer($org.id);
    newCustomerModal.open();
  }

  async function addCustomer() {
    const res = await api.from("product_customers").insert(customer);
    fetchCustomers();
  }

  async function fetchCustomers() {
    const min = page * maxRows;
    const max = min + maxRows - 1;

    const records = await api
      .from("product_customers")
      .select("*")
      .eq("vendor_id", $org.id)
      .limit(maxRows)
      .range(min, max)
      .order("name");
    const c = await api.from("product_customers").select("*", { count: "exact", head: true });

    if (records.data && c.count) {
      customers = records.data;
      count = c.count;
    }
  }

  function clickCustomer(e) {
    const { id } = e.detail;
    navigate(`/settings/product_customers/${id}`);
  }

  onMount(() => {
    fetchCustomers();
  });
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Product Customers</h2>
</div>

<div class="py-4 sm:px-4 flex justify-end items-center">
  <button class="btn btn-primary" on:click={beginAddingCustomer}>+ Add Customer</button>
</div>

<ErrorMessage message={error} on:close={() => (error = null)} />
<div class="bg-white border rounded-lg overflow-hidden">
  <Datatable data={customers} columns={customerProps} visitable on:clickrow={clickCustomer} />
  <Pager {page} {count} on:gotopage={gotoPage} />
</div>

<Modal
  bind:this={newCustomerModal}
  width="36rem"
  closeable
  on:confirm={addCustomer}
  buttons={[
    { label: "Cancel", type: "cancel" },
    {
      label: "Invite",
      type: "confirm",
      style: "primary",
      disabled: !isEmail(customer.email) || !customer.name,
    },
  ]}>
  <div slot="title">Add Customer</div>
  <div slot="content" class="space-y-4">
    <TextInput labelWidth="5rem" label="Name" border inputOnKeydown bind:value={customer.name} />
    <TextInput labelWidth="5rem" label="Email" border inputOnKeydown bind:value={customer.email} />
    <TextInput labelWidth="5rem" label="Company" border inputOnKeydown bind:value={customer.company} />
    <MapboxInput
      labelWidth="5rem"
      label="Address"
      border
      bind:value={customer.address}
      bind:coordinates={customer.address_coordinates}
      bind:postal_code={customer.address_postal_code} />
    <!-- <TextInput
      labelWidth="8rem"
      label="External ID"
      border
      inputOnKeydown
      bind:value={customer.external_id} /> -->
  </div>
</Modal>
