import uniqBy from "lodash/uniqBy";
import { api } from "#src/api";
import { orgFabrications } from "#src/extensions/org-fabrications.js";
import { sortableList } from "@local/extensions/collections/sortable-list.js";
import {
  makePriceLibrary,
  orderProductLists,
} from "@local/lamina-core/reporters";

export async function getSuppliers(org) {
  if (!org) return [];

  if (org.org_type === "supplier") {
    const suppliers = await api
      .from("organizations")
      .select("*,profiles!profiles_organization_id_fkey(*))")
      .eq("id", org.id);

    if (suppliers.error) {
      return [];
    }

    return suppliers.data;
  }

  let br = api
    .from("business_relations")
    .select("*,seller: seller_id(*,profiles!profiles_organization_id_fkey(*))");

  if (org.org_type === "product_rep") {
    br = br.eq("rep_id", org.id);
  } else {
    br = br.eq("buyer_id", org.id);
  }

  const { data, error } = await br;

  if (error) {
    console.log("Error getting suppliers", error);
    return [];
  }

  const suppliers = uniqBy(data, "seller_id").map((s) => ({
    ...s.seller,
    business_relation: s,
  }));

  return suppliers;
}

async function getOrg(orgId) {
  return await api
    .from("organizations")
    .select("*,products(*),makeups(*),item_products(*),product_categories(*)")
    .eq("id", orgId)
    .is("products.published", true)
    .is("makeups.published", true)
    .is("item_products.published", true)
    .single();
}

export async function getSupplier(supplierId, orgId) {
  if (orgId && supplierId && orgId !== supplierId) {
    const br = await api
      .from("business_relations")
      .select("*")
      .eq("buyer_id", orgId)
      .eq("seller_id", supplierId)
      .maybeSingle();

    if (br.data) {
      const plId = br.data.product_list_id;
      const prods = br.data.products;

      const s = await getOrg(supplierId);

      if (s.error) {
        return null;
      }

      if (prods === "product_list" && plId) {
        const products = await api
          .from("price_entries")
          .select(
            "*, products(*), makeups(*),item_products(*),product_categories(*)",
          )
          .eq("list_id", plId)
          .is("item_products.published", true)
          .is("products.published", true)
          .is("makeups.published", true);

        if (products.error) {
          return null;
        }

        const p = products.data.map((p) => p.products);
        const m = products.data.map((p) => p.makeups).filter((m) => m);
        const ip = products.data.map((p) => p.item_products).filter((ip) => ip);
        const pc = products.data
          .map((p) => p.product_categories)
          .filter((pc) => pc);

        return {
          ...s.data,
          products: p,
          makeups: m,
          item_products: ip,
          product_categories: sortableList(pc),
          fabrications: orgFabrications(s.data),
        };
      } else {
        return {
          ...s.data,
          fabrications: orgFabrications(s.data),
          product_categories: sortableList(s.data.product_categories),
        };
      }
    } else {
      const rep = await api
        .from("business_relations")
        .select("*")
        .eq("rep_id", orgId)
        .eq("seller_id", supplierId)
        .maybeSingle();

      if (rep.data) {
        const s = await getOrg(supplierId);

        if (s.error) {
          return null;
        }

        return {
          ...s.data,
          fabrications: orgFabrications(s.data),
          product_categories: sortableList(s.data.product_categories),
        };
      } else {
        return null;
      }
    }
  } else if (supplierId) {
    const s = await getOrg(supplierId);

    if (s.error) {
      return null;
    } else {
      return {
        ...s.data,
        fabrications: orgFabrications(s.data),
        product_categories: sortableList(s.data.product_categories),
      };
    }
  } else {
    return null;
  }
}

export async function getPriceEntries(supplierId) {
  if (!supplierId) {
    return {};
  }

  const pl = await api
    .from("product_lists")
    .select("*")
    .eq("vendor_id", supplierId);

  if (pl.error) {
    return {};
  }

  const plIds = pl.data.map((p) => p.id);

  const pe = await api
    .from("price_entries")
    .select("*,fabrication_price_entries(*),edge_treatment_price_entries(*)")
    .in("list_id", plIds)
    .order("priority");

  if (pe.error) {
    return {};
  }

  return makePriceLibrary(pe.data);
}

export async function getProductLists(supplierId) {
  if (supplierId) {
    const pl = await api
      .from("product_lists")
      .select("*,quote_item_scripts(*)")
      .eq("vendor_id", supplierId)
      .order("sort_order");

    if (!pl.data) {
      return [];
    }

    return orderProductLists(pl.data);
  } else {
    return [];
  }
}
