<script>
  import { createEventDispatcher, getContext, tick } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import range from "lodash/range";
  import { downloadBlob } from "downloaders";

  import FileIcon from "@local/assets/icons/file.svg";
  import CaretRightIcon from "@local/assets/icons/caret-right.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import FilePdfIcon from "@local/assets/icons/file-pdf.svg";
  import FileImgIcon from "@local/assets/icons/file-img.svg";
  import DownloadIcon from "@local/assets/icons/download.svg";
  import LocationItemIcon from "@local/assets/icons/location-item-filled.svg";
  import EllipsisIcon from "@local/assets/icons/ellipsis.svg";
  import SpinnerIcon from "@local/assets/icons/spinner.svg";

  import { Modal, clickOutside } from "svelte-utilities";
  import UppyDropzone from "#lib/dropzone/UppyDropzone.svelte";

  import Viewport from "#lib/drawing/ViewportFixed.svelte";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";
  import SidebarSection from "./SidebarSection.svelte";
  import TextInput from "./TextInput.svelte";
  import extension from "@local/extensions/utilities/extension.js";
  import {
    transformSheetPoint,
    sheetScaleFactor,
  } from "@local/extensions/geometry/transform-sheet-points.js";
  import { createItem as defaultItem } from "@local/lamina-core";
  import maxima from "@local/extensions/utilities/maxima.js";
  import locationsByDocument from "#src/extensions/locations-by-document.js";
  import {
    showDocPages,
    showDocFiles,
    showDocAnnotations,
    selectedAnnotations,
    selectedPages,
  } from "#src/stores/ui.js";
  import { profile } from "#src/stores/auth.js";
  import { api, getPageCount, getDocPdf } from "#src/api";
  import { SUPABASE_URL as supabaseUrl } from "#src/env";
  import { orderedList, addToSortableList } from "@local/extensions/collections/sortable-list.js";
  import eb from "#src/extensions/event-bus.js";

  export let document;
  export let group;
  export let items = [];
  export let disabled;
  export let annotations = [];
  export let hoveredFile = null;
  export let hoveredPage = null;
  export let lastSelectedPage = null;
  export let hoveredAnnotation = null;
  export let viewer = null;

  const dispatch = createEventDispatcher();
  const fileData = getContext("fileData");
  const typeColors = getContext("typeColors");

  let importModal;
  let convertModal;
  let annoSelector;
  let overallAnnoSelection;
  let showHidden = false;
  let labelInput;
  let editingLabel = null;
  let labelValue = "";
  let showDocActions = false;
  let downloading = false;

  const fileIcons = {
    file: FileIcon,
    pdf: FilePdfIcon,
    jpg: FileImgIcon,
    jpeg: FileImgIcon,
    png: FileImgIcon,
    svg: FileImgIcon,
  };

  $: pages = orderedList(document.data.pages);
  $: visiblePages = pages.filter((p) => !p.hidden).map((p) => p.id);
  $: visibleIndices = visiblePages.reduce((obj, pageId, index) => {
    obj[pageId] = index;
    return obj;
  }, {});
  $: hidden = pages.filter((p) => p.hidden);
  $: docLocations = locationsByDocument($group.locations);
  $: locations = docLocations[document.id] || [];
  $: pageLocations = getPageLocations(locations);
  $: checkSelectionState($selectedAnnotations, annoSelector, annotations);
  $: selectedAnnos = annotations.filter((a) => $selectedAnnotations[a.id]);
  $: files = getPresentFiles(document, $fileData);
  $: pageIds = getPageIds(document);
  $: fileIndex = getFileIndex(document);

  function locRecord(location) {
    if (!location) return null;
    return location.record_type === "type"
      ? $group.types[location.record_id]
      : $group.items[location.record_id];
  }

  function getPageLocations(locations) {
    return locations.reduce((obj, loc) => {
      if (!loc) return obj;
      if (!obj[loc.page_id]) obj[loc.page_id] = [];
      if (locRecord(loc)) obj[loc.page_id].push(loc);
      return obj;
    }, {});
  }

  function getPresentFiles(document, fd) {
    if (!document.data?.pages) return [];
    const f = document.data.pages.order.reduce((files, pageIndex) => {
      const page = document.data.pages[pageIndex];
      files[page.name] = true;
      return files;
    }, {});
    return Object.keys(f)
      .map((f) => fd[f])
      .filter((f) => f);
  }

  function zoomToPage(id) {
    dispatch("zoom-to-page", { page: id });
  }

  function getPageIds(document) {
    const indices = {};
    document.data.pages.order
      .filter((pageId) => !document.data.pages[pageId].hidden)
      .forEach((pageId) => {
        const page = document.data.pages[pageId];
        if (!indices[page.name]) indices[page.name] = [];
        indices[page.name].push(pageId);
      });
    return indices;
  }

  function getFileIndex(doc) {
    const idx = {};
    doc.data.pages.order.forEach((pageId) => {
      const page = doc.data.pages[pageId];
      if (!idx[page.name]) idx[page.name] = {};
      idx[page.name][page.page] = pageId;
    });
    return idx;
  }

  function checkSelectionState(selected, annoSelector, annotations) {
    if (!annotations) return;
    const ids = annotations.map((a) => a.id);
    if (ids.every((id) => selected[id])) {
      overallAnnoSelection = true;
    } else if (ids.every((id) => !selected[id])) {
      overallAnnoSelection = false;
    } else {
      overallAnnoSelection = "mixed";
    }

    if (annoSelector) {
      if (overallAnnoSelection === "mixed") {
        annoSelector.indeterminate = true;
      } else {
        annoSelector.indeterminate = false;
      }
    }
  }

  function updateDoc(prop, value) {
    if (typeof document.id === "string") {
      group.updateDocument(document.id, { [prop]: value });
    }
  }

  async function downloadFile(file) {
    try {
      const { data, error } = await api.storage.from("documents").download(file.name);
      if (error) throw error;

      const filename = file.file.user_metadata?.name || file.name;
      downloadBlob(data, filename);
    } catch (error) {
      console.log(error);
    }
  }

  function beginConverting() {
    convertModal.open();
  }

  function convertAnnotations() {
    const converted = annotations.filter((a) => $selectedAnnotations[a.id]);

    // Create items
    const newItems = [];
    converted.forEach((a, i) => {
      const item = {
        ...defaultItem($group.id, $profile.id, [...items, ...newItems]),
        ...a.item,
        is_imported: true,
      };

      if (a?.notes?.length > 0) {
        const { note_label } = a.notes[0];
        if (typeof note_label === "string" && note_label.length > 0) {
          item.mark = note_label;
        }
      }

      newItems.push(item);
    });

    // Add location markers for each new item
    const locs = newItems
      .map((item, i) => {
        const annotation = converted[i];
        const pt = annotation.own_label_point;

        const tile = viewer.tile(annotation.page);
        const size = tile.getContentSize();

        const initPt = { x: pt[0] - annotation.sheet.limits[0][0], y: pt[1] - annotation.sheet.limits[0][1] };

        const scale = sheetScaleFactor(annotation.sheet.limits, annotation.sheet.rotation, size);
        const tpt = transformSheetPoint(initPt, annotation.sheet.rotation, scale, size);

        let rot = annotation.sheet.rotation % 360 || 0;
        const m = maxima(annotation.geom.coordinates[0]);

        let bb;
        let w;
        let h;
        if (rot === 0) {
          bb = { x: m.minX, y: m.minY };
          w = m.maxX - m.minX;
          h = m.maxY - m.minY;
        } else if (rot === 90) {
          bb = { x: m.maxX, y: m.minY };
          w = m.maxY - m.minY;
          h = m.maxX - m.minX;
        } else if (rot === 180) {
          bb = { x: m.maxX, y: m.maxY };
          w = m.maxX - m.minX;
          h = m.maxY - m.minY;
        } else if (rot === 270) {
          bb = { x: m.minX, y: m.maxY };
          w = m.maxY - m.minY;
          h = m.maxX - m.minX;
        }

        const bboxPt = { x: bb.x - annotation.sheet.limits[0][0], y: bb.y - annotation.sheet.limits[0][1] };
        const bboxTpt = transformSheetPoint(bboxPt, annotation.sheet.rotation, scale, size);
        const diag = Math.sqrt(w * w + h * h);
        const itemDiag = Math.sqrt(
          item.cache.width * item.cache.width + item.cache.height * item.cache.height,
        );
        const sc = diag / itemDiag;

        const file_path = annotation.document_name;
        const file_page = annotation.sheet_idx;
        const page_id = fileIndex[file_path]?.[file_page];
        const position = {
          x: tpt.x,
          y: tpt.y,
        };

        const location = {
          id: crypto.randomUUID(),
          group_id: $group.id,
          document_id: document.id,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          transform: {
            scale: scale * sc,
            translate: bboxTpt,
          },
          record_type: "item",
          record_id: item.id,
        };

        return location;
      })
      .filter((l) => l.page_id);

    group.update([
      { type: "item", action: "add", records: newItems, block: true },
      { type: "location", action: "add", records: locs },
    ]);

    $selectedAnnotations = {};
  }

  function beginAddingFile() {
    importModal.open();
  }

  function selectFile(file) {
    const ids = document.data.pages.order.filter((id) => document.data.pages[id].name === file.name);
    selectedPages.selectOnly(...ids);
    lastSelectedPage = null;
  }

  function selectAllAnnotations() {
    const ids = annotations.map((a) => a.id);
    if (ids.some((id) => $selectedAnnotations[id])) {
      selectedAnnotations.deselect(...ids);
    } else {
      selectedAnnotations.select(...ids);
    }
  }

  function defaultLabel(page) {
    if (!page) return "";
    const sheet = $fileData[page.name]?.sheets[page.page];
    const index = document.data.pages.indices[page.id];
    return page.label || sheet?.name || index + 1;
  }

  async function beginEditingLabel(page) {
    if (disabled) return;
    editingLabel = page.id;
    labelValue = defaultLabel(page);
    await tick();
    labelInput.focus();
    labelInput.select();
  }

  function updateLabel(id, value) {
    if (value === defaultLabel(document.data.pages[id])) {
      return;
    }

    const data = cloneDeep(document.data);
    data.pages[id].label = value;
    group.updateDocument(document.id, { data });
  }

  function handleKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      updateLabel(editingLabel, labelValue);
      editingLabel = null;
    } else if (e.key === "Escape") {
      editingLabel = null;
    }
  }

  function handleBlur() {
    if (!editingLabel) return;
    updateLabel(editingLabel, labelValue);
    editingLabel = null;
  }

  function selectPage(e, page) {
    if (e.shiftKey) {
      let newSelected;
      if (showHidden) {
        const prev = lastSelectedPage || document.data.pages.order[0];
        const prevIndex = document.data.pages.indices[prev];
        const currentIndex = document.data.pages.indices[page.id];
        const min = Math.min(prevIndex, currentIndex);
        const max = Math.max(prevIndex, currentIndex);
        newSelected = range(min, max + 1).map((i) => document.data.pages.order[i]);
      } else {
        const prev = lastSelectedPage || visiblePages[0];
        const prevIndex = visibleIndices[prev];
        const currentIndex = visibleIndices[page.id];
        const min = Math.min(prevIndex, currentIndex);
        const max = Math.max(prevIndex, currentIndex);
        newSelected = range(min, max + 1).map((i) => visiblePages[i]);
      }
      selectedPages.select(...newSelected);
      lastSelectedPage = page.id;
    } else if (e.metaKey) {
      selectedPages.select(page.id);
      lastSelectedPage = page.id;
    } else {
      selectedPages.selectOnly(page.id);
      lastSelectedPage = page.id;
    }
  }

  async function addFiles(e) {
    const { results } = e.detail;

    if (results.length) {
      const filenames = results.map(async (r) => {
        await api
          .schema("storage")
          .from("objects")
          .update({ user_metadata: { name: r.meta.name } })
          .eq("name", r.meta.objectName)
          .eq("bucket_id", "documents");
      });

      const pagePromises = results.map(async (r) => {
        if (r.meta.type === "application/pdf") {
          const pageCount = await getPageCount("documents", r.meta.objectName);
          if (pageCount.data) return pageCount.data.pages;
          return 0;
        } else {
          return 1;
        }
      });

      const pageCounts = await Promise.all(pagePromises);

      const pages = [];

      results.forEach((r, i) => {
        for (let j = 0; j < pageCounts[i]; j++) {
          pages.push({
            id: crypto.randomUUID(),
            name: r.meta.objectName,
            label: null,
            hidden: false,
            page: j,
          });
        }
      });

      const data = cloneDeep(document.data);
      addToSortableList(data.pages, ...pages);
      group.updateDocument(document.id, { data });
      await Promise.all(filenames);
    }

    importModal.close();
  }

  function beginRelabeling() {
    showDocActions = false;
    eb.dispatch("relabel-pages");
  }

  async function getPdf() {
    downloading = true;
    showDocActions = false;
    const pdf = await getDocPdf(document.id);
    if (pdf.error) return;
    try {
      if (pdf.data.state !== "completed") {
        throw new Error(`PDF failure: ${pdf.data.state}, ${pdf.data.job_id}`);
      }
      const { data, error } = await api.storage.from("temp").download(pdf.data.path);
      if (error) throw error;
      downloadBlob(data, pdf.data.name);
    } catch (error) {
      console.log(error);
    }
    downloading = false;
  }
</script>

<SidebarSection>
  <TextInput
    label="Name"
    {disabled}
    labelWidth="5rem"
    value={document.name}
    on:input={(e) => updateDoc("name", e.detail.value)} />
</SidebarSection>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<SidebarSection>
  <div class="relative">
    <div class="flex justify-between items-center">
      <h3 class="mb-2 px-2">Pages</h3>
      <div class="flex gap-2 border border-transparent">
        {#if !$showDocPages && pages.length}
          <div>
            ({pages.length})
          </div>
        {:else}
          {#if hidden.length}
            <button on:click={() => (showHidden = !showHidden)} class="btn-text">
              {#if showHidden}
                Hide Hidden
              {:else}
                Show Hidden
              {/if}
            </button>
          {/if}
          <button
            class="relative p-1 rounded hover:bg-gray-200"
            use:clickOutside
            on:outclick={() => (showDocActions = false)}
            on:click={() => (showDocActions = !showDocActions)}>
            {#if downloading}
              <div class="animate-spin">
                <SpinnerIcon />
              </div>
            {:else}
              <EllipsisIcon />
            {/if}
            {#if showDocActions}
              <div class="dropdown-container right-0">
                <button class="dropdown-button-item" on:click|stopPropagation={getPdf} disabled={downloading}>
                  Export PDF
                </button>
                {#if !disabled}
                  <button class="dropdown-button-item" on:click|stopPropagation={beginRelabeling}>
                    Relabel Pages
                  </button>
                {/if}
              </div>
            {/if}
          </button>
        {/if}
      </div>
    </div>
    <button class="absolute top-0 p-1 left-0 -ml-3 -mt-1" on:click={() => ($showDocPages = !$showDocPages)}>
      {#if $showDocPages}
        <CaretDownIcon />
      {:else}
        <CaretRightIcon />
      {/if}
    </button>
  </div>
  {#if $showDocPages}
    <div>
      {#each pages as page, index}
        {@const file = $fileData[page.name]}
        {@const sheet = file?.sheets[page.page]}
        {@const label = page.label || sheet?.name || index + 1}
        {@const pageLocs = pageLocations[page.id]}
        {#if showHidden || !page.hidden}
          <div
            class="flex gap-2 py-1 px-4 select-none"
            class:bg-blue-500={$selectedPages[page.id]}
            class:text-white={$selectedPages[page.id]}
            class:opacity-30={page.hidden}
            on:mouseover={() => (hoveredPage = page.id)}
            on:mouseout={() => (hoveredPage = null)}
            on:click|preventDefault={(e) => selectPage(e, page)}
            on:dblclick={() => zoomToPage(page.id)}>
            <div class="w-4">
              {index + 1}
            </div>
            {#if editingLabel === page.id}
              <input
                class="label-input grow"
                size="1"
                bind:this={labelInput}
                bind:value={labelValue}
                on:keydown={handleKeydown}
                on:blur={handleBlur} />
            {:else if $selectedPages[page.id] && Object.keys($selectedPages).length === 1}
              <div class="grow">
                <button class="truncate" on:click|stopPropagation={() => beginEditingLabel(page)}>
                  {label}
                </button>
              </div>
            {:else}
              <div class="text-left truncate grow">
                {label}
              </div>
            {/if}
            {#if pageLocs?.length}
              <div class="flex-none">
                <LocationItemIcon />
              </div>
            {/if}
          </div>
        {/if}
      {/each}
    </div>
    {#if !disabled}
      <div class="flex justify-end py-2">
        <button class="font-bold" on:click={beginAddingFile}> + Add Pages </button>
      </div>
    {/if}
  {/if}
</SidebarSection>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<SidebarSection>
  <div class="relative">
    <div class="flex justify-between items-center">
      <h3 class="mb-2 px-2">Files</h3>
      <div class="flex gap-2 border border-transparent">
        {#if !$showDocFiles && files.length}
          <div>
            ({files.length})
          </div>
        {/if}
      </div>
    </div>
    <button class="absolute top-0 p-1 left-0 -ml-3 -mt-1" on:click={() => ($showDocFiles = !$showDocFiles)}>
      {#if $showDocFiles}
        <CaretDownIcon />
      {:else}
        <CaretRightIcon />
      {/if}
    </button>
  </div>
  {#if $showDocFiles}
    <div class="px-2">
      {#each files as file}
        <div
          class="flex justify-between items-center rounded file-button-container py-1"
          on:mouseover={() => (hoveredFile = file.name)}
          on:mouseout={() => (hoveredFile = null)}>
          <div
            class="flex gap-1 overflow-hidden"
            on:click={() => selectFile(file)}
            on:dblclick={() => dispatch("zoom-to-page", { page: pageIds[file.name] })}>
            <button
              class="flex-none text-gray-400 file-button"
              on:click|stopPropagation={() => downloadFile(file)}>
              <div class="file-button-icon">
                <svelte:component this={fileIcons[extension(file.name)] || FileIcon} />
              </div>
              <div class="file-download-icon">
                <DownloadIcon />
              </div>
            </button>
            <div class="grow overflow-hidden">
              <div class="truncate">
                {file?.file.user_metadata?.name || file.name}
              </div>
            </div>
          </div>
        </div>
      {/each}
    </div>
  {/if}
</SidebarSection>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
{#if annotations?.length}
  <SidebarSection>
    <div class="relative">
      <div class="flex justify-between items-start">
        <h3 class="mb-2 px-2">Importable Shapes</h3>
        <div class="flex gap-2 border border-transparent">
          {#if $showDocAnnotations && annotations.length}
            <!-- <div>Page</div> -->
            <div class="pr-3.5 border-r border-transparent">
              <input
                bind:this={annoSelector}
                type="checkbox"
                bind:checked={overallAnnoSelection}
                on:input={selectAllAnnotations} />
            </div>
          {:else if annotations.length}
            <div>
              ({annotations.length})
            </div>
          {/if}
        </div>
      </div>
      <button
        class="absolute top-0 p-1 left-0 -ml-3 -mt-1"
        on:click={() => ($showDocAnnotations = !$showDocAnnotations)}>
        {#if $showDocAnnotations}
          <CaretDownIcon />
        {:else}
          <CaretRightIcon />
        {/if}
      </button>
    </div>
    {#if $showDocAnnotations}
      <div class="px-2">
        {#each annotations as annotation (annotation.id)}
          <button
            class="text-left w-full flex justify-between items-center rounded border hover:border-gray-400"
            class:border-transparent={parseInt(hoveredAnnotation) !== annotation.id}
            class:border-gray-400={parseInt(hoveredAnnotation) === annotation.id}
            on:mouseover={() => (hoveredAnnotation = annotation.id)}
            on:mouseout={() => (hoveredAnnotation = null)}
            on:click={() => dispatch("zoom-to-page", { page: annotation.page_id })}>
            <div
              class="p-1 flex gap-2 grow overflow-hidden annotation-item"
              class:selected={$selectedAnnotations[annotation.id]}>
              <div class="w-4 h-4 relative flex-none">
                <Viewport
                  padding={1}
                  drawing={liteDrawingMini(annotation.item, {
                    typeColor: $typeColors[annotation.item?.type_id],
                  })}
                  width={16}
                  height={16} />
              </div>
              <div class="grow flex gap-2 overflow-hidden">
                <div class="grow truncate">
                  {annotation.own_label}
                </div>
                <!-- <div class="text-gray-500">
                  {annotation.page}
                </div> -->
              </div>
              <div class="flex-none w-4">
                <input
                  type="checkbox"
                  checked={$selectedAnnotations[annotation.id]}
                  on:input={() => selectedAnnotations.toggle(annotation.id)}
                  on:click|stopPropagation />
              </div>
            </div>
          </button>
        {/each}
      </div>
      {#if !disabled}
        <div class="flex justify-end py-2">
          <button class="font-bold" on:click={beginConverting}>
            + Import Selected ({selectedAnnos.length})
          </button>
        </div>
      {/if}
    {/if}
  </SidebarSection>
{/if}

<Modal bind:this={importModal} closeable width="36rem">
  <div slot="title">Add Pages to Document</div>
  <div slot="content">
    <UppyDropzone
      uploadUrl={`${supabaseUrl}/storage/v1/upload/resumable`}
      bucket="documents"
      accept="application/pdf,image/jpeg,image/gif,image/png"
      on:complete={addFiles} />
  </div>
</Modal>

<Modal
  bind:this={convertModal}
  closeable
  width="36rem"
  on:confirm={convertAnnotations}
  buttons={selectedAnnos.length
    ? [
        { label: "Cancel", type: "cancel" },
        { label: "Create Items", type: "confirm", style: "primary" },
      ]
    : [{ label: "Cancel", type: "cancel" }]}>
  <div slot="title">Import Shapes</div>
  <div slot="content" class="space-y-2">
    {#if selectedAnnos.length}
      <div>Import the following shapes:</div>
      <div class="space-y-1">
        <div class="flex gap-2 px-4 justify-end text-xs text-gray-500">Page</div>
        {#each selectedAnnos as annotation}
          <div class="flex gap-2 px-4">
            <div class="w-4 h-4 relative">
              <Viewport padding={1} drawing={liteDrawingMini(annotation.item)} width={16} height={16} />
            </div>
            <div class="grow flex gap-2 items-center text-xs overflow-hidden">
              <div class="grow truncate">
                {annotation.own_label}
              </div>
              <div class="text-gray-500">
                {annotation.page + 1}
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <div>Select shapes to import as items.</div>
    {/if}
  </div>
</Modal>

<style lang="scss">
  input.label-input {
    @apply grow bg-blue-200 text-black;
    border: none;
    outline: none;
    min-width: 0;
    max-width: 100%;
  }

  .file-button-container {
    .file-button-icon {
      display: block;
    }

    .file-download-icon {
      display: none;
    }

    &:hover {
      .file-button-icon {
        display: none;
      }

      .file-download-icon {
        display: block;
        color: black;
      }
    }
  }
</style>
