<!-- <svelte:options strictprops={false} /> -->

<script>
  import { Link, navigate } from "svelte-routing";
  import { getContext } from "svelte";
  import { ReorderableList } from "svelte-utilities";

  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import TextInput from "#lib/sidebar/TextInput.svelte";
  import MapboxInput from "#lib/sidebar/MapboxInput.svelte";

  import { api } from "#src/api";
  import { profile } from "#src/stores/auth.js";

  export let customerid;

  const org = getContext("org");
  const productLists = getContext("productLists");

  let customer;

  $: canAdmin = $profile && ["org_admin", "developer"].includes($profile.user_role);

  async function init() {
    const c = await api
      .from("product_customers")
      .select("*,product_customer_product_lists(*)")
      .eq("vendor_id", $org.id)
      .eq("id", customerid)
      .single();

    if (c.error) {
      navigate("/settings/product_customers");
    } else {
      customer = {
        ...c.data,
      };
    }
  }

  function beginAddingProductList() {}

  async function updateProp(prop, value) {}
</script>

<div class="text-blue-500 text-sm mb-4">
  <Link to="/settings/product_customers" class="flex items-center space-x-1">
    <CaretLeftIcon />
    <div>All Product Customers</div>
  </Link>
</div>

{#await init() then _}
  {#if customer}
    <div class="py-4 sm:px-4 space-y-2 text-sm">
      <div class="w-full sm:w-96 space-y-4">
        <TextInput labelWidth="5rem" border label="Name" value={customer.name} disabled={!canAdmin} />
        <TextInput labelWidth="5rem" border label="Email" value={customer.email} disabled={!canAdmin} />
        <TextInput labelWidth="5rem" border label="Company" value={customer.company} disabled={!canAdmin} />
        <MapboxInput
          labelWidth="5rem"
          label="Address"
          border
          disabled={!canAdmin}
          bind:value={customer.address}
          bind:coordinates={customer.address_coordinates}
          bind:postal_code={customer.address_postal_code} />
      </div>
    </div>
    <div class="space-y-2">
      <div class="py-4 sm:px-4 flex justify-between items-center">
        <h3 class="py-1 px-2">Product Lists</h3>
        {#if canAdmin}
          <button class="btn btn-primary" on:click={beginAddingProductList}>+ Add Product List</button>
        {/if}
      </div>
    </div>
    {#each customer.product_customer_product_lists as list}
      <div>{list.product_list_id}</div>
    {/each}
  {/if}
{/await}
