<script>
  import { createEventDispatcher, tick } from "svelte";
  import { DimText, LinearDisplayFormat } from "dimtext";
  import dimSettings from "@local/extensions/utilities/dim-settings.js";

  export let border = false;
  export let label = "Label";

  /** @type {string | null} */
  export let labelWidth = null;
  export let value;
  export let offsetValue;
  export let totalValue;
  export let disabled = false;
  export let offsetDisabled = false;
  export let readonly = false;
  export let validator = (v) => true;
  export let settings;
  export let allowNull = false;

  const dispatch = createEventDispatcher();

  let input;
  let focused = false;
  let inputContents;

  let offsetInput;
  let offsetInputContents;

  $: ds = dimSettings(settings);
  $: dt = new DimText({ defaultUnit: ds.displayUnit });
  $: lw = labelWidth ? `width:${labelWidth};` : "";

  $: inputValue = value === "Mixed" ? value : value?.toString();
  $: noinput = !value;
  $: displayValue =
    value === "Mixed"
      ? value
      : value?.format(LinearDisplayFormat[ds.dimFormat], ds.dimPrecision, { displayUnit: ds.displayUnit });

  $: offsetInputValue = offsetValue === "Mixed" ? offsetValue : offsetValue.toString();
  $: offsetDisplayValue =
    offsetValue === "Mixed"
      ? offsetValue
      : offsetValue.format(LinearDisplayFormat[ds.dimFormat], ds.dimPrecision, {
          displayUnit: ds.displayUnit,
        });

  $: totalDisplayValue =
    totalValue === "Mixed"
      ? totalValue
      : totalValue?.format(LinearDisplayFormat[ds.dimFormat], ds.dimPrecision, {
          displayUnit: ds.displayUnit,
        });

  $: update(value, ds.dimFormat);
  $: updateOffset(offsetValue, ds.dimFormat);
  $: valid = validate(inputContents);
  $: validOffset = validate(offsetInputContents);

  function update(value, sdf) {
    inputContents = focused ? inputValue : displayValue;
  }

  function updateOffset(offsetValue, sdf) {
    offsetInputContents = focused ? offsetInputValue : offsetDisplayValue;
  }

  function validate(contents) {
    const result = contents && contents !== "Mixed" && dt.parse(contents);

    if (!result || !result.ok) return false;

    try {
      const dim = result.value;
      return validator(dim);
    } catch (err) {
      return false;
    }
  }

  function parseInput(field, val) {
    const v = field === "offset" ? offsetValue : value;
    try {
      if (field === "input" && val === "" && allowNull && value !== null) {
        dispatch("input", { value: null, field });
        return;
      }

      let result = dt.parse(val);
      if (!result.ok) throw result.err;
      const isValid = field === "offset" ? validOffset : valid;
      if (v?.toString() !== result.value.toString() && isValid) {
        dispatch("input", { value: result.value, field });
      }
    } catch (err) {
      dispatch("error", err);
    }
  }

  function focus() {
    if (focused) return;
    dispatch("focus");
    input.focus();
  }

  async function handleFocus(field) {
    focused = true;

    if (field === "offset") {
      offsetInputContents = offsetInputValue;
      await tick();
      offsetInput.select();
    } else {
      inputContents = inputValue;
      await tick();
      input.select();
    }
  }

  function handleKeydown(evt) {
    if (evt.key === "Enter") {
      evt.stopPropagation();
      evt.target.blur();
    }
  }

  async function handleBlur(field, evt) {
    parseInput(field, evt.target.value);
    await tick();

    if (field === "offset") {
      focused = false;
      offsetInputContents = offsetDisplayValue;
      dispatch("blur");
    } else if (offsetInput) {
      inputContents = displayValue;
      offsetInput.select();
    }
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="prop-container"
  class:focused
  class:readonly
  class:visible-border={border}
  class:noinput
  on:mouseover
  on:mouseout
  on:focus
  on:blur
  on:click|stopPropagation={focus}>
  <div class="label" style={lw}>
    <slot name="label">
      {label}
    </slot>
  </div>
  {#if readonly}
    <div class="grow flex justify-between pl-2 gap-1">
      <div bind:this={input} class="input">{displayValue}</div>
      <div bind:this={offsetInput} class="input">{offsetDisplayValue}</div>
      <div class="input">{totalDisplayValue}</div>
    </div>
  {:else}
    <div class="grow flex justify-between pl-2 gap-1">
      <input
        class="input value-input"
        size="1"
        bind:this={input}
        class:text-gray-500={disabled}
        {disabled}
        bind:value={inputContents}
        on:focus={() => handleFocus("input")}
        on:keydown={handleKeydown}
        on:blur={(e) => handleBlur("input", e)} />
      <input
        class="input"
        size="1"
        bind:this={offsetInput}
        class:text-gray-500={offsetDisabled}
        disabled={offsetDisabled}
        bind:value={offsetInputContents}
        on:focus={() => handleFocus("offset")}
        on:keydown={handleKeydown}
        on:blur={(e) => handleBlur("offset", e)} />
      <div class="input italic">{totalDisplayValue || ""}</div>
    </div>
  {/if}
</div>

<style lang="scss">
  input {
    border: none;
    background-color: transparent;
    outline: none;
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
  }

  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded;

    .label {
      @apply truncate text-gray-400 flex-none;
    }

    &.noinput:not(.focused) {
      .value-input {
        @apply bg-red-200;
      }

      .label {
        @apply text-black;
      }
    }

    &:hover:not(.readonly),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }

    &.focused {
      @apply ring-2 ring-inset ring-blue-500;
    }

    .input {
      @apply min-w-0 bg-transparent basis-1/4;
      // @apply min-w-0 bg-transparent;
      // @apply min-w-0 grow shrink bg-transparent ml-2;
    }
  }
</style>
