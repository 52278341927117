<svelte:options strictprops={false} />

<script>
  import { createEventDispatcher } from "svelte";
  import upperFirst from "lodash/upperFirst";
  import isEmpty from "lodash/isEmpty";
  import { copyToClipboard } from "overline";

  import SortableThumbnailList from "#lib/SortableThumbnailList.svelte";
  import ListThumbnail from "#lib/ListThumbnail.svelte";
  import SidebarTitle from "#lib/sidebar/SidebarTitle.svelte";
  import SelectedActions from "#lib/SelectedActions.svelte";
  import Sidebar from "#lib/sidebar/Sidebar.svelte";
  import PrevNext from "#lib/sidebar/PrevNext.svelte";
  import ProductListManager from "#lib/sidebar/ProductListManager.svelte";
  import ProductListSettings from "#lib/sidebar/ProductListSettings.svelte";
  import ProductListSettingsMultiple from "#lib/sidebar/ProductListSettingsMultiple.svelte";

  import { showRightPanel } from "#src/stores/ui.js";
  import makeMultiProduct from "#src/extensions/multi-product.js";

  export let org;
  export let disabled = false;
  export let standardProducts;
  export let products;
  export let selectedObj;
  export let recordName = "type";
  export let filter;
  export let shareable = false;

  const dispatch = createEventDispatcher();

  let width;
  let height;

  $: allProducts = [...standardProducts, ...products];
  $: filteredStandardProducts = filterProducts(filter, standardProducts);
  $: filtered = filterProducts(filter, products);
  $: allFiltered = [...filteredStandardProducts, ...filtered];
  $: selectedList = allFiltered.filter((t) => $selectedObj[t.id]);
  $: selected = findSelected(selectedList);
  $: selectedIndex = allProducts.indexOf(selectedList[0]);
  $: multiProduct = makeMultiProduct(selectedList, recordName);
  $: orgProdsOnly = selectedList.every((p) => p.vendor_id === $org.id);
  $: prev = selectedList.length === 1 && products[selectedIndex - 1];
  $: next = selectedList.length === 1 && products[selectedIndex + 1];

  function filterProducts(filter, products) {
    if (!filter) return products;
    const f = filter?.toLowerCase() || "";
    return products.filter((p) => {
      const name = p.name?.toLowerCase() || "";
      const category = p.category?.toLowerCase() || "";
      const classification = p.classification?.toLowerCase() || "";

      return name.includes(f) || category.includes(f) || classification.includes(f);
    });
  }

  function findSelected(s) {
    if (s.length !== 1) return null;

    const t = s[0];

    return t;
  }

  function select(e) {
    const { event, id } = e.detail;

    if (event.shiftKey && !isEmpty($selectedObj)) {
      const allIds = filtered.map((t) => t.id);
      const indices = filtered
        .map((t, i) => i)
        .filter((i) => $selectedObj[filtered[i].id] || filtered[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedObj.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedObj[id]) {
        selectedObj.select(id);
      } else {
        selectedObj.deselect(id);
      }
    } else {
      selectedObj.selectOnly(id);
    }
  }

  function selectNone() {
    $selectedObj = {};
  }

  function gotoPrev() {
    if (prev) selectedObj.selectOnly(prev.id);
  }

  function gotoNext() {
    if (next) selectedObj.selectOnly(next.id);
  }

  function keydownContainer() {}

  function copyIdToClipboard() {
    if (selectedList.length === 1) {
      const [{ id }] = selectedList;
      if (id) {
        copyToClipboard(id);
      }
    }
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <div class="grow relative h-full overflow-y-auto" on:click={selectNone} on:keydown={keydownContainer}>
    {#if selectedList.length > 0}
      <SelectedActions
        cloneable={!disabled && orgProdsOnly}
        deletable={!disabled && orgProdsOnly}
        shareable={orgProdsOnly && shareable}
        on:share={() => dispatch("share-selected", { selected: selectedList.map((s) => s.id) })}
        on:delete={() => dispatch("remove-selected", { selected: selectedList.map((s) => s.id) })}
        on:clone={() => dispatch("clone-selected", { selected: selectedList.map((s) => s.id) })}
        on:copy-id={copyIdToClipboard} />
    {/if}
    <SortableThumbnailList
      list={filteredStandardProducts}
      header={`Standard ${upperFirst(recordName)}s`}
      disabled
      actionsVisible={false}
      selected={$selectedObj}
      let:record
      filterable
      bind:filter
      on:select={select}
      on:select-none={selectNone}>
      <ListThumbnail {record} wrapText>
        <div class="w-full h-full relative">
          <slot name="standard-thumbnail" product={record} />
        </div>
      </ListThumbnail>
    </SortableThumbnailList>

    <SortableThumbnailList
      spaced={false}
      list={filtered}
      actionsVisible={false}
      header={`Org ${upperFirst(recordName)}s`}
      {disabled}
      selected={$selectedObj}
      let:record
      reorderable={!filter}
      on:select={select}
      on:select-none={selectNone}
      on:add
      on:reorder>
      <ListThumbnail {record} wrapText>
        <div class="w-full h-full relative">
          <slot name="thumbnail" product={record} />
        </div>
      </ListThumbnail>
    </SortableThumbnailList>
  </div>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedList.length === 1 && selected}
          <PrevNext
            {prev}
            {next}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={selected.name} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedList.length === 0}
          <!-- <div class="px-6 text-xs italic">No {recordName}s selected.</div> -->
          <ProductListManager />
        {:else if selectedList.length === 1}
          {#key selected.id}
            <slot name="sidebar" {selected} />
            <ProductListSettings {selected} />
          {/key}
        {:else}
          {#key multiProduct.id}
            <SidebarTitle title="Multiple {upperFirst(recordName)}s Selected" />
            <slot name="multi-sidebar" selected={multiProduct} />
            <ProductListSettingsMultiple selected={selectedList} />
          {/key}
        {/if}
      </svelte:fragment>
    </Sidebar>
  {/if}
</div>
