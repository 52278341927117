<svelte:options strictprops={false} />

<script>
  import cloneDeep from "lodash/cloneDeep";
  import get from "lodash/get";
  import range from "lodash/range";
  import uniq from "lodash/uniq";
  import uniqBy from "lodash/uniqBy";
  import { add, subtract, multiply, midpoint, distSq, degToRad } from "vector";
  import { onMount, tick, getContext, setContext } from "svelte";
  import { writable } from "svelte/store";
  import { Drawing } from "drawing";
  import { EventBus, bucketArray, objectify, bucketArrayMultiple } from "overline";
  import { OpenSeadragon } from "docviewer";
  import { Link, navigate } from "svelte-routing";

  import CaretLeftIcon from "@local/assets/icons/caret-left.svg";
  import PrevNext from "#lib/sidebar/PrevNext.svelte";
  import SelectedActions from "#lib/SelectedActions.svelte";
  import DocSearchWidget from "#lib/DocSearchWidget.svelte";
  import DocPageRelabelWidget from "#lib/DocPageRelabelWidget.svelte";

  import { Modal } from "svelte-utilities";
  import ResizablePanes from "#lib/ResizablePanes.svelte";
  import SummaryTable from "#lib/home/SummaryTable.svelte";
  import Sidebar from "#lib/sidebar/Sidebar.svelte";
  import DocumentProperties from "#lib/sidebar/DocumentProperties.svelte";
  import LocationProperties from "#lib/sidebar/LocationProperties.svelte";
  import DocLocationProperties from "#lib/sidebar/DocLocationProperties.svelte";
  import {
    showRightPanel,
    showSummarySplitscreen,
    currentTool,
    selectedLocations,
    selectedAnnotations,
    showSearchResults,
    useSelectedInRelabel,
    selectedPages,
    currentDocid,
    clipboard,
    expandRightPanel,
  } from "#src/stores/ui.js";
  import {
    orderedList,
    removeFromSortableList,
    sortListBy,
  } from "@local/extensions/collections/sortable-list.js";
  import { user } from "#src/stores/auth.js";
  import markColor from "@local/extensions/drawing/mark-color.js";
  import clipRectangle from "@local/extensions/drawing/clip-rectangle.js";

  import { getFiles, getFileData, getProgress } from "#src/extensions/check-document-status.js";
  import { createItem as defaultItem, createType as defaultType, itemFromVertices } from "@local/lamina-core";
  import copyToClipboard from "#src/extensions/copy-to-clipboard.js";
  import drawingSymbol from "@local/extensions/drawing/drawing-symbol.js";
  import eb from "#src/extensions/event-bus.js";
  import nearColors from "#src/extensions/near-colors.js";
  import { nextMark as nextMarkFromList } from "@local/extensions/identifiers/mark.js";
  import { incrementMarkCopy } from "@local/extensions/identifiers/mark.js";
  import imgClipToBlob from "#src/extensions/dom/img-clip-to-blob.js";
  import {
    sheetScaleFactor,
    transformSheetPoint,
  } from "@local/extensions/geometry/transform-sheet-points.js";
  import nameMinusExtension from "@local/extensions/utilities/name-minus-extension.js";
  import extension from "@local/extensions/utilities/extension.js";
  import locationsByDocument from "#src/extensions/locations-by-document.js";
  import validDropIndices from "#src/extensions/valid-drop-indices.js";
  import translateRectangle from "@local/extensions/geometry/translate-rectangle.js";
  import { api } from "#src/api";
  import { TRIOPS_URL as triopsUrl } from "#src/env";

  export let group;
  export let docid;
  export let items;
  export let collections;
  export let types;
  export let disabled;
  export let customColumns;
  export let customColColumns;
  export let standardColumns;
  export let queryParams;

  const CLIP_THRESHOLD = 600;
  const controller = new EventBus();
  const dpi = window.devicePixelRatio;
  const org = getContext("org");
  const fileData = writable({});
  setContext("fileData", fileData);

  let loaded = false;

  const markShapes = {
    item: "hexagon",
    collection: "rectangle",
    type: "circle",
  };

  const pdfUnits = {
    "'": "feet",
    '"': "inches",
    "''": "inches",
    ft: "feet",
    in: "inches",
    cm: "centimeters",
    m: "meters",
    mm: "millimeters",
  };

  let viewer;
  let adding = null;
  let confirmRemoveLocationsModal;
  let locationsToDelete = [];
  let confirmRemovePagesModal;
  let pagesToDelete = [];
  let tileList = [];
  let annotations = [];
  let searchResults = [];
  let importSearchResultsAs = "item";
  let hoveredFile = null;
  let hoveredPage = null;
  let lastSelectedPage = null;
  let hoveredAnnotation = null;
  let hoveredSearchResult = null;

  let timeout;

  let width;
  let height;

  let showItems = true;
  let showCollections = true;
  let showTypes = true;
  let hitHashes = {};
  let currentHb = null;
  let locationPreview = null;
  let pannable = $currentTool === "pan";
  let tempTool = null;
  let dragStart = null;
  let dragging = null;
  let selecting = null;
  let clipping = null;
  let hoveredLocations = {};
  let cursor = "default";
  let highlightedText = null;
  let showPageRelabelRegions = false;
  let pageRegions;
  let overlayDwgs;

  $: allAttachments = $group && bucketArrayMultiple($group.attachments, "item_id", "type_id");
  $: doc = $group.documents[docid];
  $: visiblePages = getVisiblePages(doc);
  $: visibleIndices = getVisibleIndices(visiblePages);
  $: initialPage = visibleIndices[queryParams?.["doc-page"]];
  $: files = getFiles(doc);
  $: {
    doc;
    setup();
  }
  $: fileIndex = getFileIndex(doc);
  $: checkStatus(doc, $fileData);
  $: progress = getProgress(doc, $fileData);
  $: sidebarPosition = getSidebarPosition(width, height);
  $: docLocations = locationsByDocument($group.locations);
  $: locations = docLocations[doc?.id] || [];
  $: documents = orderedList($group.documents);
  $: docIndex = documents.findIndex((f) => f.id === docid);
  $: nextDoc = documents[docIndex + 1];
  $: prevDoc = documents[docIndex - 1];
  $: locationsObj = objectify(locations);
  $: annosObj = objectify(annotations);
  $: searchResultsObj = bucketArray(searchResults, "page_id");
  $: checkSelected(docid);
  $: indexedLocations = indexLocations(locations, $group, items);
  $: locationsByRecord = bucketArray(locations, "record_id");
  $: collectionItems = bucketArray(items, "collection_id");
  $: nextMark = getNextMark(adding, $group, items, collections, types);
  $: selectedLocationList = Object.keys($selectedLocations)
    .map((id) => locationsObj[id])
    .filter((loc) => loc);
  $: selectedPageList = Object.keys($selectedPages)
    .map((id) => doc.data.pages[id])
    .filter((page) => page);
  $: uniqLocations = uniqBy(selectedLocationList, "record_id").filter((l) => l && !!relatedRecord(l, $group));
  $: location = uniqLocations[0];
  $: prevLoc = getPrevLocation(location, indexedLocations);
  $: nextLoc = getNextLocation(location, indexedLocations);
  $: locationRecord = relatedRecord(location, $group);
  $: checkCurrentTool($currentTool);
  $: {
    if (loaded) {
      indexLocations;
      $selectedLocations;
      $selectedAnnotations;
      hoveredLocations;
      hoveredAnnotation;
      locationPreview;
      dragging;
      annosObj;
      $fileData;
      highlightedText;
      searchResultsObj;
      $showSearchResults;
      hoveredSearchResult;
      importSearchResultsAs;
      showPageRelabelRegions;
      pageRegions;
      overlayDwgs = makeOverlayDwgs();
    }
  }
  $: marquee = makeMarqueeDwg(selecting, clipping);
  $: draggedMark = makeDraggedMark(dragging);
  $: pageDropTarget = makePageDropTarget(dragging);
  $: {
    docid;
    overlayDwgs;
    marquee;
    draggedMark;
    pageDropTarget;
    hoveredFile;
    hoveredPage;
    hoveredAnnotation;
    highlightedText;
    if (viewer && viewer.redraw) {
      viewer.redraw();
    }
  }

  async function checkStatus(doc, fd) {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (progress === 1) return;

    const incomplete =
      doc.data?.pages.order.reduce((incomplete, pageId) => {
        const page = doc.data.pages[pageId];
        if (fd[page.name]?.status[page.page]?.state !== "completed") {
          incomplete[page.name] = true;
        }
        return incomplete;
      }, {}) || {};

    const pages = doc.data?.pages.order.reduce((p, pageId) => {
      const page = doc.data.pages[pageId];
      if (!p[page.name]) p[page.name] = [];
      p[page.name].push(page.page);
      return p;
    }, {});

    const check = async () => {
      let refreshTiles = false;
      const s = await Promise.all(Object.keys(incomplete).map((f) => getFileData(f)));
      s.forEach((st) => {
        if (st?.file?.name) {
          pages[st.file.name].forEach((index) => {
            if (st.status[index]?.state !== $fileData[st.file.name]?.status[index]?.state) {
              refreshTiles = true;
            }
          });

          $fileData[st.file.name] = st;
        }
      });

      if (refreshTiles) {
        updateTileList();
      }
    };

    timeout = setTimeout(check, 1000);
  }

  function getVisiblePages(doc) {
    return doc.data.pages.order.filter((pageId) => {
      const page = doc.data.pages[pageId];
      return !page.hidden;
    });
  }

  function getVisibleIndices(visiblePages) {
    return visiblePages.reduce((obj, pageId, index) => {
      obj[pageId] = index;
      return obj;
    }, {});
  }

  function getFileIndex(doc) {
    const idx = {};
    doc.data.pages.order.forEach((pageId) => {
      const page = doc.data.pages[pageId];
      if (!idx[page.name]) idx[page.name] = {};
      idx[page.name][page.page] = pageId;
    });
    return idx;
  }

  function getNextLocation(location, indexedLocations) {
    if (!location) return null;
    const pageLocs = indexedLocations[location.page_id];
    const last = pageLocs[pageLocs.length - 1];

    if (location.id === last?.id) {
      const pageIndex = doc.data.pages.indices[location.page_id];
      const next = range(pageIndex + 1, doc.data.pages.order.length).find((i) => {
        const id = doc.data.pages.order[i];
        const page = doc.data.pages[id];
        return page && !page?.hidden && indexedLocations[id]?.length;
      });
      if (next !== undefined) {
        const id = doc.data.pages.order[next];
        return indexedLocations[id][0];
      }
    } else {
      const currentIndex = pageLocs.findIndex((l) => l.id === location.id);
      return pageLocs[currentIndex + 1];
    }
  }

  function getPrevLocation(location, indexedLocations) {
    if (!location) return null;
    const pageLocs = indexedLocations[location.page_id];
    const first = pageLocs[0];

    if (location.id === first?.id) {
      const pageIndex = doc.data.pages.indices[location.page_id];
      const prev = range(pageIndex - 1, -1, -1).find((i) => {
        const id = doc.data.pages.order[i];
        const page = doc.data.pages[id];
        return page && !page?.hidden && indexedLocations[id]?.length;
      });
      if (prev !== undefined) {
        const id = doc.data.pages.order[prev];
        const index = indexedLocations[id].length - 1;
        return indexedLocations[id][index];
      }
    } else {
      const currentIndex = pageLocs.findIndex((l) => l.id === location.id);
      return pageLocs[currentIndex - 1];
    }
  }

  function relatedRecord(location, grp) {
    if (!location) return null;
    return location.record_type === "type" ? grp.types[location.record_id] : grp.items[location.record_id];
  }

  function clipVertex(clip, index) {
    if (index == 0) return { x: clip.x, y: clip.y + clip.height };
    if (index == 1) return { x: clip.x + clip.width, y: clip.y + clip.height };
    if (index == 2) return { x: clip.x + clip.width, y: clip.y };
    return { x: clip.x, y: clip.y };
  }

  function draggedClip(clip, index, to) {
    const vertices = [
      { x: clip.x, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y + clip.height },
      { x: clip.x + clip.width, y: clip.y },
      { x: clip.x, y: clip.y },
    ];
    const i = parseInt(index);

    const opp = vertices[(i + 2) % 4];
    const minX = Math.min(to.x, opp.x);
    const minY = Math.min(to.y, opp.y);
    const maxX = Math.max(to.x, opp.x);
    const maxY = Math.max(to.y, opp.y);

    return {
      x: minX,
      y: minY,
      width: Math.max(maxX - minX, 10),
      height: Math.max(maxY - minY, 10),
    };
  }

  async function updateTileList() {
    // Perform async update, requesting signed URL for image tiles
    const tiles = visiblePages.map((pageId) => {
      const page = doc.data.pages[pageId];
      const file = $fileData[page.name];

      if (file?.status[page.page]?.state !== "completed") {
        return {
          url: null,
          id: page.id,
          page: page.page,
          name: page.name,
        };
      }

      if (file.file.metadata?.mimetype.startsWith("image")) {
        return api.storage
          .from("documents")
          .createSignedUrl(file.file.name, 60 * 60)
          .then(({ data }) => {
            return {
              type: "image",
              url: data?.signedUrl,
              id: page.id,
              page: page.page,
              name: page.name,
            };
          });
      } else if (file.file.metadata?.mimetype === "application/pdf") {
        const nme = nameMinusExtension(page.name);
        const path = encodeURIComponent(nme);
        return {
          type: "iiif",
          url: `${triopsUrl}/iiif/2/drawings/${path}.${page.page}.tiff/info.json`,
          id: page.id,
          page: page.page,
          name: page.name,
        };
      }
    });

    tileList = await Promise.all(tiles);
  }

  async function updateAnnotations() {
    if (!doc) {
      annotations = [];
      return;
    }

    const pdfIds = files.filter((name) => extension(name) === "pdf");

    const polys = await api
      .from("dng_polygon_notes")
      .select("*,sheet:dng_sheets(limits::jsonb,rotation)")
      .eq("document_bucket_id", "documents")
      .in("document_name", pdfIds);

    if (polys.error) {
      annotations = [];
      return;
    }

    const pi = visiblePages.reduce((obj, pageId) => {
      const page = doc.data.pages[pageId];
      if (!obj[page.name]) obj[page.name] = [];
      obj[page.name].push(page.page);
      return obj;
    }, {});

    annotations = polys.data
      .filter((a) => {
        if (a.type !== "/PolygonDimension") return false;
        if (a.measure_scale === null) return false;

        const indices = pi[a.document_name];
        return indices?.includes(a.sheet_idx);
      })
      .map((a) => ({
        ...a,
        page: visibleIndices[fileIndex[a.document_name]?.[a.sheet_idx]],
        page_id: fileIndex[a.document_name]?.[a.sheet_idx],
        item: itemFromVertices(a.scaled_vertices, pdfUnits[a.measure_unit], a.sheet?.rotation),
      }))
      .filter((a) => a.page !== undefined)
      .sort((a, b) => {
        if (a.page !== b.page) {
          return a.page - b.page;
        }
        return a.id - b.id;
      });
  }

  async function loadFileData() {
    const s = await Promise.all(files.map((f) => getFileData(f)));
    files.forEach((file, index) => {
      $fileData[file] = s[index];
    });
  }

  async function setup() {
    await loadFileData();
    await updateTileList();
    await updateAnnotations();
  }

  async function finish() {
    loaded = true;
  }

  function checkSelected(docid) {
    if ($currentDocid && $currentDocid !== docid) {
      deselectAll();
    }

    if (docid) {
      $currentDocid = docid;
    }

    const toDeselect = Object.keys($selectedLocations).filter((id) => !locRecord(locationsObj[id]));
    if (toDeselect.length) {
      selectedLocations.deselect(...toDeselect);
    }
  }

  function getNextMark(adding, grp, items, collections, types) {
    if (!adding?.clone) return null;
    let record;
    let marks = [];

    if (adding.record_type === "type") {
      record = grp.types[adding.record_id];
      marks = types.map((t) => t.mark);
    } else if (adding.record_type === "item") {
      record = grp.items[adding.record_id];
      marks = items.map((i) => i.mark);
    } else if (adding.record_type === "collection") {
      record = grp.items[adding.record_id];
      marks = collections.map((c) => c.mark);
    }

    if (!record) return null;

    return incrementMarkCopy(record.mark, marks);
  }

  function getSidebarPosition(width, height) {
    if (width >= 640) {
      return "right";
    }

    return height > width ? "bottom" : "right";
  }

  function checkCurrentTool(ct) {
    if (!["location-item", "location-type", "location-collection"].includes(ct) && !tempTool) {
      adding = null;
      locationPreview = null;
    }

    pannable = ct === "pan";
  }

  function indexLocations(locations, grp, items) {
    const indexed = doc.data.pages.order.reduce((o, pageId) => {
      o[pageId] = [];
      return o;
    }, {});

    locations.forEach((location) => {
      if (indexed[location.page_id]) {
        if (location.record_type === "type" && grp.types[location.record_id] && showTypes) {
          indexed[location.page_id].push(location);
        } else if (location.record_type === "item" && grp.items[location.record_id] && showItems) {
          indexed[location.page_id].push(location);
        } else if (
          location.record_type === "collection" &&
          grp.items[location.record_id] &&
          showCollections
        ) {
          indexed[location.page_id].push(location);
        }
      }
    });

    Object.entries(indexed).forEach(([pageId, locations]) => {
      indexed[pageId] = locations.sort((a, b) => {
        if (a.position.x !== b.position.x) return a.position.x - b.position.x;
        return a.position.y - b.position.y;
      });
    });

    return indexed;
  }

  function recordMark(location, record) {
    if (location.record_type !== "type") {
      const labelBy = record.is_collection
        ? $group.data.settings.collections_label_by
        : $group.data.settings.label_by;

      return labelBy ? get(record, labelBy) : record.mark;
    } else {
      return record.mark;
    }
  }

  function locRecord(location) {
    if (!location) return null;
    return location.record_type === "type"
      ? $group.types[location.record_id]
      : $group.items[location.record_id];
  }

  function annotationMark(annotation, style, name = null) {
    const pt = annotation.own_label_point;
    const tile = viewer.tile(annotation.page);
    if (!tile) return null;
    const size = tile.getContentSize();

    const initPt = {
      x: pt[0] - annotation.sheet.limits[0][0],
      y: pt[1] - annotation.sheet.limits[0][1],
    };

    const ssf = sheetScaleFactor(annotation.sheet.limits, annotation.sheet.rotation, size);
    const pos = transformSheetPoint(initPt, annotation.sheet.rotation, ssf, size);
    const p = { x: pos.x, y: -pos.y };

    const mk = new Drawing().mark(p, "✓", { shape: "hexagon" }).style(style);
    if (name) {
      return mk.name(name);
    }

    return mk;
  }

  function makeOverlayDwgs() {
    if (!viewer) return {};
    return visiblePages.reduce((dwgs, pageId) => {
      const locations = indexedLocations[pageId];
      const index = visibleIndices[pageId];
      if (index === undefined) return dwgs;
      const tile = viewer.tile(index);
      if (!tile) return dwgs;
      const size = tile.getContentSize();

      const smarks = [];
      const marks = locations
        .filter((loc) => loc)
        .map((loc) => {
          const record = locRecord(loc);
          const highlighted = hoveredLocations?.[loc.id] || $selectedLocations?.[loc.id];
          const pos = { x: loc.position.x, y: -loc.position.y };
          let color;
          if (dragging && dragging.selected?.[loc.id]) {
            color = { fill: "#FFFFFF", textColor: "#FFFFFF", stroke: "#AAAAAA" };
          } else {
            color = markColor(record, { highlighted, emphasized: true });
          }

          const markText = recordMark(loc, record);

          if ($selectedLocations[loc.id]) {
            if (dragging?.type === "loc-clip-outline" && dragging.location?.id === loc.id) {
              const lindex = visibleIndices[loc.page_id];
              const p = subtract(dragging.end, dragging.delta);
              const tc = viewer.tileCoords(p, lindex);

              const minX = loc.position.x - loc.clip.x;
              const minY = loc.position.y - loc.clip.y;
              const maxX = tc.width - (loc.clip.x + loc.clip.width - loc.position.x);
              const maxY = tc.height - (loc.clip.y + loc.clip.height - loc.position.y);
              const x = Math.max(minX, Math.min(tc.x, maxX));
              const y = Math.max(minY, Math.min(tc.y, maxY));
              const delta = subtract({ x, y }, loc.position);

              let npos;
              let clip;

              npos = { x, y: -y };
              clip = clipRectangle(
                "loc",
                {
                  x: loc.clip.x + delta.x,
                  y: loc.clip.y + delta.y,
                  width: loc.clip.width,
                  height: loc.clip.height,
                },
                loc.id,
              );

              const mk = new Drawing()
                .mark(npos, markText, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              return new Drawing().add(clip, mk);
            } else if (dragging?.type === "loc-clip-vertex" && dragging.location?.id === loc.id) {
              const mk = new Drawing()
                .mark(pos, markText, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              let clip;
              const npos = subtract(dragging.end, dragging.delta);
              const lindex = visibleIndices[loc.page_id];
              const tc = viewer.tileCoords(npos, lindex);
              const x = Math.max(0, Math.min(tc.x, tc.width));
              const y = Math.max(0, Math.min(tc.y, tc.height));
              const to = { x, y };
              const d = draggedClip(loc.clip, dragging.index, to);
              clip = clipRectangle("loc", d, loc.id);

              return new Drawing().add(clip, mk);
            } else {
              const mk = new Drawing()
                .mark(pos, markText, {
                  shape: markShapes[loc.record_type],
                  highlighted: true,
                })
                .style({ ...color, lineWidth: 2 })
                .name(`location_${loc.id}`);

              smarks.push(mk);

              let clip;
              let dwgBtn;

              if (loc.clip) {
                clip = clipRectangle("loc", loc.clip, loc.id);
                const h = record.drawing && record.drawing.attachment_id === loc.id;

                if (loc.record_type !== "type") {
                  dwgBtn = drawingSymbol(
                    loc.id,
                    {
                      x: loc.clip.x + loc.clip.width,
                      y: -loc.clip.y - loc.clip.height,
                    },
                    h,
                  );
                }
              }

              return new Drawing().add(clip, mk, dwgBtn);
            }
          } else {
            return new Drawing()
              .mark(pos, markText, {
                shape: markShapes[loc.record_type],
              })
              .style({ ...color, lineWidth: 2 })
              .name(`location_${loc.id}`);
          }
        });

      const samarks = Object.keys($selectedAnnotations)
        .map((id) => annosObj[id])
        .filter((a) => a && a.page === parseInt(index))
        .map((a) => annotationMark(a, { fill: "#F7F7B7", lineWidth: 2 }, `annotation_${a.id}`));

      let pr;
      if (showPageRelabelRegions && pageRegions) {
        const relabelSelected = $useSelectedInRelabel && selectedPageList.length;
        if (!relabelSelected || $selectedPages[pageId]) {
          pr = new Drawing();
          if (pageRegions.title) {
            const title = translateRectangle(pageRegions.title, size);
            const clip = clipRectangle("region", title, "title")
              .text(
                "Page Title",
                { x: title.x, y: -title.y - title.height },
                {
                  offset: { x: 5, y: 10 },
                  alignment: "left",
                  font: "sans-serif",
                },
              )
              .style({ textColor: "#0066E5" });
            pr = pr.add(clip);
          }

          if (pageRegions.number) {
            const number = translateRectangle(pageRegions.number, size);
            const clip = clipRectangle("region", number, "number")
              .text(
                "Page Number",
                { x: number.x, y: -number.y - number.height },
                {
                  offset: { x: 5, y: 10 },
                  alignment: "left",
                  font: "sans-serif",
                },
              )
              .style({ textColor: "#0066E5" });
            pr = pr.add(clip);
          }
        }
      }

      let lpm;
      if (locationPreview?.index === parseInt(index)) {
        let mark = "";
        let style;
        if (adding?.record_id) {
          const record = locRecord(adding);

          if (adding.clone) {
            const labelBy = record?.is_collection
              ? $group.data.settings.collections_label_by
              : $group.data.settings.label_by;

            if (!labelBy || labelBy === "mark") {
              mark = nextMark;
            } else {
              mark = get(record, labelBy);
            }
          } else {
            mark = record ? recordMark(adding, record) : "";
          }
          style = { ...markColor(record, { emphasized: true }), lineWidth: 2 };
        } else {
          if (adding?.record_type === "type") {
            mark = nextMarkFromList(types);
          } else if (adding?.record_type === "collection") {
            mark = nextMarkFromList(collections);
          } else if (adding?.record_type === "item") {
            mark = nextMarkFromList(items);
          }

          style = { fill: "#f59e0b", lineWidth: 1 };
        }
        const pos = { x: locationPreview.position.x, y: -locationPreview.position.y };

        lpm = new Drawing().mark(pos, mark, { shape: markShapes[locationPreview.type] }).style(style);
      }

      let ham;
      if (hoveredAnnotation) {
        const annotation = annosObj[hoveredAnnotation];
        if (annotation.page === parseInt(index)) {
          ham = annotationMark(
            annotation,
            { fill: "#000000", lineWidth: 2, textColor: "#FFFFFF" },
            `annotation_${hoveredAnnotation}`,
          );
        }
      }

      let hsrm;
      if (hoveredSearchResult) {
        const hovered = searchResultsObj?.[pageId]?.find((res) => res.id === hoveredSearchResult);
        if (hovered) {
          hsrm = new Drawing()
            .mark({ x: hovered.center.x, y: -hovered.center.y }, hovered.label, {
              shape: markShapes[importSearchResultsAs],
            })
            .style({ fill: "#000000", lineWidth: 2, textColor: "#FFFFFF" })
            .name(`searchresult_${hoveredSearchResult}`);
        }
      }

      let label;
      {
        const page = doc.data.pages[pageId];
        const pageIndex = doc.data.pages.indices[pageId];
        const sheet = $fileData[page.name]?.sheets?.[page.page];
        const labelText = page.label || sheet?.name || pageIndex + 1;
        label = new Drawing()
          .text(
            labelText,
            { x: 0, y: -size.y },
            {
              offset: { x: 5, y: -10 },
              alignment: "left",
              font: "sans-serif",
              width: size.x,
              truncate: true,
            },
          )
          .style({ fontSize: 10 });
      }

      let htdwg;
      if (highlightedText?.page_id === pageId) {
        const { x, y, width, height, angle } = highlightedText;
        htdwg = new Drawing()
          .rectangle(0, 0, width, height)
          .rotate(-angle)
          .translate(x, -y)
          .style({ fill: "rgba(255, 255, 0, 0.5)", stroke: "rgba(255, 255, 0, 1)", lineWidth: 2 });
      }

      let stmarks = [];
      if ($showSearchResults && searchResultsObj?.[pageId]) {
        stmarks = searchResultsObj[pageId].map((result) => {
          const { id, center, label } = result;
          const p = { x: center.x, y: -center.y };
          const dwg = new Drawing()
            .mark(p, label, { shape: markShapes[importSearchResultsAs] })
            .style({ fill: "#F7F7B7", lineWidth: 2 })
            .name(`searchresult_${id}`);
          return dwg;
        });
      }

      const dwg = new Drawing().add(
        ...marks,
        ...smarks,
        ...samarks,
        ...stmarks,
        pr,
        lpm,
        ham,
        hsrm,
        label,
        htdwg,
      );

      dwgs[index] = dwg;
      return dwgs;
    }, {});
  }

  function makeMarqueeDwg(selecting, clipping) {
    let start;
    let end;
    let style;
    if (selecting) {
      start = selecting.start;
      end = selecting.end;
      style = { fill: "transparent", stroke: "black" };
    } else if (clipping) {
      start = clipping.start;
      end = clipping.end;
      style = { stroke: "#0066E5", fill: "transparent", lineWidth: 2 };
    } else {
      return null;
    }

    const x = Math.min(start.x, end.x) * dpi;
    const y = Math.min(start.y, end.y) * dpi;
    const w = Math.abs(start.x - end.x) * dpi;
    const h = Math.abs(start.y - end.y) * dpi;

    return new Drawing().rectangle(x, -y, w, -h).style(style);
  }

  function makeDraggedMark(dragging) {
    if (dragging?.type !== "location") return null;
    if (!dragging.location) return null;
    const { start, end, selected } = dragging;

    const marks = Object.keys(selected)
      .filter((locid) => locationsObj[locid])
      .map((locid) => {
        const loc = locationsObj[locid];
        const record = locRecord(loc);
        const mark = recordMark(loc, record);
        const index = visibleIndices[loc.page_id];
        const pos = viewer.pixelCoords({ ...loc.position, index });
        if (!pos) return null;
        const delta = subtract(end, start);
        const newPos = add(pos, delta);
        const newTc = viewer.tileCoords(newPos);
        const isViable = newTc.index >= 0;
        const color = isViable
          ? markColor(record, { emphasized: true })
          : { fill: "#FFFFFF", textColor: "#FFFFFF", stroke: "#AAAAAA" };

        const scaledPos = multiply(newPos, { x: dpi, y: -dpi });
        return new Drawing().mark(scaledPos, mark, { shape: markShapes[loc.record_type] }).style(color);
      });

    return new Drawing().add(...marks);
  }

  function makePageDropTarget(dragging) {
    if (dragging?.type !== "pages") return null;
    const nearest = viewer.nearestTile(dragging.end);
    const { x, y, width, height } = nearest.rect;

    const before = dragging.end.x <= nearest.center.x;
    const gapIndex = before ? nearest.index : nearest.index + 1;
    if (!dragging.droppableIndices[gapIndex]) {
      return null;
    }

    const margin = nearest.margin;

    let dtX;
    if (before) {
      dtX = x - margin / 2;
    } else {
      dtX = x + width + margin / 2;
    }

    return new Drawing()
      .polyline([
        { x: dtX * dpi, y: -(y * dpi) },
        { x: dtX * dpi, y: -(y + height) * dpi },
      ])
      .style({ stroke: "#0066E5", lineWidth: 2 });
  }

  function zoomIn() {
    controller.dispatch("zoom-in");
  }

  function zoomOut() {
    controller.dispatch("zoom-out");
  }

  function zoomToFit() {
    controller.dispatch("home");
  }

  function zoomToPage(id) {
    const ids = Array.isArray(id) ? id : [id];
    const indices = ids.map((id) => visibleIndices[id]).filter((index) => index !== undefined);

    if (indices.length) {
      controller.dispatch("zoom-to-page", indices);
    }
  }

  function moveToLocation(location) {
    const { page_id, point } = location;
    const index = visibleIndices[page_id];
    controller.dispatch("pan-to-location", { ...point, index });
  }

  function tileOverlay(options) {
    const dwg = overlayDwgs?.[options.index];
    if (dwg) {
      dwg.render({ ctx: options.context, annoScale: 1 / options.zoom });
      hitHashes[options.index] = dwg.renderHitbox({ ctx: options.hitContext, annoScale: 1 / options.zoom });
    } else {
      hitHashes[options.index] = null;
    }

    const t = tileList[options.index];
    if (!t || !viewer) return;
    if ($selectedPages[t.id] || hoveredFile === t.name || hoveredPage === t.id) {
      const tile = viewer.tile(options.index);
      const size = tile.getContentSize();
      const dwg = new Drawing()
        .rectangle(0, 0, size.x, -size.y)
        .style({ stroke: "#0066E5", fill: "transparent", lineWidth: 1 });
      dwg.render({ ctx: options.context, annoScale: 1 / options.zoom });
    }
  }

  function overlay(options) {
    if (marquee) {
      marquee.render({ ctx: options.context });
    }

    if (draggedMark) {
      draggedMark.render({ ctx: options.context, annoScale: dpi });
    }

    if (pageDropTarget) {
      pageDropTarget.render({ ctx: options.context });
    }
  }

  function isLoadedPage(index) {
    const page = tileList[index];
    return !!page.url;
  }

  async function toggleLocationDrawing(locid) {
    const loc = locationsObj[locid];
    const record = locRecord(loc);
    const page = doc.data.pages[loc.page_id];
    const file = $fileData[page?.name];
    if (!file || !page || !record || !loc) return;

    if (record.drawing && record.drawing.attachment_id === loc.id) {
      group.updateItem(record.id, "drawing", null);
      return;
    }

    const ext = extension(page.name);
    if (ext === "pdf") {
      const path = `${nameMinusExtension(page.name)}.${page.page}.tiff`;

      const drawing = {
        attachment_id: loc.id,
        id: page.name.split("/")[0],
        name: page.label || file.file.user_metadata?.name,
        type: "iiif",
        clip: loc.clip,
        path,
        extension: ext,
        properties: {
          width: loc.clip.width,
          height: loc.clip.height,
        },
      };

      group.updateItem(record.id, "drawing", drawing);
    } else {
      const drawing = await imageDrawingFromClip(loc);
      group.updateItem(record.id, "drawing", drawing);
    }
  }

  async function handleCanvasClick(e) {
    dragStart = null;
    dragging = null;
    selecting = null;
    clipping = null;

    const { tilePosition } = e;

    const position = {
      x: tilePosition.x,
      y: tilePosition.y,
    };
    const tile = tileList[tilePosition.index];
    const page_id = tile?.id;

    if ($currentTool === "select") {
      const hitbox = eventObj(e);
      if (hitbox) {
        $selectedPages = {};
        const [type, id] = hitbox.name.split("_");
        if (type === "location") {
          if (e.shift) {
            selectedLocations.select(id);
          } else if (e.originalEvent.metaKey) {
            if ($selectedLocations[id]) {
              selectedLocations.deselect(id);
            } else {
              selectedLocations.select(id);
            }
          } else {
            selectedLocations.selectOnly(id);
          }
        } else if (type === "annotation") {
          selectedAnnotations.deselect(id);
          if (hoveredAnnotation === id) {
            hoveredAnnotation = null;
          }
        } else if (type === "searchresult") {
          searchResults = searchResults.filter((r) => r.id !== id);
          if (hoveredSearchResult === id) {
            hoveredSearchResult = null;
          }
        } else if (type === "dwgbtn") {
          toggleLocationDrawing(id);
        }
      } else {
        if (tilePosition.index >= 0 && isLoadedPage(tilePosition.index)) {
          if (e.shift) {
            const prev = lastSelectedPage || visiblePages[0];
            const prevIndex = visibleIndices[prev];
            const currentIndex = visibleIndices[tile.id];
            const min = Math.min(prevIndex, currentIndex);
            const max = Math.max(prevIndex, currentIndex);
            const newSelected = range(min, max + 1).map((i) => visiblePages[i]);
            selectedPages.select(...newSelected);
            lastSelectedPage = tile.id;
          } else if (e.originalEvent.metaKey) {
            selectedPages.select(tile.id);
            lastSelectedPage = tile.id;
          } else {
            selectedPages.selectOnly(tile.id);
            lastSelectedPage = tile.id;
          }
        } else {
          $selectedPages = {};
        }

        if (!e.shift) {
          $selectedLocations = {};
        }
        hoveredLocations = {};
      }
    } else if (
      ["location-item", "location-collection"].includes($currentTool) &&
      tilePosition.index >= 0 &&
      isLoadedPage(tilePosition.index) &&
      adding
    ) {
      if (adding.clone) {
        const record_type = adding.record_type;
        const record =
          record_type === "type"
            ? cloneDeep($group.types[adding.record_id])
            : cloneDeep($group.items[adding.record_id]);

        record.id = crypto.randomUUID();
        record.mark = nextMark;
        const id = crypto.randomUUID();

        const location = {
          id,
          group_id: $group.id,
          document_id: docid,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          record_type,
          record_id: record.id,
        };

        group.update([
          { type: record_type, action: "add", records: [record] },
          { type: "location", action: "add", records: [location] },
        ]);

        selectedLocations.select(id);
      } else if (adding.record_id) {
        const id = crypto.randomUUID();

        group.addLocation({
          id,
          group_id: $group.id,
          document_id: docid,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          record_type: adding.record_type,
          record_id: adding.record_id,
        });
        selectedLocations.select(id);
      } else {
        const record_type = adding.record_type;
        const p = record_type === "collection" ? { is_collection: true } : null;
        const records = record_type === "collection" ? collections : items;
        const item = defaultItem($group.id, $user.id, records, types, p);

        const id = crypto.randomUUID();
        const location = {
          id,
          group_id: $group.id,
          document_id: docid,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          record_type,
          record_id: item.id,
        };

        group.update([
          { type: "item", action: "add", records: [item], block: true },
          { type: "location", action: "add", records: [location] },
        ]);

        selectedLocations.select(id);
      }
    } else if ($currentTool === "location-type" && tilePosition.index >= 0) {
      if (!adding.record_id) {
        const type = defaultType($group.id, types);
        const id = crypto.randomUUID();

        const location = {
          id,
          group_id: $group.id,
          document_id: docid,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          record_type: "type",
          record_id: type.id,
        };

        group.update([
          { type: "type", action: "add", records: [type], block: true },
          { type: "location", action: "add", records: [location] },
        ]);

        selectedLocations.select(id);
        return;
      } else if (adding?.clone) {
        const record = cloneDeep($group.types[adding.record_id]);
        record.id = crypto.randomUUID();
        record.mark = nextMark;

        const id = crypto.randomUUID();
        const location = {
          id,
          group_id: $group.id,
          document_id: docid,
          index: locations.length,
          type: "pin",
          position,
          page_id,
          record_type: "type",
          record_id: record.id,
        };

        group.update([
          { type: "type", action: "add", records: [record], block: true },
          { type: "location", action: "add", records: [location] },
        ]);

        selectedLocations.select(id);
        return;
      }

      const id = crypto.randomUUID();
      group.addLocation({
        id,
        group_id: $group.id,
        document_id: docid,
        index: locations.length,
        type: "pin",
        position,
        page_id,
        record_type: "type",
        record_id: adding.record_id,
      });
      selectedLocations.select(id);
      await tick();

      adding = null;
      $currentTool = "select";
    } else {
      $currentTool = "select";
      adding = null;
    }
  }

  function handleCanvasDoubleClick(e) {
    if ($currentTool === "select") {
      const { tilePosition } = e;
      if (tilePosition.index >= 0) {
        controller.dispatch("zoom-to-page", tilePosition.index);
      }
    }
  }

  function eventObj(e) {
    if (!e.hitContext) return null;
    const x = e.position.x * dpi;
    const y = e.position.y * dpi;
    const p = e.hitContext.getImageData(x, y, 1, 1).data;
    if (p[0] === 0 && p[1] === 0 && p[2] === 0) {
      return null;
    }

    const color = `rgb(${p[0]},${p[1]},${p[2]})`;
    const hash = hitHashes[e.tilePosition.index];
    if (!hash) return null;
    if (hash[color]) return hash[color];

    const near = nearColors(p[0], p[1], p[2], 1);
    const match = near.find((c) => hash[c]);
    if (match) return hash[match];

    return null;
  }

  function hitboxChange(current, hitbox) {
    if (!current && !hitbox) return false;
    if (!current && hitbox) return true;
    if (current && !hitbox) return true;
    if (current && hitbox) {
      return current.name !== hitbox.name;
    }
  }

  async function handleMousemove(e) {
    const { tilePosition } = e;
    if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
      locationPreview = {
        position: {
          x: tilePosition.x,
          y: tilePosition.y,
        },
        type: adding.record_type,
        index: tilePosition.index,
      };
    } else {
      locationPreview = null;
    }

    const hitbox = eventObj(e);
    if (hitboxChange(currentHb, hitbox)) {
      currentHb = hitbox;
      if (hitbox) {
        const [type, id, index] = hitbox.name.split("_");

        if ($currentTool === "select") {
          if (type === "dwgbtn") {
            cursor = "pointer";
          } else if (["loc-clip", "region-clip"].includes(type)) {
            if (["0", "2"].includes(index)) {
              cursor = "nesw-resize";
            } else if (["1", "3"].includes(index)) {
              cursor = "nwse-resize";
            } else if (index === "outline") {
              cursor = "move";
            }
          } else if (type === "location") {
            cursor = "pointer";
          } else if (type === "annotation") {
            cursor = "pointer";
          } else {
            cursor = "default";
          }
        }

        if (type === "location") {
          hoveredLocations = { [id]: true };
          hoveredAnnotation = null;
          hoveredSearchResult = null;
        } else if (type === "annotation") {
          hoveredAnnotation = id;
          hoveredLocations = {};
          hoveredSearchResult = null;
        } else if (type === "searchresult") {
          hoveredSearchResult = id;
          hoveredAnnotation = null;
          hoveredLocations = {};
        } else {
          hoveredLocations = {};
          hoveredAnnotation = null;
          hoveredSearchResult = null;
        }
      } else {
        hoveredLocations = {};
        hoveredAnnotation = null;
        hoveredSearchResult = null;
        cursor = "default";
      }
    }
  }

  function handleLeftDown(e) {
    if (!viewer) return;
    const { position, tilePosition } = e;
    if (["location-item", "location-collection", "location-type"].includes($currentTool)) {
      if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
        const tpPos = { x: tilePosition.x, y: tilePosition.y };
        clipping = { start: position, end: position, tpStart: tpPos, tpEnd: tpPos };
        locationPreview = {
          position: tpPos,
          type: adding.record_type,
          index: tilePosition.index,
        };
      }
    }

    if ($currentTool !== "select") return;
    const hitbox = eventObj(e);
    if (hitbox && !disabled && !e.shift) {
      const [type, id, index] = hitbox.name.split("_");

      if (type === "location") {
        let selected;
        if ($selectedLocations[id]) {
          selected = cloneDeep($selectedLocations);
        } else {
          // $selectedLocations = {};
          $selectedPages = {};
          selected = { [id]: true };
        }

        const location = locationsObj[id];
        const pageIndex = visibleIndices[location.page_id];
        if (pageIndex === undefined) return;
        const p = viewer.pixelCoords({ ...location.position, index: pageIndex });
        if (p) {
          dragStart = {
            type: "location",
            start: position,
            end: position,
            delta: subtract(position, p),
            hitbox,
            location,
            selected,
          };
        }
      } else if (type === "loc-clip") {
        const location = locationsObj[id];
        const pageIndex = visibleIndices[location.page_id];
        if (pageIndex === undefined) return;
        if (index === "outline") {
          const p = viewer.pixelCoords({ ...location.position, index: pageIndex });
          if (p) {
            dragStart = {
              type: "loc-clip-outline",
              start: position,
              end: position,
              delta: subtract(position, p),
              hitbox,
              location,
              selected: { [id]: true },
            };
          }
        } else {
          const vertex = clipVertex(location.clip, index);
          const p = viewer.pixelCoords({ ...vertex, index: pageIndex });
          if (p) {
            dragStart = {
              type: "loc-clip-vertex",
              index,
              start: position,
              end: position,
              delta: subtract(position, p),
              hitbox,
              location,
            };
          }
        }
      } else if (type === "region-clip") {
        const tile = viewer.tile(tilePosition.index);
        if (!tile) return;
        const size = tile.getContentSize();
        const clip = translateRectangle(pageRegions[id], size);
        if (index === "outline") {
          const vertex = clipVertex(clip, 3);
          const p = viewer.pixelCoords({ ...vertex, index: tilePosition.index });
          const delta = subtract(position, p);
          dragStart = {
            type: "region-clip-outline",
            id,
            start: position,
            end: position,
            delta,
            hitbox,
            tile_index: tilePosition.index,
          };
        } else {
          const vertex = clipVertex(clip, index);
          const p = viewer.pixelCoords({ ...vertex, index: tilePosition.index });
          const delta = subtract(position, p);
          dragStart = {
            type: "region-clip-vertex",
            id,
            index,
            start: position,
            end: position,
            delta,
            hitbox,
            tile_index: tilePosition.index,
          };
        }
      }
    } else if (tilePosition.index >= 0 && $selectedPages[visiblePages[tilePosition.index]]) {
      const selectedIndices = Object.keys($selectedPages).map((id) => visibleIndices[id]);
      const droppableIndices = validDropIndices(selectedIndices, tilePosition.index, visiblePages.length);

      if (Object.keys(droppableIndices).length) {
        dragStart = {
          type: "pages",
          index: tilePosition.index,
          start: position,
          end: position,
          droppableIndices,
        };
      }
    } else {
      selecting = { start: position, end: position, hitbox };
    }
  }

  function handleCanvasDrag(e) {
    if (!viewer) return;
    const { position, tilePosition } = e;

    if (["location-item", "location-collection", "location-type"].includes($currentTool)) {
      if (tilePosition.index >= 0 && adding && isLoadedPage(tilePosition.index)) {
        if (clipping) {
          const tpPos = { x: tilePosition.x, y: tilePosition.y };
          clipping.end = position;
          clipping.tpEnd = tpPos;
          locationPreview = {
            position: midpoint(clipping.tpStart, clipping.tpEnd),
            type: adding.record_type,
            index: tilePosition.index,
          };
        }
      } else {
        clipping = null;
        locationPreview = null;
      }
    }

    if ($currentTool !== "select") return;

    if (selecting) {
      selecting.end = position;
      return;
    }

    if (dragStart) {
      if (!dragging) {
        dragging = { ...dragStart };
      }

      dragging.end = position;

      if (dragging.type === "region-clip-outline") {
        const { delta, id, end, tile_index } = dragging;
        const clip = pageRegions[id];
        const pos = subtract(end, delta);
        const tile = viewer.tile(tile_index);
        const size = tile.getContentSize();
        const tc = viewer.tileCoords(pos, tile_index);
        const x = Math.max(0, Math.min(tc.x, size.x - clip.width));
        const y = Math.max(0, Math.min(tc.y, size.y - clip.height));
        const rx = x - size.x;
        const ry = y - size.y;

        pageRegions[id] = { ...pageRegions[id], x: rx, y: ry };
      } else if (dragging.type === "region-clip-vertex") {
        const { delta, id, end, index, tile_index } = dragging;
        const tile = viewer.tile(tile_index);
        const size = tile.getContentSize();
        const clip = translateRectangle(pageRegions[id], size);
        const pos = subtract(end, delta);
        const tc = viewer.tileCoords(pos, tile_index);
        const x = Math.max(0, Math.min(tc.x, size.x));
        const y = Math.max(0, Math.min(tc.y, size.y));
        const to = { x, y };
        const d = draggedClip(clip, index, to);
        const rx = d.x - size.x;
        const ry = d.y - size.y;
        pageRegions[id] = { ...d, x: rx, y: ry };
      }

      return;
    }
  }

  async function imageDrawingFromClip(location) {
    const page = doc.data.pages[location.page_id];
    const file = $fileData[page.name];
    const f = await api.storage.from("documents").download(page.name);
    if (f.error) return;

    const url = URL.createObjectURL(f.data);
    const blob = await imgClipToBlob(url, location.clip);
    const clipId = crypto.randomUUID();
    const object_id = `${page.name}/${clipId}.png`;

    const stored = await api.storage.from("drawings").upload(object_id, blob, {
      contentType: "image/png",
    });
    if (stored.error) return;

    return {
      attachment_id: location.id,
      id: clipId,
      name: page.label || file.file.user_metadata?.name,
      type: "image",
      extension: "png",
      object_id,
      preview_object_id: object_id,
      properties: {
        width: location.clip.width,
        height: location.clip.height,
      },
    };
  }

  async function updateClipItem(item, locUpdate, location) {
    const drawing = await imageDrawingFromClip(location);
    if (!drawing) return;

    const updates = [locUpdate, { type: "item", id: item.id, prop: "drawing", value: drawing }];
    group.update(updates);
  }

  async function handleCanvasDragEnd(e) {
    if (!viewer) return;

    // Handle selecting finish
    if (selecting) {
      const minX = Math.min(selecting.start.x, selecting.end.x);
      const minY = Math.min(selecting.start.y, selecting.end.y);
      const maxX = Math.max(selecting.start.x, selecting.end.x);
      const maxY = Math.max(selecting.start.y, selecting.end.y);

      const selected = locations.filter((loc) => {
        const record = locRecord(loc);
        if (!record) return false;
        const index = visibleIndices[loc.page_id];
        if (index == null) return false;
        const p = viewer.pixelCoords({ ...loc.position, index });
        return p && p.x > minX && p.x < maxX && p.y > minY && p.y < maxY;
      });

      const ids = selected.map((l) => l.id);

      const hitbox = eventObj(e);
      if (e.shift) {
        if (hitbox && hitbox.name === selecting.hitbox?.name) {
          if (hitbox.name.startsWith("location")) {
            const m = hitbox.name.match(/location_(.+)/);
            selectedLocations.select(m[1]);
          }
        } else {
          selectedLocations.select(...ids);
        }
      } else {
        selectedLocations.selectOnly(...ids);
      }
      $selectedPages = {};

      if ($showSearchResults && searchResults.length) {
        searchResults = searchResults.filter((r) => {
          const index = visibleIndices[r.page_id];
          const p = viewer.pixelCoords({ ...r.center, index });
          return !p || p.x < minX || p.x > maxX || p.y < minY || p.y > maxY;
        });
      }
    }

    // Handle clipping finish
    else if (adding && clipping) {
      const { tilePosition } = e;
      const d = distSq(clipping.end, clipping.start);

      const minX = Math.min(clipping.tpStart.x, tilePosition.x);
      const minY = Math.min(clipping.tpStart.y, tilePosition.y);
      const maxX = Math.max(clipping.tpStart.x, tilePosition.x);
      const maxY = Math.max(clipping.tpStart.y, tilePosition.y);

      const pos = midpoint(clipping.tpStart, tilePosition);

      const page_id = tileList[tilePosition.index]?.id;
      const position = {
        x: pos.x,
        y: pos.y,
      };

      const clip =
        d > CLIP_THRESHOLD
          ? { x: minX, y: minY, width: Math.max(maxX - minX, 10), height: Math.max(maxY - minY, 10) }
          : null;

      const dd = Math.sqrt(d);

      // A bit of a fudge: for small drags, OpenSeadragon will emit both a "click" AND a "dragend" event.
      // To keep from adding two locations (one for each event), we check whether the distance dragged
      // is larger than the default clickDistThreshold property (5).
      if (tilePosition.index >= 0 && isLoadedPage(tilePosition.index) && dd > 5) {
        if (adding.clone) {
          const record_type = adding.record_type;
          const record =
            record_type === "type"
              ? cloneDeep($group.types[adding.record_id])
              : cloneDeep($group.items[adding.record_id]);

          record.id = crypto.randomUUID();
          record.mark = nextMark;
          const id = crypto.randomUUID();

          const location = {
            id,
            group_id: $group.id,
            document_id: docid,
            index: locations.length,
            type: "pin",
            page_id,
            position,
            record_type,
            record_id: record.id,
            clip,
          };

          group.update([
            { type: record_type, action: "add", records: [record], block: true },
            { type: "location", action: "add", records: [location] },
          ]);

          selectedLocations.select(id);
        } else if (adding.record_id) {
          const id = crypto.randomUUID();
          group.addLocation({
            id,
            group_id: $group.id,
            document_id: docid,
            index: locations.length,
            type: "pin",
            page_id,
            position,
            record_type: adding.record_type,
            record_id: adding.record_id,
            clip,
          });
          selectedLocations.select(id);
        } else {
          const record_type = adding.record_type;
          if (record_type === "type") {
            const type = defaultType($group.id, types);
            const id = crypto.randomUUID();

            const loc = {
              id,
              group_id: $group.id,
              document_id: docid,
              index: locations.length,
              type: "pin",
              page_id,
              position,
              record_type,
              record_id: type.id,
              clip,
            };

            group.update([
              { type: "type", action: "add", records: [type], block: true },
              { type: "location", action: "add", records: [loc] },
            ]);

            selectedLocations.select(id);
          } else {
            const p = record_type === "collection" ? { is_collection: true } : null;
            const records = record_type === "collection" ? collections : items;
            const item = defaultItem($group.id, $user.id, records, types, p);

            const id = crypto.randomUUID();
            const loc = {
              id,
              group_id: $group.id,
              document_id: docid,
              index: locations.length,
              type: "pin",
              page_id,
              position,
              record_type,
              record_id: item.id,
              clip,
            };

            const page = doc.data.pages[page_id];
            const file = $fileData[page.name];
            const ext = extension(page.name);

            if (clip && file && ext === "pdf") {
              const path = `${nameMinusExtension(page.name)}.${page.page}.tiff`;

              item.drawing = {
                attachment_id: id,
                id: page.name.split("/")[0],
                name: page.label || file.file.user_metadata?.name,
                type: "iiif",
                clip,
                path,
                extension: ext,
                properties: {
                  width: clip.width,
                  height: clip.height,
                },
              };

              group.update([
                { type: "item", action: "add", records: [item], block: true },
                { type: "location", action: "add", records: [loc] },
              ]);

              selectedLocations.select(id);
            } else if (clip) {
              const drawing = await imageDrawingFromClip(loc);
              if (!drawing) return;
              item.drawing = drawing;
              group.update([
                { type: "item", action: "add", records: [item], block: true },
                { type: "location", action: "add", records: [loc] },
              ]);

              selectedLocations.select(id);
            } else {
              group.update([
                { type: "item", action: "add", records: [item], block: true },
                { type: "location", action: "add", records: [loc] },
              ]);

              selectedLocations.select(id);
            }
          }
        }
      }
    }

    // Handle dragging finish
    else if (dragging) {
      if (dragging.type === "location") {
        const { start, end, delta, selected } = dragging;
        const dropPos = subtract(end, delta);
        const ldelta = subtract(end, start);
        const dropTc = viewer.tileCoords(dropPos);
        const isViableDropLocation = dropTc.index >= 0;

        if (isViableDropLocation) {
          const updates = Object.keys(selected)
            .filter((locid) => $group.locations[locid])
            .map((locid) => {
              const loc = $group.locations[locid];
              const index = visibleIndices[loc.page_id];
              const pos = viewer.pixelCoords({
                ...loc.position,
                index,
              });
              const newPos = add(pos, ldelta);
              const newTc = viewer.tileCoords(newPos);

              return {
                type: "location",
                id: locid,
                diff: {
                  page_id: tileList[newTc.index]?.id,
                  position: newTc,
                },
              };
            });

          group.update(updates);
        }
      } else if (dragging.type === "loc-clip-outline") {
        const { location, end, delta } = dragging;
        const pos = subtract(end, delta);
        const lindex = visibleIndices[location.page_id];
        const tc = viewer.tileCoords(pos, lindex);

        const minX = location.position.x - location.clip.x;
        const minY = location.position.y - location.clip.y;
        const maxX = tc.width - (location.clip.x + location.clip.width - location.position.x);
        const maxY = tc.height - (location.clip.y + location.clip.height - location.position.y);
        const x = Math.max(minX, Math.min(tc.x, maxX));
        const y = Math.max(minY, Math.min(tc.y, maxY));
        const td = subtract({ x, y }, location.position);

        const loc = $group.locations[location.id];
        const d = {
          x: loc.clip.x + td.x,
          y: loc.clip.y + td.y,
          width: loc.clip.width,
          height: loc.clip.height,
        };
        const item = ["item", "collection"].includes(loc.record_type) && $group.items[loc.record_id];
        const locUpdate = {
          type: "location",
          id: location.id,
          diff: {
            clip: d,
            page_id: tileList[tc.index]?.id,
            position: { x, y },
          },
        };
        if (item?.drawing?.attachment_id === loc.id) {
          const drawing = cloneDeep(item.drawing);
          drawing.clip = d;

          const updates = [locUpdate, { type: "item", id: item.id, prop: "drawing", value: drawing }];
          group.update(updates);
        } else {
          group.update([locUpdate]);
        }
      } else if (dragging.type === "loc-clip-vertex") {
        const { location, index, end, delta } = dragging;
        const pos = subtract(end, delta);
        const lindex = visibleIndices[location.page_id];
        const tc = viewer.tileCoords(pos, lindex);

        const x = Math.max(0, Math.min(tc.x, tc.width));
        const y = Math.max(0, Math.min(tc.y, tc.height));
        const to = { x, y };

        const loc = $group.locations[location.id];

        const d = draggedClip(loc.clip, index, to);

        const item = ["item", "collection"].includes(loc.record_type) && $group.items[loc.record_id];
        const locUpdate = {
          type: "location",
          id: location.id,
          diff: {
            clip: d,
            page_id: tileList[tc.index]?.id,
          },
        };
        if (item?.drawing?.attachment_id === loc.id) {
          const pageId = visiblePages[lindex];
          const page = doc.data.pages[pageId];
          const ext = extension(page.name);

          if (ext === "pdf") {
            const drawing = cloneDeep(item.drawing);
            drawing.clip = d;
            drawing.properties.width = d.width;
            drawing.properties.height = d.height;

            const updates = [locUpdate, { type: "item", id: item.id, prop: "drawing", value: drawing }];
            group.update(updates);
          } else {
            await updateClipItem(item, locUpdate, loc);
          }
        } else {
          group.update([locUpdate]);
        }
      } else if (dragging.type === "pages") {
        const { end, droppableIndices } = dragging;
        const nearest = viewer.nearestTile(end);

        const before = end.x <= nearest.center.x;
        const gapIndex = before ? nearest.index : nearest.index + 1;

        if (!droppableIndices[gapIndex]) return null;

        const newOrder = visiblePages.filter((id) => !$selectedPages[id]);
        const dragged = Object.keys($selectedPages);
        if (gapIndex === visiblePages.length) {
          newOrder.push(...dragged);
        } else {
          const dragTo = visiblePages[gapIndex];
          const dropIndex = newOrder.indexOf(dragTo);
          newOrder.splice(dropIndex, 0, ...dragged);
        }

        const data = cloneDeep(doc.data);
        sortListBy(data.pages, newOrder);
        group.updateDocument(docid, { data });
      }
    }

    dragStart = null;
    dragging = null;
    selecting = null;
    clipping = null;
  }

  function gotoNext() {
    // slide = "left";
    navigate(`./${nextDoc.id}`);
  }

  function gotoPrev() {
    // slide = "right";
    navigate(`./${prevDoc.id}`);
  }

  function gotoNextLoc() {
    if (!nextLoc) return;
    const pc = viewer.pixelCoords({
      ...nextLoc.position,
      index: visibleIndices[nextLoc.page_id],
    });
    const isVisible = viewer.isPointVisible(pc);
    selectedLocations.selectOnly(nextLoc.id);
    if (!isVisible) {
      controller.dispatch("zoom-to-page", visibleIndices[nextLoc.page_id]);
    }
  }

  function gotoPrevLoc() {
    if (!prevLoc) return;
    const pc = viewer.pixelCoords({
      ...prevLoc.position,
      index: visibleIndices[prevLoc.page_id],
    });
    const isVisible = viewer.isPointVisible(pc);
    selectedLocations.selectOnly(prevLoc.id);
    if (!isVisible) {
      controller.dispatch("zoom-to-page", visibleIndices[prevLoc.page_id]);
    }
  }

  function beginAdding(e) {
    const { record_type, record_id } = e.detail;
    $currentTool = `location-${record_type}`;
    adding = {
      record_type,
      record_id,
    };
  }

  function beginCloning(e) {
    const { record_type, record_id } = e.detail;
    $currentTool = `location-${record_type}`;
    adding = {
      record_type,
      record_id,
      clone: true,
    };
  }

  function handleLocationToolClick() {
    adding = {
      record_type: $currentTool.split("-")[1],
      record_id: null,
    };
  }

  function confirmRemoveLocations() {
    locationsToDelete = selectedLocationList.map((l) => l.id);
    confirmRemoveLocationsModal.open();
  }

  function removeLocations() {
    group.removeLocation(...locationsToDelete);
  }

  function confirmRemovePages() {
    pagesToDelete = selectedPageList.map((l) => l.id);
    confirmRemovePagesModal.open();
  }

  function removePages() {
    selectedPages.deselect(...pagesToDelete);
    const data = cloneDeep(doc.data);
    removeFromSortableList(data.pages, ...pagesToDelete);
    group.updateDocument(docid, { data });
  }

  function hidePages(e) {
    const data = cloneDeep(doc.data);
    const ids = selectedPageList.map((l) => l.id);
    ids.forEach((id) => {
      data.pages[id].hidden = true;
    });
    group.updateDocument(docid, { data });
  }

  function showPages(e) {
    const data = cloneDeep(doc.data);
    const ids = selectedPageList.map((l) => l.id);
    ids.forEach((id) => {
      data.pages[id].hidden = false;
    });
    group.updateDocument(docid, { data });
  }

  function handleRightDown() {
    tempTool = $currentTool;
    $currentTool = "pan";
  }

  function handleRightUp() {
    if (tempTool) {
      $currentTool = tempTool;
      tempTool = null;
    }
  }

  function handleLocationClick(e) {
    const { locations } = e.detail;
    if (locations.length > 0) {
      const indices = locations.map((l) => visibleIndices[l.page_id]).filter((l) => l !== undefined);
      const pages = uniq(indices);
      controller.dispatch("zoom-to-page", pages);
    }
  }

  function handleLocationDblClick(e) {
    const { locations } = e.detail;
    if (locations.length > 0) {
      const indices = locations.map((l) => visibleIndices[l.page_id]).filter((l) => l !== undefined);
      const pages = uniq(indices);
      controller.dispatch("zoom-to-page", pages);
      selectedLocations.selectOnly(...locations.map((l) => l.id));
    }
  }

  function copyLocationsToClipboard() {
    copyToClipboard({
      type: "locations",
      data: {
        locations: cloneDeep(selectedLocationList),
        document_id: docid,
      },
    });
  }

  function pasteLocationsFromClipboard() {
    if ($clipboard?.type === "locations") {
      const sel = Math.min(...Object.keys($selectedPages).map((id) => visibleIndices[id]));
      const p = sel || 0;
      const locs = $clipboard.data.locations;
      const pdoc = $group.documents[$clipboard.data.document_id];
      const pdocVisible = getVisiblePages(pdoc);
      const pdocIndices = getVisibleIndices(pdocVisible);
      let minIndex = 0;
      const indices = locs.map((l) => pdocIndices[l.page_id]).filter((l) => l !== undefined);
      if (indices.length) {
        minIndex = Math.min(...indices);
      }

      const newLocs = locs
        .map((l) => {
          const index = pdocIndices[l.page_id];
          const newIndex = index - minIndex + p;
          const page_id = visiblePages[newIndex];

          if (!page_id) return null;

          return {
            ...l,
            id: crypto.randomUUID(),
            document_id: docid,
            index: locations.length,
            page_id,
          };
        })
        .filter((l) => l && locRecord(l));

      if (newLocs.length) {
        group.update([{ type: "location", action: "add", records: newLocs }]);
      }
    }
  }

  function deselectAll() {
    $selectedAnnotations = {};
    $selectedLocations = {};
    $selectedPages = {};
  }

  onMount(() => {
    eb.on("zoom-in-doc", zoomIn);
    eb.on("zoom-out-doc", zoomOut);
    eb.on("zoom-to-fit-doc", zoomToFit);
    eb.on("location-item", handleLocationToolClick);
    eb.on("location-type", handleLocationToolClick);
    eb.on("location-collection", handleLocationToolClick);
    eb.on("copy-to-clipboard", copyLocationsToClipboard);
    eb.on("paste-from-clipboard", pasteLocationsFromClipboard);
    eb.on("key-esc", deselectAll);
    controller.on("canvas-click", handleCanvasClick);
    controller.on("canvas-double-click", handleCanvasDoubleClick);
    controller.on("canvas-press", handleLeftDown);
    controller.on("canvas-drag", handleCanvasDrag);
    controller.on("canvas-drag-end", handleCanvasDragEnd);
    controller.on("canvas-mousemove", handleMousemove);
    controller.on("canvas-nonprimary-press", handleRightDown);
    controller.on("canvas-nonprimary-release", handleRightUp);
    controller.on("open", finish);

    return () => {
      eb.unsubscribe("zoom-in-doc", zoomIn);
      eb.unsubscribe("zoom-out-doc", zoomOut);
      eb.unsubscribe("zoom-to-fit-doc", zoomToFit);
      eb.unsubscribe("location-item", handleLocationToolClick);
      eb.unsubscribe("location-type", handleLocationToolClick);
      eb.unsubscribe("location-collection", handleLocationToolClick);
      eb.unsubscribe("copy-to-clipboard", copyLocationsToClipboard);
      eb.unsubscribe("paste-from-clipboard", pasteLocationsFromClipboard);
      controller.subscriptions = {};

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });
</script>

{#if doc}
  <div class="p-4 absolute z-10 space-y-2">
    <div class="text-blue-500 text-sm">
      <Link to={"documents"} class="flex items-center space-x-1">
        <CaretLeftIcon />
        <div>All Documents</div>
      </Link>
    </div>
  </div>

  <div
    class="w-full h-full flex relative"
    bind:offsetWidth={width}
    bind:offsetHeight={height}
    class:flex-col={sidebarPosition === "bottom"}>
    <ResizablePanes
      startLeft={850}
      startBottom={300}
      showPane={$showSummarySplitscreen && width >= 640}
      direction="horizontal">
      <div slot="content" class="w-full h-full flex relative">
        <div class="grow relative border">
          <div class="absolute w-full h-full overflow-hidden">
            <DocSearchWidget
              {docid}
              {group}
              {items}
              {collections}
              {types}
              {locations}
              {visibleIndices}
              bind:searchResults
              bind:highlightedText
              bind:importSearchResultsAs
              on:move-to-location={(e) => moveToLocation(e.detail)}
              on:zoom-to-page={(e) => zoomToPage(e.detail.page)} />
            <DocPageRelabelWidget
              {group}
              {doc}
              {visiblePages}
              {visibleIndices}
              bind:pageRegions
              bind:showPageRelabelRegions
              on:move-to-location={(e) => moveToLocation(e.detail)}
              on:zoom-to-page={(e) => zoomToPage(e.detail.page)} />
            {#if selectedLocationList.length > 0}
              <SelectedActions
                deletable={!disabled}
                cloneable
                selected={selectedLocationList.length}
                on:delete={confirmRemoveLocations}
                on:clone={copyLocationsToClipboard} />
            {:else if selectedPageList.length > 0}
              <SelectedActions
                deletable={!disabled}
                hideable
                hidden={selectedPageList.every((p) => p.hidden)}
                selected={selectedPageList.length}
                on:delete={confirmRemovePages}
                on:hide={hidePages}
                on:show={showPages} />
            {/if}

            {#if adding}
              {@const addingItem = locRecord(adding)}
              {@const l = locationsByRecord[adding.record_id] || []}
              {@const record = adding.record_type === "collection" ? "opening" : adding.record_type}
              <div class="absolute top-0 right-0 p-4 text-sm italic z-20">
                {#if addingItem}
                  Adding location {l.length + 1}
                  {#if adding.record_type === "item"}
                    / {addingItem.quantity}
                  {/if}
                  for {record}
                  {addingItem.mark}
                {:else}
                  Click to add new {record}s
                {/if}
              </div>
            {/if}
            {#key docid}
              <div class="absolute w-full h-full" style="cursor: {cursor};">
                {#await setup() then _}
                  <OpenSeadragon
                    bind:this={viewer}
                    {controller}
                    {tileList}
                    {pannable}
                    rightMousePan
                    options={{
                      viewportMargins: {
                        left: 30,
                        top: 30,
                        right: 30,
                        bottom: 30,
                      },
                    }}
                    {initialPage}
                    {tileOverlay}
                    {overlay} />
                {/await}
                {#if progress < 0.99999}
                  <div class="absolute bottom-0 right-0">
                    <div class="text-sm p-2">
                      {Math.round(progress * 100)}% complete
                    </div>
                  </div>
                {/if}
              </div>
            {/key}
          </div>
        </div>
        {#if $showRightPanel}
          <Sidebar
            position={sidebarPosition}
            type={sidebarPosition === "bottom" ? "absolute" : "flex"}
            bind:expanded={$expandRightPanel}
            expandable>
            <svelte:fragment slot="header">
              {#if selectedLocationList.length === 0}
                <PrevNext
                  prev={prevDoc}
                  next={nextDoc}
                  title={doc?.name}
                  on:gotoprev={gotoPrev}
                  on:gotonext={gotoNext}
                  sticky />
              {:else if selectedLocationList.length === 1}
                <PrevNext
                  prev={prevLoc}
                  next={nextLoc}
                  title={`Location: ${locationRecord?.mark}`}
                  on:gotoprev={gotoPrevLoc}
                  on:gotonext={gotoNextLoc}
                  sticky />
              {/if}
            </svelte:fragment>
            <svelte:fragment slot="content">
              {#if selectedLocationList.length > 0}
                <LocationProperties
                  locations={selectedLocationList}
                  {group}
                  {types}
                  {items}
                  {collections}
                  {disabled}
                  {customColumns}
                  {customColColumns}
                  {collectionItems}
                  {standardColumns}
                  on:add-location={beginAdding}
                  on:clone-location={beginCloning}
                  on:updateSupplier />
              {:else if selectedLocationList.length === 0 && items}
                <div class="h-full flex-col space-y-4" class:gap-4={sidebarPosition === "bottom"}>
                  <DocumentProperties
                    document={doc}
                    {group}
                    {disabled}
                    {annotations}
                    {items}
                    {viewer}
                    bind:hoveredFile
                    bind:hoveredPage
                    bind:lastSelectedPage
                    bind:hoveredAnnotation
                    on:zoom-to-page={(e) => zoomToPage(e.detail.page)} />
                  <DocLocationProperties
                    document={doc}
                    {group}
                    {items}
                    {types}
                    {collections}
                    {adding}
                    bind:hoveredLocations
                    on:add-location={beginAdding}
                    on:clone-location={beginCloning}
                    on:click-locations={handleLocationClick}
                    on:dblclick-locations={handleLocationDblClick} />
                </div>
              {/if}
            </svelte:fragment>
          </Sidebar>
        {/if}
      </div>

      <div slot="pane" class="bg-white w-full h-full overflow-hidden">
        <SummaryTable
          {group}
          {types}
          {items}
          {org}
          attachments={allAttachments}
          center={false}
          paddingTop="3rem" />
      </div>
    </ResizablePanes>
  </div>
{/if}

<Modal
  bind:this={confirmRemoveLocationsModal}
  on:confirm={removeLocations}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Locations</div>
  <div slot="content" class="space-y-2">
    <div class="space-y-2">
      <p>Are you sure you want to delete {locationsToDelete.length} location marker(s)?</p>
    </div>
  </div>
</Modal>

<Modal
  bind:this={confirmRemovePagesModal}
  on:confirm={removePages}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Delete", type: "confirm", style: "danger" },
  ]}
  closeOnOutclick>
  <div slot="title">Delete Pages</div>
  <div slot="content" class="space-y-2">
    <div class="space-y-2">
      <p>Are you sure you want to delete {pagesToDelete.length} page(s) from this document?</p>
    </div>
  </div>
</Modal>
