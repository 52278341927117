<script>
  import { createEventDispatcher, getContext } from "svelte";
  import { nanoid } from "nanoid";
  import range from "lodash/range";
  import uniq from "lodash/uniq";
  import { navigate } from "svelte-routing";
  import { Modal } from "svelte-utilities";

  import SpinnerIcon from "@local/assets/icons/spinner-lg.svg";
  import UploadIcon from "@local/assets/icons/upload.svg";
  import CaretDownIcon from "@local/assets/icons/caret-down.svg";
  import CaretRightIcon from "@local/assets/icons/caret-right.svg";
  import Datatable from "#lib/datatable/Datatable.svelte";
  import RfqListActions from "#lib/home/RfqListActions.svelte";
  import RfqListItem from "#lib/home/RfqListItem.svelte";
  import SearchSelect from "#lib/sidebar/SearchSelect.svelte";

  import { rfqs, filteredRfqs, selectedRfqIds, draftList, sentList } from "#src/stores/rfqs.js";
  import { detailVisible, showDrafts, showSent } from "#src/stores/ui.js";
  import { profile } from "#src/stores/auth.js";
  import sanitizePgString from "#src/extensions/sanitize-postgrest-string.js";
  import { api } from "#src/api";
  import eb from "#src/extensions/event-bus.js";

  const dispatch = createEventDispatcher();
  const productUser = getContext("productUser");
  const org = getContext("org");

  let fetched = false;

  let confirmShareModal;
  let jobToShare;
  let orgSearchString;
  let selectedOrg;
  let orgSearchResults = [];

  $: drafts = draftList(filteredRfqs);
  $: sent = sentList(filteredRfqs);
  $: selectOne($filteredRfqs, fetched);
  $: selected = makeSelection($selectedRfqIds);

  async function fetchRfqs() {
    await rfqs.fetch();
    rfqs.subscribeToDbUpdates();
    fetched = true;
  }

  function makeSelection(id) {
    return id.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  function selectOne(rfqs, fetched) {
    if (!rfqs || !fetched) return;
    const present = $selectedRfqIds.filter((id) => rfqs[id]);
    if (present.length && present.length === $selectedRfqIds.length) return;

    if (!present.length) {
      const first = rfqs[rfqs.order[0]];
      if (!first) {
        $selectedRfqIds = [];
      } else {
        $selectedRfqIds = [first.id];
      }
    } else {
      $selectedRfqIds = present;
    }
  }

  function confirmDeleteRfq(id) {
    dispatch("delete-rfq", { id });
  }

  async function cloneRfq(id) {
    dispatch("clone-rfq", { id });
  }

  function archiveRfq(id) {
    rfqs.archive(id);
  }

  function selectRfq(e, list) {
    if (e.detail.event.shiftKey) {
      const index = list === "drafts" ? e.detail.index : e.detail.index + $drafts.length;
      const indices = $selectedRfqIds.map((id) => $filteredRfqs.indices[id]);
      const min = Math.min(...indices, index);
      const max = Math.max(...indices, index);
      const newIndices = range(min, max + 1);
      $selectedRfqIds = newIndices.map((i) => $filteredRfqs.order[i]);
    } else if (e.detail.event.metaKey || e.detail.event.ctrlKey) {
      if ($selectedRfqIds.includes(e.detail.id)) {
        $selectedRfqIds = $selectedRfqIds.filter((id) => id !== e.detail.id);
      } else {
        $selectedRfqIds = uniq([...$selectedRfqIds, e.detail.id]);
      }
    } else {
      $selectedRfqIds = [e.detail.id];
    }

    if ($selectedRfqIds.length > 0) {
      $detailVisible = true;
    } else {
      $detailVisible = false;
    }
  }

  function confirmShareSelected(data) {
    const rfq = $rfqs[data.id];
    jobToShare = {
      name: rfq.job_name,
      group_id: rfq.group_id || rfq.job_current_group_id,
      type: "quote_request",
    };
    confirmShareModal.open();
  }

  async function searchOrgs(e) {
    const name = sanitizePgString(e.detail.value);
    const res = await api
      .from("organizations")
      .select("*")
      .or(`name.ilike.%${name}%,primary_contact_email.ilike.%${name}%`)
      .not("id", "eq", "aa15e3d4-e107-5b40-b034-db88a7d5c03a")
      .not("id", "eq", $org.id)
      .range(0, 5);

    if (res.error) {
      orgSearchResults = [];
    } else {
      orgSearchResults = res.data.map((o) => ({
        ...o,
        // description: o.address,
        cta: o.address,
        type: "existing",
        id: o.id,
      }));
    }
  }

  function selectOrg(e) {
    const result = e.detail.result;
    selectedOrg = result;
    orgSearchString = result.name;
  }

  function deselectOrg() {
    selectedOrg = null;
    orgSearchString = "";
  }

  async function cloneToOrg() {
    const clone = await api.rpc("clone_revision_to_org", {
      group_id: jobToShare.group_id,
      org_id: selectedOrg.id,
    });

    const jobId = clone.data;
    if (selectedOrg.org_type === "recipient") {
      await api.from("quote_requests").insert({
        job_id: jobId,
        organization_id: selectedOrg.id,
        link_nanoid: nanoid(12),
        request_for: "quote",
      });
    }
  }

  function gotoRfq(e) {
    if ($productUser) return;
    const rfq = $rfqs[e.detail.id];
    if (rfq.sent_at) {
      navigate(`versions/${rfq.group_id}`);
    } else {
      navigate(`jobs/${rfq.job_id}/current`);
    }
  }
</script>

{#if rfqs}
  {#await fetchRfqs()}
    <div class="text-gray-600 p-4 h-24 w-24 relative">
      <div class="absolute">
        <div class="animate-spin">
          <SpinnerIcon />
        </div>
      </div>
    </div>
  {:then}
    <div>
      <div class="w-full text-sm flex gap-2">
        <button class="btn btn-primary-alt" on:click={() => eb.dispatch("create-rfq")}>
          + New {$productUser ? "Quote" : "Draft"}</button>
        {#if $profile?.user_role === "developer"}
          <button class="btn btn-icon btn-primary-alt" on:click={() => eb.dispatch("upload-job")}>
            <UploadIcon />
          </button>
        {/if}
      </div>
      <div class="text-sm p-2 flex items-center">
        <button class="p-1" on:click={() => ($showDrafts = !$showDrafts)}>
          {#if $showDrafts}
            <CaretDownIcon />
          {:else}
            <CaretRightIcon />
          {/if}
        </button>
        {#if $showDrafts}
          <div class="text-gray-500">Drafts</div>
        {:else}
          <div class="text-gray-500">Drafts ({$drafts.length})</div>
        {/if}
      </div>
      {#if $showDrafts}
        <div class="bg-white border rounded-lg mb-2">
          <Datatable
            fixed
            rowComponent={RfqListItem}
            actionsComponent={RfqListActions}
            data={$drafts}
            {selected}
            on:clickrow={(e) => selectRfq(e, "drafts")}
            on:dblclickrow={gotoRfq}
            on:gotorow={gotoRfq}
            on:clonerow={(e) => cloneRfq(e.detail.id)}
            on:sendrow={(e) => confirmShareSelected(e.detail)}
            on:deleterow={(e) => confirmDeleteRfq(e.detail.id)}
            on:archiverow={(e) => archiveRfq(e.detail.id)}>
            <div slot="nocontent" class="text-xs flex gap-2 py-2 px-2">
              <button class="text-blue-500" on:click={() => eb.dispatch("create-rfq")}> Create New </button>
            </div>
          </Datatable>
        </div>
      {/if}
    </div>

    {#if $sent.length > 0}
      {@const sentLabel = $productUser ? "Quotes" : "Sent"}
      <div>
        <div class="text-sm p-2 flex items-center">
          <button class="p-1" on:click={() => ($showSent = !$showSent)}>
            {#if $showSent}
              <CaretDownIcon />
            {:else}
              <CaretRightIcon />
            {/if}
          </button>
          {#if $showSent}
            <div class="text-gray-500">{sentLabel}</div>
          {:else}
            <div class="text-gray-500">{sentLabel} ({$sent.length})</div>
          {/if}
        </div>
        {#if $showSent}
          <div class="bg-white border rounded-lg mb-2">
            <Datatable
              fixed
              rowComponent={RfqListItem}
              actionsComponent={RfqListActions}
              data={$sent}
              sendable={$profile?.user_role === "developer"}
              {selected}
              on:clickrow={(e) => selectRfq(e, "sent")}
              on:dblclickrow={gotoRfq}
              on:gotorow={gotoRfq}
              on:clonerow={(e) => cloneRfq(e.detail.id)}
              on:sendrow={(e) => confirmShareSelected(e.detail)}
              on:deleterow={(e) => confirmDeleteRfq(e.detail.id)}
              on:archiverow={(e) => archiveRfq(e.detail.id)} />
          </div>
        {/if}
      </div>
    {/if}
  {/await}
{/if}

{#if $profile?.user_role === "developer"}
  <Modal
    bind:this={confirmShareModal}
    on:confirm={cloneToOrg}
    buttons={[
      { label: "Cancel", type: "cancel" },
      { label: "Copy Job", type: "confirm", disabled: !selectedOrg },
    ]}>
    <div slot="title">Clone Job to Organization</div>
    <div slot="content">
      <div class="mb-2">The following job will be copied:</div>
      <div class="px-4 mb-2">
        {jobToShare?.name}
      </div>
      <SearchSelect
        placeholder="Destination Organization"
        border
        bind:value={orgSearchString}
        selection={selectedOrg}
        results={orgSearchResults}
        on:focus={searchOrgs}
        on:search={searchOrgs}
        on:select={selectOrg}
        on:deselect={deselectOrg} />
    </div>
  </Modal>
{/if}
