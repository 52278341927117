<script>
  import debounce from "lodash/debounce";
  import { createEventDispatcher } from "svelte";

  export let value;
  export let rows = 5;
  export let disabled = false;
  export let placeholder = "Placeholder";
  export let fullwidth = false;
  export let mono = false;

  const dispatch = createEventDispatcher();

  let localValue = value;

  $: refresh(value);

  function refresh(value) {
    localValue = value;
  }

  function update() {
    if (localValue !== value) {
      value = localValue;
      dispatch("input", { value: localValue });
    }
  }

  const debouncedUpdate = debounce(update, 1000);
</script>

<textarea
  class="border border-gray-300 rounded text-sm p-2"
  class:font-mono={mono}
  class:w-72={!fullwidth}
  class:w-full={fullwidth}
  class:text-gray-500={disabled}
  {placeholder}
  {disabled}
  bind:value={localValue}
  on:blur={update}
  on:input={debouncedUpdate}
  {rows} />
