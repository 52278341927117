<script>
  import { createEventDispatcher } from "svelte";

  export let border = false;
  export let label = "Label";

  /** @type {string | null} */
  export let labelWidth = null;
  export let value;
  export let disabled = false;
  export let readonly = false;
  export let buttons = []; // { component: Icon, label: string, value: string }

  const dispatch = createEventDispatcher();
  let focused = false;

  $: lw = labelWidth ? `width:${labelWidth};` : "";

  function handleFocus(evt) {
    focused = true;
  }

  function blur() {
    focused = false;
  }

  function toggleValue(button) {
    if (value === button.value) {
      value = null;
    } else {
      value = button.value;
    }
    dispatch("input", { value });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="prop-container" class:focused class:readonly class:visible-border={border}>
  {#if label !== null}
    <div class="label" style={lw}>
      <slot name="label">
        {label}
      </slot>
    </div>
  {/if}
  <div class="min-w-0 flex px-1 items-center gap-2 bg-transparent">
    {#each buttons as button}
      <button
        class="button"
        {disabled}
        on:focus={handleFocus}
        on:blur={blur}
        on:click={() => toggleValue(button)}
        class:active={value === button.value}>
        {#if button.component}
          <svelte:component this={button.component} />
        {:else if button.label}
          {button.label}
        {/if}
      </button>
    {/each}
  </div>
</div>

<style lang="scss">
  .prop-container {
    @apply px-2 py-2 w-full flex items-center rounded bg-white;
    &:hover:not(.readonly):not(.focused),
    &.visible-border {
      @apply ring-1 ring-inset ring-gray-300;
    }
    // &.focused {
    //   @apply ring-2 ring-inset ring-blue-500;
    // }
    .label {
      @apply truncate text-gray-400 flex-none;
    }
    .button {
      @apply border rounded border-transparent text-gray-400 p-0.5;

      &.active,
      &:hover {
        @apply text-black bg-gray-200;
      }
    }
  }
</style>
